import { getConstraint } from "../services/constraintsService";

export const parseReportResults = (asset, clientSettings) => {
  const results = [];

  clientSettings.filterResults.forEach((category) => {
    category.info.forEach((res) => {
      if (res.options) {
        res.options.forEach((o) => {
          const value = res.season ? "All" + o.value : o.value;
          results.push({
            category: value,
            constraint: getConstraint(asset, value),
          });
        });
      } else {
        const value = res.season ? "All" + res.value : res.value;
        if (asset[value]) {
          results.push({
            category: res.value,
            constraint: getConstraint(asset, value),
          });
        }
      }
      if (res.value === "feederFuseSize" && res.isSet) {
        if (asset.hasFuseWarning) {
          results.push({
            category: "feederFuseSize",
            name: "Fuse Warning",
            value: "Fault level",
            unit: "",
            class: "sea-green",
            constraint: true,
            hide: false,
          });
        }
      }
    });
  });

  if (asset.hasFuseMaxCurrentWarning) {
    let currentResult = results.filter((r) => r.name === "Fuse Size (A)")[0];
    if (currentResult) {
      currentResult.class = "sea-green";
      currentResult.constraint = true;
    }
  }

  return results;
};
