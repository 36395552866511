// @flow
import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";

interface IRagTransformerProperties {
  geometry: LatLng;
  styles: any;
  rag: string;
}

export class RagTransformerProperties implements IRagTransformerProperties {
  geometry: LatLng;
  styles: any;
  rag: string;
}

export class RagTransformer extends Asset implements IRagTransformerProperties {
  constructor(id: string, transformerProperties: RagTransformerProperties) {
    super(id);
    this.geometry = transformerProperties.geometry;
    this.styles = transformerProperties.styles;
    this.rag = transformerProperties.rag;
  }

  geometry: LatLng;
  styles: string;
  rag: string;
}
