import React, { useContext } from "react";
import { ToolContext } from "../context/ToolContext";

const Sidebar = ({ position, children }) => {
  const { toolState, setToolState } = useContext(ToolContext);

  const toggleSidebar = (e) => {
    document.body.classList.toggle("sidebar-open");
    const _toolState = { ...toolState };
    _toolState.sidebar = !toolState.sidebar;
    setToolState(_toolState);
  };

  return (
    <div
      style={{ top: position }}
      className={`navbar-collapse offcanvas-collapse caret-right ${
        toolState.sidebar ? "open" : ""
      }`}
    >
      {children}
      <button
        type="button"
        className="btn btn-dark btn-panel d-none d-sm-block"
        onClick={toggleSidebar}
      ></button>
    </div>
  );
};

export default Sidebar;
