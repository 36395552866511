import { updateGroupedConnectionChild } from "../../app/networkSlice";
import { AppDispatch } from "../../app/store";
import { DemandChangeTypeEnum, lctTypes, propertyTypes } from "../../constants/groupUpdateTypes";
import { ConsumerTypeReference, Reference } from "../../model/reference/referenceTypes";
import { ConnectionPoint } from "../../model/viewModel/connectionPoint";
import { GroupedConnectionPoint } from "../../model/viewModel/groupedConnectionPoint";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";

export interface DayNightPair {
  day: number;
  night: number;
}

export const getDemandTypes = (
  ringfencedTypes: RingfencedElement[],
): { hasDemand: boolean; hasGeneration: boolean } => {
  const hasDemand = ringfencedTypes.some(
    (p) =>
      p.ringfencedPoint &&
      (!p.ringfencedPoint.isGenerator ||
        p.ringfencedPoint.subGroupConnectionPoints?.some((q) => !q.isGenerator)),
  );
  const hasGeneration = ringfencedTypes.some(
    (p) =>
      p.ringfencedPoint &&
      (p.ringfencedPoint.isGenerator ||
        p.ringfencedPoint.subGroupConnectionPoints?.some((q) => q.isGenerator)),
  );

  return { hasDemand, hasGeneration };
};

export const shouldShowDemandForm = (
  ringfencedTypes: RingfencedElement[],
  selected: string,
): boolean => {
  const shouldShow = (type: string) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some((p) => !shouldShow(p.name));
    return !isNotAllowed;
  }
  return shouldShow(selected);
};
export const shouldShowProfileDemandForm = (
  ringfencedTypes: RingfencedElement[],
  selected: string,
): boolean => {
  const shouldShow = (type: string) => {
    switch (type) {
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
        return true;
      default:
        return false;
    }
  };
  if (selected === "All") {
    if (ringfencedTypes.some((p) => !shouldShow(p.name))) {
      return false;
    }

    if (
      ringfencedTypes.filter((p) => propertyTypes.includes(p.name) || lctTypes.includes(p.name))
        .length === 1
    ) {
      return true;
    }

    return false;
  }
  if (selected === "Properties") {
    if (ringfencedTypes.filter((p) => propertyTypes.includes(p.name)).length === 1) {
      return true;
    }
    return false;
  }
  if (selected === "LCT") {
    if (ringfencedTypes.filter((p) => lctTypes.includes(p.name)).length === 1) {
      return true;
    }
    return false;
  }

  return shouldShow(selected);
};

const update = (
  dispatchRedux: AppDispatch,
  p: RingfencedElement,
  q: GroupedConnectionPoint,
  name: string,
  value: any,
): void => {
  if (q[name] !== value) {
    dispatchRedux(
      updateGroupedConnectionChild({
        id: p.id,
        childUpdate: { id: q.id, name, value },
        isGroupUpdate: false,
        parentId: null,
      }),
    );
  }
};
const updateChild = (
  dispatchRedux: AppDispatch,
  p: RingfencedElement,
  q: GroupedConnectionPoint,
  child: ConnectionPoint,
  name: string,
  value: any,
): void => {
  if (q[name] !== value) {
    dispatchRedux(
      updateGroupedConnectionChild({
        id: p.id,
        childUpdate: { id: child.id, name, value },
        isGroupUpdate: false,
        parentId: q.id,
      }),
    );
  }
};
const setDefault = (
  dispatchRedux: AppDispatch,
  p: RingfencedElement,
  q: GroupedConnectionPoint,
  name: string,
): void => {
  if (q[name]) {
    dispatchRedux(
      updateGroupedConnectionChild({
        id: p.id,
        childUpdate: { id: q.id, name, value: false },
        isGroupUpdate: false,
        parentId: null,
      }),
    );
  }
};
const applyUplift = (
  dispatchRedux: AppDispatch,
  parentElement: RingfencedElement,
  childElement: GroupedConnectionPoint,
  subChildElement: ConnectionPoint | undefined,
  uplift: number,
): void => {
  const upliftValue = 1 + uplift / 100;
  const convert = (value: number, uplift: number): number => {
    const result = parseFloat((value * uplift).toFixed(3));
    if (result < 0) {
      return 0;
    }
    return result;
  };
  if (subChildElement) {
    updateChild(
      dispatchRedux,
      parentElement,
      childElement,
      subChildElement,
      "consumptionValue1",
      convert(subChildElement.consumptionValue1, upliftValue),
    );
    updateChild(
      dispatchRedux,
      parentElement,
      childElement,
      subChildElement,
      "consumptionValue2",
      convert(subChildElement.consumptionValue2, upliftValue),
    );
    updateChild(
      dispatchRedux,
      parentElement,
      childElement,
      subChildElement,
      "consumptionValue1IsDefault",
      false,
    );
    updateChild(
      dispatchRedux,
      parentElement,
      childElement,
      subChildElement,
      "consumptionValue2IsDefault",
      false,
    );
  } else {
    update(
      dispatchRedux,
      parentElement,
      childElement,
      "consumptionValue1",
      convert(childElement.consumptionValue1, upliftValue),
    );
    update(
      dispatchRedux,
      parentElement,
      childElement,
      "consumptionValue2",
      convert(childElement.consumptionValue2, upliftValue),
    );
    setDefault(dispatchRedux, parentElement, childElement, "consumptionValue1IsDefault");
    setDefault(dispatchRedux, parentElement, childElement, "consumptionValue2IsDefault");
  }
};

export const applyProfileDemandChange = (
  ringfencedFiltered: RingfencedElement[],
  consumerType: string | undefined,
  consumptionType: string | undefined,
  dayValue: number | undefined,
  nightValue: number | undefined,
  demandUplift: number | undefined,
  generationUplift: number | undefined,
  demandTypeChange: DemandChangeTypeEnum,
  dispatchRedux: AppDispatch,
): void => {
  if (
    demandTypeChange === DemandChangeTypeEnum.Absolute &&
    (consumerType === undefined ||
      consumptionType === undefined ||
      dayValue === undefined ||
      nightValue === undefined)
  ) {
    return;
  }
  if (
    demandTypeChange === DemandChangeTypeEnum.Relative &&
    (demandUplift === undefined || generationUplift === undefined)
  ) {
    return;
  }
  ringfencedFiltered.forEach((p) => {
    if (p.ringfencedPoint) {
      const point = p.ringfencedPoint;

      if (demandTypeChange === DemandChangeTypeEnum.Absolute) {
        update(dispatchRedux, p, point, "consumerType", consumerType);
        update(dispatchRedux, p, point, "consumptionType", consumptionType);
        update(dispatchRedux, p, point, "consumptionValue1", dayValue);
        update(dispatchRedux, p, point, "consumptionValue2", nightValue);

        setDefault(dispatchRedux, p, point, "consumerTypeIsDefault");
        setDefault(dispatchRedux, p, point, "consumptionTypeIsDefault");
        setDefault(dispatchRedux, p, point, "consumptionValue1IsDefault");
        setDefault(dispatchRedux, p, point, "consumptionValue2IsDefault");
      } else if (demandTypeChange === DemandChangeTypeEnum.Relative) {
        if (point.isGenerator && generationUplift) {
          applyUplift(dispatchRedux, p, point, undefined, generationUplift);
        } else if (!point.isGenerator && demandUplift) {
          applyUplift(dispatchRedux, p, point, undefined, demandUplift);
        }
        if (point.subGroupConnectionPoints) {
          point.subGroupConnectionPoints.forEach((q) => {
            if (q.isGenerator && generationUplift) {
              applyUplift(dispatchRedux, p, point, q, generationUplift);
            } else if (!q.isGenerator && demandUplift) {
              applyUplift(dispatchRedux, p, point, q, demandUplift);
            }
          });
        }
      }
    }
  });
};

export const getDefaultConsumerType = (
  reference: Reference,
  ringfencedFiltered: RingfencedElement[],
): string | undefined => {
  if (!ringfencedFiltered || ringfencedFiltered.length === 0) {
    return undefined;
  }

  const consumerClass =
    ringfencedFiltered[0].ringfencedPoint?.styles.class ?? ringfencedFiltered[0].styles.class;

  return reference.consumers.defaults[consumerClass]?.loadprofile;
};

export const getConsumerType = (
  reference: Reference,
  consumerType: string | undefined,
): ConsumerTypeReference | undefined => {
  return reference.consumers.types.find((f) => f.name === consumerType);
};

export const getConsumptionType = (
  reference: Reference,
  consumerType: string | undefined,
): string | undefined => {
  if (!consumerType) {
    return undefined;
  }
  const result = getConsumerType(reference, consumerType);
  if (!result) {
    return undefined;
  }

  let _consumptionType: string | undefined;
  [
    { demandType: "demandtypeAC", consumptionType: "AnnualConsumption" },
    {
      demandType: "demandtypeMDD",
      consumptionType: "MaximumDemandDiversified",
    },
    { demandType: "demandtypeMDF", consumptionType: "MaximumDemand" },
  ].forEach((dt) => {
    if (result[dt.demandType]) {
      _consumptionType = dt.consumptionType;
    }
  });
  return _consumptionType;
};

export const getConsumptionValues = (
  reference: Reference,
  _consumerType: string | undefined,
  _consumptionType: string | undefined,
): DayNightPair => {
  if (!_consumerType || !_consumptionType) {
    return {
      day: 0,
      night: 0,
    };
  }

  const consumerTypeValues = getConsumerType(reference, _consumerType);

  if (!consumerTypeValues) {
    return {
      day: 0,
      night: 0,
    };
  }
  const day = consumerTypeValues[`default${_consumptionType}Day`] ?? 0;
  const night = consumerTypeValues[`default${_consumptionType}Night`] ?? 0;

  return {
    day,
    night,
  };
};
