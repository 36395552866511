// @flow
import { LatLng } from "./simpleTypes";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";
import { Asset } from "./asset";
import {
  ITransformerProperties,
  TransformerProperties,
  TransformerMounting,
} from "./transformerProperties";

export class Transformer extends Asset implements ITransformerProperties {
  constructor(id: string, transformerProperties: TransformerProperties) {
    super(id);
    this.nameplateRating = transformerProperties.nameplateRating;
    this.nameplateRatingIsDefault = transformerProperties.nameplateRatingIsDefault;
    this.nameplateRatingAssigned = transformerProperties.nameplateRatingAssigned;
    this.cyclicRating = transformerProperties.cyclicRating;
    this.mounting = transformerProperties.mounting;
    this.systemVoltage = transformerProperties.systemVoltage;
    this.geometry = transformerProperties.geometry;
    this.maximumDemandKVA = transformerProperties.maximumDemandKVA;
    this.maximumDemandKVAPeriod = transformerProperties.maximumDemandKVAPeriod;
    this.maximumUtilisationPercent = transformerProperties.maximumUtilisationPercent;
    this.numberOfPhases = transformerProperties.numberOfPhases;
    this.numberOfPhasesIsDefault = transformerProperties.numberOfPhasesIsDefault;
    this.styles = transformerProperties.styles;
    this.fuseSelection = transformerProperties.fuseSelection;
    this.fuseSelectionIsDefault = transformerProperties.fuseSelectionIsDefault;
    this.fuseRating = transformerProperties.fuseRating;
    this.maxVoltRiseTotal = transformerProperties.maxVoltRiseTotal;
    this.maximumExportKVA = transformerProperties.maximumExportKVA;
    this.maxVoltRiseGeneration = transformerProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = transformerProperties.maxVoltRiseUnbalance;
    this.maxVoltDropPercent = transformerProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult = transformerProperties.maxVoltDropPercentPassResult;
    this.autoSelect = transformerProperties.autoSelect;
    this.groupedConnectionPoints = transformerProperties.groupedConnectionPoints
      ? transformerProperties.groupedConnectionPoints
      : [];
    this.ratingType = transformerProperties.ratingType;
    this.AllmaximumDemandKVA = transformerProperties.AllmaximumDemandKVA;
    this.AllmaximumDemandKVAPeriod = transformerProperties.AllmaximumDemandKVAPeriod;
    this.AllmaximumUtilisationPercent = transformerProperties.AllmaximumUtilisationPercent;
    this.AllmaxVoltDropPercent = transformerProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod = transformerProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult = transformerProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltRiseTotal = transformerProperties.AllmaxVoltRiseTotal;
    this.AllmaximumExportKVA = transformerProperties.AllmaximumExportKVA;
    this.AllmaxVoltRiseGeneration = transformerProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = transformerProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod = transformerProperties.AllmaxVoltRisePercentPeriod;
    this.wintermaximumDemandKVA = transformerProperties.wintermaximumDemandKVA;
    this.wintermaximumDemandKVAPeriod = transformerProperties.wintermaximumDemandKVAPeriod;
    this.wintermaximumUtilisationPercent = transformerProperties.wintermaximumUtilisationPercent;
    this.wintermaxVoltDropPercent = transformerProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod = transformerProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult =
      transformerProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltRisePercent = transformerProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRiseTotal = transformerProperties.wintermaxVoltRiseTotal;
    this.wintermaximumExportKVA = transformerProperties.wintermaximumExportKVA;
    this.wintermaxVoltRiseGeneration = transformerProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance = transformerProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePercentPeriod = transformerProperties.wintermaxVoltRisePercentPeriod;
    this.summermaximumDemandKVA = transformerProperties.summermaximumDemandKVA;
    this.summermaximumDemandKVAPeriod = transformerProperties.summermaximumDemandKVAPeriod;
    this.summermaximumUtilisationPercent = transformerProperties.summermaximumUtilisationPercent;
    this.summermaxVoltDropPercent = transformerProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod = transformerProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult =
      transformerProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltRisePercent = transformerProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = transformerProperties.summermaxVoltRiseTotal;
    this.summermaximumExportKVA = transformerProperties.summermaximumExportKVA;
    this.summermaxVoltRiseGeneration = transformerProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance = transformerProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod = transformerProperties.summermaxVoltRisePercentPeriod;
    this.nodeNumber = transformerProperties.nodeNumber;
    this.hvFaultLevel = transformerProperties.hvFaultLevel;
    this.hvFaultLevelIsDefault = transformerProperties.hvFaultLevelIsDefault;
    this.xrRatio = transformerProperties.xrRatio;
    this.annotation = transformerProperties.annotation;
    this.xrRatioIsDefault = transformerProperties.xrRatioIsDefault;
    this.flickerP28Primary = transformerProperties.flickerP28Primary;
    this.flickerP28PrimaryPassResult = transformerProperties.flickerP28PrimaryPassResult;
    this.flickerP28Secondary = transformerProperties.flickerP28Secondary;
    this.flickerP28SecondaryPassResult = transformerProperties.flickerP28SecondaryPassResult;
    this.cascadingRAG = transformerProperties.cascadingRAG;
    this.nonCascadingRAG = transformerProperties.nonCascadingRAG;
    this.ohEnvironment = transformerProperties.ohEnvironment;
    this.substationId = transformerProperties.substationId;
    this.phaseAngle = transformerProperties.phaseAngle;
    this.phasesAvailable = transformerProperties.phasesAvailable;
    this.poleTermLength = transformerProperties.poleTermLength;
    this.isDefaultPoleTermLength = transformerProperties.isDefaultPoleTermLength;
    this.designVoltage = transformerProperties.designVoltage;
  }

  [key: string]: any;
  geometry: LatLng;
  nameplateRating: number;
  nameplateRatingIsDefault: Boolean;
  nameplateRatingAssigned: number;
  cyclicRating: number;
  mounting: TransformerMounting;
  systemVoltage: number;
  maximumDemandKVA: number;
  maximumDemandKVAPeriod: number;
  maximumUtilisationPercent: number;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  fuseSelection: string;
  fuseSelectionIsDefault: Boolean;
  fuseRating: number;
  maxVoltDropPercent: number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: number;
  maximumExportKVA: number;
  maxVoltRiseGeneration: number;
  maxVoltRiseUnbalance: number;
  autoSelect: boolean;
  groupedConnectionPoints: GroupedConnectionPoint[];
  ratingType: string;
  AllmaximumDemandKVA: number;
  AllmaximumDemandKVAPeriod: number;
  AllmaximumUtilisationPercent: number;
  AllmaxVoltDropPercent: number;
  AllmaxVoltDropPercentPeriod: number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltRiseTotal: number;
  AllmaximumExportKVA: number;
  AllmaxVoltRiseGeneration: number;
  AllmaxVoltRiseUnbalance: number;
  AllmaxVoltRisePercentPeriod: number;
  wintermaximumDemandKVA: number;
  wintermaximumDemandKVAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaxVoltDropPercent: number;
  wintermaxVoltDropPercentPeriod: number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltRisePercent: number;
  wintermaxVoltRiseTotal: number;
  wintermaximumExportKVA: number;
  wintermaxVoltRiseGeneration: number;
  wintermaxVoltRiseUnbalance: number;
  wintermaxVoltRisePercentPeriod: number;
  summermaximumDemandKVA: number;
  summermaximumDemandKVAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaxVoltDropPercent: number;
  summermaxVoltDropPercentPeriod: number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltRisePercent: number;
  summermaxVoltRiseTotal: number;
  summermaximumExportKVA: number;
  summermaxVoltRiseGeneration: number;
  summermaxVoltRiseUnbalance: number;
  summermaxVoltRisePercentPeriod: number;
  nodeNumber: number;
  hvFaultLevel: number;
  hvFaultLevelIsDefault: Boolean;
  xrRatio: number;
  xrRatioIsDefault: Boolean;
  annotation: string;
  flickerP28Primary: number;
  flickerP28PrimaryPassResult: Boolean;
  flickerP28Secondary: number;
  flickerP28SecondaryPassResult: Boolean;
  cascadingRAG: string;
  nonCascadingRAG: string;
  ohEnvironment: string;
  substationId: string;
  phaseAngle: number;
  phasesAvailable: number;
  poleTermLength: number;
  isDefaultPoleTermLength: Boolean;
  designVoltage: number;
}
