import React, { useState } from "react";
import { Input } from "reactstrap";

interface NegativeNumericInputProps {
  id: string;
  title?: string | undefined;
  isDisabled?: boolean | undefined;
  value: number;
  setValue: (value: number) => void;
}

const NegativeNumericInput = ({
  id,
  title,
  isDisabled,
  value,
  setValue,
}: NegativeNumericInputProps) => {
  const [valueVisible, setValueVisible] = useState<string>(value?.toString() ?? "0");
  const updateValue = (currentValue: string) => {
    if (currentValue === "-") {
      setValueVisible(currentValue);
      setValue(0);
      return;
    }
    if (currentValue === "") {
      setValueVisible("");
      setValue(0);
      return;
    }
    const number = Number(currentValue);
    if (!isNaN(number)) {
      setValueVisible(currentValue);
      setValue(number);
    }
  };
  const startEditing = (currentValue: string) => {
    if (currentValue === "0") {
      updateValue("");
    }
  };
  const finaliseValue = (currentValue: string) => {
    if (currentValue === "" || currentValue === "-") {
      updateValue("0");
    }
  };

  return (
    <Input
      type="text"
      id={id}
      name={id}
      inputMode="numeric"
      value={valueVisible}
      disabled={isDisabled}
      onFocus={(e) => startEditing(e.target.value)}
      onChange={(e) => updateValue(e.target.value)}
      onBlur={(e) => finaliseValue(e.target.value)}
      title={title}
      autoComplete="off"
    />
  );
};

export default NegativeNumericInput;
