export const splitFilename = (filename: string) => {
  const parts = filename.replace(/^.*[\\\/]/, "").split(".");
  const extension = parts.length > 1 ? parts[parts.length - 1] : "";
  parts.pop();
  const name = parts.join(".");
  return {
    extension: extension,
    name: name,
    fullName: `${name}.${extension}`,
  };
};

export const validateFiles = (files: any, fileTypes: string[]) => {
  const splitFileNames = files.map(
    (m: any) => `.${splitFilename(m.name).extension?.toLowerCase()}`,
  );
  const filterFileNames = splitFileNames.filter((p: string) => !fileTypes.includes(p));
  const dedupeFileNames = filterFileNames.filter(
    (v: string[], i: number) => filterFileNames.indexOf(v) === i,
  );
  return dedupeFileNames.length > 1
    ? `${dedupeFileNames.join(" and ")} are invalid file formats`
    : dedupeFileNames.length === 1
      ? `${dedupeFileNames[0]} is an invalid file format`
      : "";
};
