// @flow
import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";

interface IRagCableProperties {
  geometry: LatLng[];
  cableGroup: string;
  cascadingRAG: string;
  nonCascadingRAG: string;
}

export class RagCableProperties implements IRagCableProperties {
  geometry: LatLng[];
  cableGroup: string;
  styles: any;
  cascadingRAG: string;
  nonCascadingRAG: string;
}

export class RagCable extends Asset implements IRagCableProperties {
  constructor(id: string, cableProperties: RagCableProperties) {
    super(id);
    this.geometry = cableProperties.geometry;
    this.cableGroup = cableProperties.cableGroup;
    this.styles = cableProperties.styles;
    this.cascadingRAG = cableProperties.cascadingRAG;
    this.nonCascadingRAG = cableProperties.nonCascadingRAG;
  }

  geometry: LatLng[];
  cableGroup: string;
  styles: any;
  cascadingRAG: string;
  nonCascadingRAG: string;
}
