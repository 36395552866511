import React, { useContext } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import SpinnerButton from "./SpinnerButton";
import { ToolContext } from "../context/ToolContext";

const RunAssessment = () => {
  useHotkeys("shift+r", () => runAssessment());
  const { toolState, setToolState } = useContext(ToolContext);
  const { assessmentRunning } = toolState;

  const runAssessment = () => {
    const _toolState = toolState;
    _toolState.assessmentRunning = true;
    _toolState.drawBoundary = false;
    _toolState.ringfenced = [];
    _toolState.ringfencedFiltered = [];
    _toolState.ringfencedTypes = [];
    _toolState.disableBoundary = false;
    setToolState(_toolState);
  };

  return (
    <SpinnerButton
      id="runAssessment"
      onClick={runAssessment}
      showSpinner={assessmentRunning}
      text="Run Assessment"
      color="success"
      classes="btn-sm my-2 my-sm-0 mr-1"
      icon="play"
    />
  );
};

export default RunAssessment;
