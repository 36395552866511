import { FormGroup, Label } from "reactstrap";
import { useContext } from "react";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

const DynamicPropertiesList = ({ propertyType }) => {
  const { formState } = useContext(FormContext);
  const { toolState } = useContext(ToolContext);
  const { clientSettings } = formState;

  var properties = clientSettings.dynamicProperties?.sort(
    (a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder),
  );

  return (
    <div>
      {properties
        .filter((f) => f.propertyType === propertyType)
        .map((item) => (
          <FormGroup key={item.attributeValue}>
            <Label className="id">
              {item.displayName}: {toolState.clickedAsset[item.attributeValue]}
            </Label>
          </FormGroup>
        ))}
    </div>
  );
};

export default DynamicPropertiesList;
