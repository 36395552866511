// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { PointOfConnection } from "./pointOfConnection";
import { PointOfConnectionProperties } from "./pointOfConnectionProperies";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import { convertAndFormat } from "../../utils/numberFunctions";

export function createPointOfConnectionFromConductingEquipment(
  conductingEquipment: ConductingEquipment,
  substationId: any,
): PointOfConnection {
  const pointOfConnectionProperties = new PointOfConnectionProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  pointOfConnectionProperties.geometry = getLatLngFromWktPoint(geometryString);
  pointOfConnectionProperties.annotation = conductingEquipment.getPropertyValue("annotation");
  pointOfConnectionProperties.styles = getAssetStyleInfo(
    conductingEquipment.getPropertyValue("class"),
  );
  pointOfConnectionProperties.voltDropPercent =
    conductingEquipment.getPropertyValue("voltDropPercent");
  pointOfConnectionProperties.loopResistanceMilliohm =
    conductingEquipment.getPropertyValue("loopResistanceMilliohm");
  pointOfConnectionProperties.includesTransformerResistance =
    conductingEquipment.getPropertyValue("includesTransformerResistance") === "true";
  pointOfConnectionProperties.maxVoltDropPercent =
    conductingEquipment.getPropertyValue("maxVoltDropPercent");
  pointOfConnectionProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") === "true";
  pointOfConnectionProperties.maxVoltRisePercent =
    conductingEquipment.getPropertyValue("maxVoltRisePercent");
  pointOfConnectionProperties.maxVoltRiseTotal =
    conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  pointOfConnectionProperties.maxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("maxVoltRiseGeneration");
  pointOfConnectionProperties.maxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("maxVoltRiseUnbalance");
  pointOfConnectionProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance") == null
      ? null
      : Number(conductingEquipment.getPropertyValue("earthLoopImpedance"));

  pointOfConnectionProperties.threePhaseFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("threePhaseFaultCurrent"),
  );

  pointOfConnectionProperties.phaseToPhaseFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("phaseToPhaseFaultCurrent"),
  );

  pointOfConnectionProperties.singlePhaseToEarthFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("singlePhaseToEarthFaultCurrent"),
  );

  pointOfConnectionProperties.flickerPassResult =
    conductingEquipment.getPropertyValue("flickerPassResult") === "true";
  pointOfConnectionProperties.flickerDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerDeltaVoltage"),
  );
  pointOfConnectionProperties.flickerP28Limit = Number(
    conductingEquipment.getPropertyValue("flickerP28Limit"),
  );
  pointOfConnectionProperties.flickerStartingPassResult =
    conductingEquipment.getPropertyValue("flickerStartingPassResult") === "true";
  pointOfConnectionProperties.flickerStartingDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerStartingDeltaVoltage"),
  );
  pointOfConnectionProperties.impedanceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceLoopOperating");
  pointOfConnectionProperties.impedanceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceLoopOperatingPassResult") === "true";
  pointOfConnectionProperties.impedanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "impedanceSourceLoopOperating",
  );
  pointOfConnectionProperties.impedanceSourceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperatingPassResult") === "true";
  pointOfConnectionProperties.impedanceLoopFault =
    conductingEquipment.getPropertyValue("impedanceLoopFault");
  pointOfConnectionProperties.impedancePhaseOperating =
    conductingEquipment.getPropertyValue("impedancePhaseOperating");
  pointOfConnectionProperties.impedanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "impedanceSourcePhaseOperating",
  );
  pointOfConnectionProperties.impedanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("impedanceSourcePhasePhaseOperating");
  pointOfConnectionProperties.impedancePhaseFault =
    conductingEquipment.getPropertyValue("impedancePhaseFault");
  pointOfConnectionProperties.resistanceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceLoopOperating");
  pointOfConnectionProperties.resistanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "resistanceSourceLoopOperating",
  );
  pointOfConnectionProperties.resistanceLoopFault =
    conductingEquipment.getPropertyValue("resistanceLoopFault");
  pointOfConnectionProperties.resistancePhaseOperating = conductingEquipment.getPropertyValue(
    "resistancePhaseOperating",
  );
  pointOfConnectionProperties.resistanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "resistanceSourcePhaseOperating",
  );
  pointOfConnectionProperties.resistanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("resistanceSourcePhasePhaseOperating");
  pointOfConnectionProperties.resistancePhaseFault =
    conductingEquipment.getPropertyValue("resistancePhaseFault");
  pointOfConnectionProperties.reactanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "reactanceSourceLoopOperating",
  );
  pointOfConnectionProperties.reactanceLoopFault =
    conductingEquipment.getPropertyValue("reactanceLoopFault");
  pointOfConnectionProperties.reactancePhaseOperating =
    conductingEquipment.getPropertyValue("reactancePhaseOperating");
  pointOfConnectionProperties.reactanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "reactanceSourcePhaseOperating",
  );
  pointOfConnectionProperties.reactanceSourcePhasePhaseOperating =
    conductingEquipment.getPropertyValue("reactanceSourcePhasePhaseOperating");
  pointOfConnectionProperties.reactancePhaseFault =
    conductingEquipment.getPropertyValue("reactancePhaseFault");
  pointOfConnectionProperties.AllmaxVoltDropPercent =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercent");
  pointOfConnectionProperties.AllmaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPercentPeriod",
  );
  pointOfConnectionProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") === "true";
  pointOfConnectionProperties.AllmaxVoltDropPhase =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPhase");
  pointOfConnectionProperties.AllmaxVoltRisePercent =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercent");
  pointOfConnectionProperties.AllmaxVoltRisePercent =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercent");
  pointOfConnectionProperties.AllmaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseTotal");
  pointOfConnectionProperties.AllmaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "AllmaxVoltRiseGeneration",
  );
  pointOfConnectionProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  pointOfConnectionProperties.AllmaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePercentPeriod",
  );
  pointOfConnectionProperties.AllmaxVoltRisePhase =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePhase");
  pointOfConnectionProperties.AllmaxVoltage = conductingEquipment.getPropertyValue("AllmaxVoltage");
  pointOfConnectionProperties.AllmaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase1");
  pointOfConnectionProperties.AllmaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase2");
  pointOfConnectionProperties.AllmaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase3");
  pointOfConnectionProperties.AllminVoltage = conductingEquipment.getPropertyValue("AllminVoltage");
  pointOfConnectionProperties.AllminVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase1");
  pointOfConnectionProperties.AllminVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase2");
  pointOfConnectionProperties.AllminVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase3");
  pointOfConnectionProperties.wintermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercent",
  );
  pointOfConnectionProperties.wintermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercentPeriod",
  );
  pointOfConnectionProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPassResult") === "true";
  pointOfConnectionProperties.wintermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPhase");
  pointOfConnectionProperties.wintermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercent",
  );
  pointOfConnectionProperties.wintermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseTotal");
  pointOfConnectionProperties.wintermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseGeneration",
  );
  pointOfConnectionProperties.wintermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseUnbalance",
  );
  pointOfConnectionProperties.wintermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercentPeriod",
  );
  pointOfConnectionProperties.wintermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePhase");
  pointOfConnectionProperties.wintermaxVoltage =
    conductingEquipment.getPropertyValue("wintermaxVoltage");
  pointOfConnectionProperties.wintermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase1");
  pointOfConnectionProperties.wintermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase2");
  pointOfConnectionProperties.wintermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase3");
  pointOfConnectionProperties.winterminVoltage =
    conductingEquipment.getPropertyValue("winterminVoltage");
  pointOfConnectionProperties.winterminVoltagePhase1 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase1");
  pointOfConnectionProperties.winterminVoltagePhase2 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase2");
  pointOfConnectionProperties.winterminVoltagePhase3 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase3");
  pointOfConnectionProperties.summermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercent",
  );
  pointOfConnectionProperties.summermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercentPeriod",
  );
  pointOfConnectionProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPassResult") === "true";
  pointOfConnectionProperties.summermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("summermaxVoltDropPhase");
  pointOfConnectionProperties.summermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercent",
  );
  pointOfConnectionProperties.summermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("summermaxVoltRiseTotal");
  pointOfConnectionProperties.summermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseGeneration",
  );
  pointOfConnectionProperties.summermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseUnbalance",
  );
  pointOfConnectionProperties.summermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercentPeriod",
  );
  pointOfConnectionProperties.summermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("summermaxVoltRisePhase");
  pointOfConnectionProperties.summermaxVoltage =
    conductingEquipment.getPropertyValue("summermaxVoltage");
  pointOfConnectionProperties.summermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase1");
  pointOfConnectionProperties.summermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase2");
  pointOfConnectionProperties.summermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase3");
  pointOfConnectionProperties.summerminVoltage =
    conductingEquipment.getPropertyValue("summerminVoltage");
  pointOfConnectionProperties.summerminVoltagePhase1 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase1");
  pointOfConnectionProperties.summerminVoltagePhase2 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase2");
  pointOfConnectionProperties.summerminVoltagePhase3 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase3");
  pointOfConnectionProperties.phaseImpedance =
    conductingEquipment.getPropertyValue("phaseImpedance");
  pointOfConnectionProperties.loopImpedance = conductingEquipment.getPropertyValue("loopImpedance");
  pointOfConnectionProperties.nodeNumber = conductingEquipment.getPropertyValue("nodeNumber");
  if (parseInt(conductingEquipment.getPropertyValue("phase1AutoConsumers") ?? 0) > 0) {
    pointOfConnectionProperties.phaseAllocation = "L1 + ";
  } else {
    pointOfConnectionProperties.phaseAllocation = "";
  }
  if (parseInt(conductingEquipment.getPropertyValue("phase2AutoConsumers") ?? 0) > 0) {
    pointOfConnectionProperties.phaseAllocation =
      pointOfConnectionProperties.phaseAllocation + "L2 + ";
  }
  if (parseInt(conductingEquipment.getPropertyValue("phase3AutoConsumers") ?? 0) > 0) {
    pointOfConnectionProperties.phaseAllocation =
      pointOfConnectionProperties.phaseAllocation + "L3 + ";
  }

  if (pointOfConnectionProperties.phaseAllocation)
    pointOfConnectionProperties.phaseAllocation = pointOfConnectionProperties.phaseAllocation
      .trimRight()
      .replace(/\+\s*$/, "")
      .trimRight();

  pointOfConnectionProperties.rootTransformerId =
    conductingEquipment.getPropertyValue("rootTransformerId");

  pointOfConnectionProperties.substationId ??= substationId;

  return new PointOfConnection(conductingEquipment.id, pointOfConnectionProperties);
}
