import { useEffect, useState } from "react";
import escape from "regexp.escape";

export const getSpanList = (text, term) => {
  const split = text.split(new RegExp(escape(term), "gi"));

  const lengthStringSplit = split.map((s) => s.length);

  const lengthList = split
    .map((s) => s.length)
    .map((x, index) => (index < lengthStringSplit.length - 1 ? [x, term.length] : [x]))
    .flatMap((x) => x);

  const indexFullList = lengthList.map((_, k) =>
    lengthList.slice(0, k + 1).reduce((a, c) => a + c, 0),
  );

  const final = indexFullList.map((searchIndex, k) => ({
    s: text.slice(k ? indexFullList[k - 1] : 0, searchIndex),
    h: !!(k % 2),
  }));

  return text === term ? [{ s: text, h: true }] : final;
};

function removeStudyListViewValues(value, index, arr) {
  if (value !== "userDisplayName") {
    arr.splice(index, 1);
    return true;
  }
  return false;
}

export const useSearch = (data = [], keys = [], studyListViewLimitedEnabled) => {
  var searchBy = Array.isArray(keys) && keys.length ? keys : ["studyName", "userDisplayName"];

  if (studyListViewLimitedEnabled) {
    searchBy = searchBy.filter(removeStudyListViewValues);
  }

  const [list, setList] = useState([]);
  const [term, setTerm] = useState("");

  const searchResultList = data.filter((file) =>
    term.trim()
      ? searchBy
          .map((objectKey) => file[objectKey].toLowerCase().split(term.toLowerCase()).length > 1)
          .filter(Boolean).length > 0
      : false,
  );

  useEffect(() => {
    setList(searchResultList);
  }, [term, data]); // eslint-disable-line

  return [term ? list : data, setTerm, term];
};
