import { updateCable } from "../../app/networkSlice";
import { AppDispatch } from "../../app/store";
import * as statusConstants from "../../constants/status";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";

export const shouldShowCableTypeForm = (
  ringfencedTypes: RingfencedElement[],
  selected: string,
): boolean => {
  const shouldShow = (type: string) => {
    switch (type) {
      case "Mains Underground Cable":
      case "Service Underground Cable":
      case "Mains Overhead Line":
      case "Service Overhead Line":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed =
      ringfencedTypes.length > 1 || ringfencedTypes.some((p) => !shouldShow(p.name));
    return !isNotAllowed;
  }
  if (selected === "Conductors") {
    return ringfencedTypes.filter((p) => shouldShow(p.name)).length === 1;
  }
  return shouldShow(selected);
};

export const refreshCableTypeWarning = (
  ringfencedFiltered: RingfencedElement[],
  ohWhitelist: string[],
  ugWhitelist: string[],
  cableType: string | undefined,
  cableTypeWarning: boolean,
  setCableTypeWarning: (cableTypeWarning: boolean) => void,
  status: string | undefined,
): void => {
  if (
    !ohWhitelist.includes(cableType ?? "") &&
    ringfencedFiltered.some(
      (p) =>
        p.cableGroup &&
        p.cableGroup.split("-")[1] === "overhead" &&
        (status ?? p.status) !== statusConstants.EXISTING,
    )
  ) {
    setCableTypeWarning(true);
  } else if (
    !ugWhitelist.includes(cableType ?? "") &&
    ringfencedFiltered.some(
      (p) =>
        p.cableGroup &&
        p.cableGroup.split("-")[1] === "underground" &&
        (status ?? p.status) !== statusConstants.EXISTING,
    )
  ) {
    setCableTypeWarning(true);
  } else if (cableTypeWarning) {
    setCableTypeWarning(false);
  }
};

export const applyCableTypeChange = (
  ringfencedFiltered: RingfencedElement[],
  cableType: string,
  dispatchRedux: AppDispatch,
): void => {
  ringfencedFiltered.forEach((p) => {
    dispatchRedux(updateCable({ id: p.id, name: "cableType", value: cableType }));
    dispatchRedux(
      updateCable({
        id: p.id,
        name: "autoSelect",
        value: cableType === "auto" ? true : false,
      }),
    );
    dispatchRedux(updateCable({ id: p.id, name: "cableTypeIsDefault", value: false }));
  });
};
