import { ConnectionPoint } from "./connectionPoint";
import { ConnectionPointProperties } from "./connectionPointProperties";

// Group within container. this.count is equal to one of the following:
// this.connectionPointProperties.phase1Consumers
// this.connectionPointProperties.phase2Consumers
// this.connectionPointProperties.phase3Consumers
// this.connectionPointProperties.phaseAutoConsumers
export class GroupedConnectionPoint extends ConnectionPoint {
  constructor(
    id: string,
    connectionPointProperties: ConnectionPointProperties,
    subGroupConnectionPoints: ConnectionPoint[],
    count: number,
    parentId: string,
  ) {
    super(id, connectionPointProperties);
    this.subGroupConnectionPoints = subGroupConnectionPoints;
    this.count = count;
    this.parentId = parentId;
  }

  [key: string]: any;
  parentId: string;
  subGroupConnectionPoints: ConnectionPoint[];
  count: number;
}
