import { v4 as uuid } from "uuid";
import rfdc from "rfdc";

const clone = rfdc();

export const addGenerator = (groupedConnections: any) => {
  const clonedGroupConnections = clone(groupedConnections);

  clonedGroupConnections.forEach((gcp: any) => {
    gcp.groupedConnectionPoints.forEach((cp: any) => {
      cp.subGroupConnectionPoints.forEach((p: any) => {
        if (p.styles.name === "Generator") {
          const groupedConnection = {
            annotation: "",
            groupedConnectionPoints: [
              {
                annotation: "",
                consumerType: p.consumerType,
                id: p.id,
                isGenerator: true,
                isSubGroupConnection: true,
                nodeNumber: cp.nodeNumber,
                parentId: cp.id,
                status: p.status,
                subGroupConnectionPoints: [],
                geometry: cp.geometry,
                numberOfPhases: cp.numberOfPhases,
              },
            ],
            id: uuid(),
            nodeNumber: cp.nodeNumber,
            status: "new",
          };
          clonedGroupConnections.push(groupedConnection);
        }
      });
    });
  });
  return clonedGroupConnections;
};
