import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Input, Label } from "reactstrap";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { updateGroupedConnection } from "../app/networkSlice";

const MultiOccupancySelector = () => {
  const { toolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { groupedConnectionProperties } = formState;
  const { multiOccupancyContainer } = groupedConnectionProperties;
  const { clickedAsset } = toolState;

  const dispatchRedux = useDispatch();

  const toggleMultiOccupancy = () => {
    let newValue = undefined;
    if (!multiOccupancyContainer?.enabled) {
      newValue = {
        enabled: true,
        numSpareWays: 0,
      };
    }

    updateNode(newValue);
  };

  const updateNumSpareWays = (numSpareWays) => {
    const newValue = {
      ...multiOccupancyContainer,
      numSpareWays: numSpareWays,
    };

    updateNode(newValue);
  };

  const setNumSpareWaysToZeroIfEmpty = (numSpareWays) => {
    if (numSpareWays === "") {
      updateNumSpareWays(0);
    }
  };

  const updateNode = (value) => {
    dispatch({
      form: "groupedConnectionProperties",
      field: "multiOccupancyContainer",
      value: value,
      type: "UPDATE_FIELD",
    });
    dispatchRedux(
      updateGroupedConnection({ id: clickedAsset.id, name: "multiOccupancyContainer", value }),
    );
  };

  return (
    <>
      <div
        className="bg-white-alpha d-flex flex-column"
        style={{
          padding: ".75rem",
          margin: ".75rem 0",
          marginBottom: "1rem",
        }}
      >
        <div className="d-flex w-100" onClick={() => toggleMultiOccupancy()}>
          <h6
            className="text-light"
            style={{
              fontSize: ".75rem",
              textTransform: "uppercase",
              fontWeight: 200,
            }}
          >
            Multi-Occupancy
          </h6>
          <span className="ml-auto">
            <i
              className={`icon-${
                multiOccupancyContainer && multiOccupancyContainer.enabled ? "check" : "cross"
              }-solid pr-2`}
            ></i>
          </span>
        </div>
        {multiOccupancyContainer && multiOccupancyContainer.enabled && (
          <div
            className="bg-white-alpha d-flex align-items-baseline"
            style={{
              padding: ".75rem",
              margin: ".75rem 0",
            }}
          >
            <Label for="spareWays">Spare MSDB Ways</Label>
            <Input
              type="number"
              name="spareWays"
              className="w-25 ml-auto no-spinner"
              placeholder="0"
              value={multiOccupancyContainer.numSpareWays}
              onChange={(e) => updateNumSpareWays(e.target.value)}
              onBlur={(e) => setNumSpareWaysToZeroIfEmpty(e.target.value)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MultiOccupancySelector;
