import React, { useState } from "react";
import { Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ConsumerSelector = ({ header, consumer, count, selectList, addItem }) => {
  const [selectedItem, setSelectedItem] = useState(selectList[0].class);
  const [val, setVal] = useState(count || 1);

  const handleSubmit = (event) => {
    event.preventDefault();
    addItem(consumer, selectedItem, val);
    setVal(1);
  };

  return (
    <div
      className="bg-white-alpha"
      style={{
        padding: ".75rem",
        margin: ".75rem 0",
      }}
    >
      <h6
        className="text-light"
        style={{
          fontSize: ".75rem",
          textTransform: "uppercase",
          fontWeight: 200,
        }}
      >
        {header}
      </h6>
      <div className="text-light d-flex">
        <Input
          type="select"
          name="item"
          className="w-100"
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        >
          {selectList.map((item) => (
            <option key={item.class} value={item.class}>
              {item.name}
            </option>
          ))}
        </Input>
        <Input
          type={count ? "hidden" : "number"}
          name="count"
          className="w-25 ml-2"
          value={val}
          min="0"
          onChange={(e) => setVal(e.target.value)}
        />
        <Button size="sm" color="success" className="ml-2" onClick={handleSubmit}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
    </div>
  );
};

export default ConsumerSelector;
