import { Col, FormGroup, Input, Label } from "reactstrap";
import { useContext } from "react";
import BtnGrp from "../BtnGrp";
import NegativeNumericInput from "../NegativeNumericInput";
import { FormContext } from "../../context/FormContext";
import { getConsumptionType, getConsumptionValues } from "./ProfileDemandUpdateActions";
import { GroupedConnection } from "../../model/viewModel/groupedConnection";
import { Reference } from "../../model/reference/referenceTypes";
import { DemandChangeTypeEnum } from "../../constants/groupUpdateTypes";

interface ProfileDemandUpdateProps {
  minimised: boolean;
  setMinimised: (value: boolean) => void;
  ringfencedFiltered: GroupedConnection[];
  consumerType: string | undefined;
  consumptionType: string | undefined;
  dayValue: number | undefined;
  nightValue: number | undefined;
  demandUplift: number | undefined;
  generationUplift: number | undefined;
  demandChangeType: DemandChangeTypeEnum;
  setDemandChangeType: (value: DemandChangeTypeEnum) => void;
  setConsumerType: (value: string) => void;
  setConsumptionType: (value: string) => void;
  setDayValue: (value: number | undefined) => void;
  setNightValue: (value: number | undefined) => void;
  setDemandUplift: (value: number) => void;
  setGenerationUplift: (value: number) => void;
  hasDemand: boolean;
  hasGeneration: boolean;
  allowProfileChange: boolean;
}
const ProfileDemandUpdate = ({
  minimised,
  setMinimised,
  ringfencedFiltered,
  consumerType,
  consumptionType,
  hasDemand,
  hasGeneration,
  demandChangeType,
  setDemandChangeType,
  dayValue,
  nightValue,
  demandUplift,
  generationUplift,
  setConsumerType,
  setConsumptionType,
  setDayValue,
  setNightValue,
  setDemandUplift,
  setGenerationUplift,
  allowProfileChange,
}: ProfileDemandUpdateProps) => {
  const { formState } = useContext(FormContext);
  const reference = formState.reference as Reference;

  const consumerClass =
    ringfencedFiltered?.length > 0
      ? ringfencedFiltered[0].ringfencedPoint?.styles.class ?? ringfencedFiltered[0].styles.class
      : "";

  const setConsumptionValues = (_consumerType: string, _consumptionType: string) => {
    const { day, night } = getConsumptionValues(reference, _consumerType, _consumptionType);
    setDayValue(day);
    setNightValue(night);
  };

  const setConsumerTypeLocal = (_consumerType: string) => {
    setConsumerType(_consumerType);

    const _consumptionType = getConsumptionType(reference, _consumerType);
    if (_consumptionType) {
      setConsumptionType(_consumptionType);
      setConsumptionValues(_consumerType, _consumptionType);
    }
  };

  const setConsumptionTypeLocal = (_consumptionType: string) => {
    if (!consumerType) {
      return;
    }
    setConsumptionType(_consumptionType);
    setConsumptionValues(consumerType, _consumptionType);
  };

  const relativeInput = () => {
    return (
      <>
        <FormGroup row>
          <Label for="demandUplift" sm={6}>
            Demand Uplift (%)
          </Label>
          <Col sm={6}>
            <NegativeNumericInput
              id="demandUplift"
              value={demandUplift ?? 0}
              isDisabled={!hasDemand}
              setValue={setDemandUplift}
              title={
                !hasDemand
                  ? "There are no consumers in this selection so the demand uplift cannot be set"
                  : ""
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="generationUplift" sm={6}>
            Generation Uplift (%)
          </Label>
          <Col sm={6}>
            <NegativeNumericInput
              id="generationUplift"
              value={generationUplift ?? 0}
              isDisabled={!hasGeneration}
              setValue={setGenerationUplift}
              title={
                !hasGeneration
                  ? "There are no generators in this selection so the generation uplift cannot be set"
                  : ""
              }
            />
          </Col>
        </FormGroup>
      </>
    );
  };

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label for="status" onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Load Profile/Demand
      </Label>
      {!minimised && allowProfileChange && (
        <>
          <FormGroup row>
            <Label sm={6}>Change Type</Label>
            <Col sm={6}>
              <BtnGrp
                arr={[
                  { id: DemandChangeTypeEnum.Absolute, name: "Absolute" },
                  { id: DemandChangeTypeEnum.Relative, name: "Relative" },
                ]}
                value={demandChangeType}
                setValue={setDemandChangeType}
              />
            </Col>
          </FormGroup>
          {demandChangeType === DemandChangeTypeEnum.Absolute && (
            <>
              <FormGroup>
                <Label for="consumerType">Load Profile</Label>
                <Input
                  type="select"
                  name="consumerType"
                  id="consumerType"
                  value={consumerType}
                  onChange={(e) => setConsumerTypeLocal(e.target.value)}
                >
                  {reference.consumers.types
                    .filter((f) => f[consumerClass])
                    .map((type) => (
                      <option key={type.name} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="consumptionType">Demand Type</Label>
                <Input
                  type="select"
                  name="consumptionType"
                  id="consumptionType"
                  value={consumptionType}
                  onChange={(e) => setConsumptionTypeLocal(e.target.value)}
                >
                  <option key="AnnualConsumption" value="AnnualConsumption">
                    Annual Consumption
                  </option>
                  <option key="MaximumDemandDiversified" value="MaximumDemandDiversified">
                    Maximum Demand (Diversified)
                  </option>
                  <option key="MaximumDemand" value="MaximumDemand">
                    Maximum Demand (Fixed)
                  </option>
                </Input>
              </FormGroup>
              <FormGroup row>
                <Label for="consumptionValue1" sm={6} data-testid="day-label">
                  {`Day (kW${consumptionType === "AnnualConsumption" ? "h" : ""} per customer)`}
                </Label>
                <Col sm={6}>
                  <Input
                    type="number"
                    id="consumptionValue1"
                    name="consumptionValue1"
                    value={dayValue}
                    onChange={(e) =>
                      setDayValue(e.target.value === "" ? undefined : Number(e.target.value) ?? 0)
                    }
                    onBlur={(e) => e.target.value === "" && setDayValue(0)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="consumptionValue2" sm={6} data-testid="night-label">
                  {`Night (kW${consumptionType === "AnnualConsumption" ? "h" : ""} per customer)`}
                </Label>
                <Col sm={6}>
                  <Input
                    type="number"
                    id="consumptionValue2"
                    name="consumptionValue2"
                    value={nightValue}
                    onChange={(e) =>
                      setNightValue(e.target.value === "" ? undefined : Number(e.target.value) ?? 0)
                    }
                    onBlur={(e) => e.target.value === "" && setNightValue(0)}
                  />
                </Col>
              </FormGroup>
            </>
          )}
          {demandChangeType === DemandChangeTypeEnum.Relative && relativeInput()}
        </>
      )}
      {!minimised && !allowProfileChange && relativeInput()}
    </div>
  );
};

export default ProfileDemandUpdate;
