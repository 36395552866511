// @flow
import { ConnectionPointProperties } from "../model/viewModel/connectionPointProperties";

export const getConnectionPointProperties = (
  consumerType,
  consumerTypeIsDefault,
  autoPhase,
  phaseAutoConsumers,
  phase1Consumers,
  phase2Consumers,
  phase3Consumers,
  balancedLoad,
  unbalancePercent,
  lowLoadedPhase,
  consumptionValue1,
  consumptionValue1IsDefault,
  consumptionValue2,
  consumptionValue2IsDefault,
  consumptionType,
  consumptionTypeIsDefault,
  geometry,
  numberOfPhases,
  numberOfPhasesIsDefault,
  phaseMap,
  styles,
  parentId,
  isSubGroupConnection,
  isGenerator,
  groupId,
  annotation,
  status,
  cutOutSize,
  multiOccupancyContainer,
  linkBox,
  potEnds,
  overrideGroundType,
  pole,
) => {
  let connectionPointProperties: ConnectionPointProperties = new ConnectionPointProperties();

  connectionPointProperties.consumerType = consumerType;
  connectionPointProperties.consumerTypeIsDefault = consumerTypeIsDefault;
  connectionPointProperties.autoPhase = autoPhase;
  connectionPointProperties.phaseAutoConsumers = phaseAutoConsumers;
  connectionPointProperties.phase1Consumers = phase1Consumers;
  connectionPointProperties.phase2Consumers = phase2Consumers;
  connectionPointProperties.phase3Consumers = phase3Consumers;
  connectionPointProperties.balancedLoad = balancedLoad;
  connectionPointProperties.unbalancePercent = unbalancePercent;
  connectionPointProperties.lowLoadedPhase = lowLoadedPhase;
  connectionPointProperties.consumptionValue1 = consumptionValue1;
  connectionPointProperties.consumptionValue1IsDefault = consumptionValue1IsDefault;
  connectionPointProperties.consumptionValue2 = consumptionValue2;
  connectionPointProperties.consumptionValue2IsDefault = consumptionValue2IsDefault;
  connectionPointProperties.consumptionType = consumptionType;
  connectionPointProperties.consumptionTypeIsDefault = consumptionTypeIsDefault;
  connectionPointProperties.geometry = geometry;
  connectionPointProperties.numberOfPhases = numberOfPhases;
  connectionPointProperties.numberOfPhasesIsDefault = numberOfPhasesIsDefault;
  connectionPointProperties.phaseMap = phaseMap;
  connectionPointProperties.styles = styles;
  connectionPointProperties.parentId = parentId;
  connectionPointProperties.isSubGroupConnection = isSubGroupConnection;
  connectionPointProperties.isGenerator = isGenerator;
  connectionPointProperties.groupId = groupId;
  connectionPointProperties.annotation = annotation;
  connectionPointProperties.status = status;
  connectionPointProperties.cutOutSize = cutOutSize;
  connectionPointProperties.multiOccupancyContainer = multiOccupancyContainer;
  connectionPointProperties.linkBox = linkBox;
  connectionPointProperties.potEnds = potEnds;
  connectionPointProperties.overrideGroundType = overrideGroundType;
  connectionPointProperties.pole = pole;

  return connectionPointProperties;
};
