import React, { useContext } from "react";
import { Pane, useMap } from "react-leaflet";

import { ToolContext } from "../context/ToolContext";

import RagAsset from "./RagAsset";
import CableGroup from "./cable-group/cable-group";

const RagNetwork = ({ ragNetworks, handleSelect, clickedAsset }) => {
  const SUBSTATION = "substation";
  const { toolState } = useContext(ToolContext);

  const map = useMap();
  // const bounds = map.getBounds();

  const mapZoom = map.getZoom();

  const circleOptions = {
    color: "red",
    fillColor: "red",
    fillOpacity: "1",
  };

  const markerDiameterZoomFilter = [
    135.06, 67.53, 33.76, 16.88, 8.44, 4.31, 2.15, 1.05, 0.53, 0.27, 0.14, 0.07, 0.032,
  ]
    .map((el, k) => ({
      diameter: el,
      zoom: k + 15,
    }))
    .reduce(
      (a, b) => ({
        ...a,
        [b.zoom]: b.diameter,
      }),
      {},
    );

  const filterCableRag = (ragNetworks) => {
    const result = ragNetworks.map((ragNetwork) => {
      const cableList = ragNetwork.cables;
      // .filter((p, k) =>
      //   mapZoom === 15
      //     ? isVisible(bounds, p.geometry) &&
      //       p.cableGroup === "mains-underground"
      //     : isVisible(bounds, p.geometry)
      // )
      // // .filter((x, k) => k % 2)
      // .map((cable) => {
      //   const newCable = {
      //     ...cable,
      //     geometry:
      //       mapZoom === 15
      //         ? [cable.geometry[0], cable.geometry[cable.geometry.length - 1]]
      //         : cable.geometry,
      //   };

      //   return newCable;
      // });

      if (mapZoom === 17) {
        const cableIdList = {};

        const mainsUndergroundList = cableList.filter((cable) => {
          if (cableIdList[cable.id] !== -1) {
            // const newCircle = L.circle(
            //   [cable.geometry[0].lat, cable.geometry[0].lng],
            //   markerDiameterZoomFilter[mapZoom] /
            //     (cable.type === "property" ? 5 / toolState.iconSize : 1.5),
            //   circleOptions
            // );

            // newCircle.addTo(map);

            // const checkCloseNeighbours = cableList.filter(
            //   (neighbour) =>
            //     newCircle.getBounds().contains(neighbour.geometry) &&
            //     cable.id !== neighbour.id
            // );
            // checkCloseNeighbours.forEach(({ id }) => (cableIdList[id] = -1));
            // map.removeLayer(newCircle);

            return true;
          }

          return false;
        });
        return {
          ...ragNetwork,
          cables: mainsUndergroundList,
        };
      }

      return {
        ...ragNetwork,
        cables: cableList,
      };
    });

    return result;
  };
  const cableFilteredList = filterCableRag(ragNetworks);

  return (
    <>
      {
        <>
          <Pane name="rag-network-transformers" style={{ zIndex: "590" }}>
            {ragNetworks.map((ragNetwork) =>
              ragNetwork.transformers.map((transformer) => (
                <RagAsset
                  key={transformer.id}
                  asset={transformer}
                  ragNetwork={ragNetwork}
                  onClick={handleSelect}
                  highlighted={clickedAsset && transformer.id === clickedAsset.id}
                ></RagAsset>
              )),
            )}
          </Pane>

          {ragNetworks.map((ragNetwork) => (
            <CableGroup key={ragNetwork.transformers[0].id} ragNetwork={ragNetwork} />
          ))}
          {/* {cableFilteredList.map((ragNetwork) =>
            ragNetwork.cables.map((cable) => {
              console.log("asdasd", cable);
              return (
                <RagAsset
                  key={cable.id}
                  asset={cable}
                  ragNetwork={ragNetwork}
                ></RagAsset>
              );
            })
          )} */}
        </>
      }
    </>
  );
};

export default RagNetwork;
