export const RED = "rgb(220, 53, 69)";
export const DANGER = "rgb(220, 53, 69)";
export const PRIMARY = "rgb(0, 170, 228)";
export const AMBER = "rgb(255, 193, 7)";
export const WARNING = "rgb(250, 183, 33)";
export const GREEN = "rgb(25, 135, 84)";
export const SUCCESS = "rgb(28, 157, 91)";
export const DEFAULT = "rgb(128,128,128)";
export const THREE_PHASE = "rgb(128, 128, 128)";
export const DARK = "rgb(52, 58, 64)";
export const COLOURBLIND_RED = "rgb(219, 67, 37)";
export const COLOURBLIND_AMBER = "rgb(237, 162, 71)";
export const COLOURBLIND_GREEN = "rgb(87, 196, 173)";
export const HIDDEN_MARKERS_COUNTER = "#1c9d5b";
export const BLUE_TICK = "#5bc0de";
export const DEFAULT_FAULT_COLOR = "rgb(220,5,32)";
export const BLUE_HIGHLIGHT = "rgba(38, 183, 232, 1)";
export const FAULT_MARKER_COLORS = [
  "rgb(220,5,32)",
  "rgb(0,90,181)",
  "rgb(136,204,238)",
  "rgb(211,95,183)",
  "rgb(153,79,0)",
  "rgb(255,194,10)",
  "rgb(230,97,0)",
  "rgb(17,119,51)",
  "rgb(75,0,146)",
  "rgb(212,17,89)",
  "rgb(240,228,66)",
  "rgb(64,176,166)",
];
