import React from "react";
import { Button, ButtonGroup } from "reactstrap";

const RadioBtnGrp = ({ items, textColor, changeValue, selectedValue }) => {
  return (
    <ButtonGroup className="w-100">
      {items.obj.map((item) => (
        <Button
          id={item.id}
          key={item.value}
          //className={`text-${textColor}-alpha`}
          color="dark"
          onClick={() => changeValue(item)}
          active={item.value === selectedValue}
          disabled={item.disabled}
        >
          {item.value}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default RadioBtnGrp;
