import { useState, useEffect } from "react";
import { useMap } from "react-leaflet";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const ZoomControl = () => {
  const map = useMap();
  const [zoomLevel, setzoomLevel] = useState(map.getZoom());

  map.on("zoomend", function () {
    setzoomLevel(map.getZoom());
  });
  useEffect(() => {
    map.setZoom(zoomLevel);
  }, [zoomLevel, map]);

  const setZoom = (value) => {
    setzoomLevel(map.getZoom() + value);
  };

  return (
    <>
      <ButtonGroup vertical>
        <Button
          id="zoom-up"
          color="dark"
          disabled={zoomLevel === map.getMaxZoom()}
          onClick={() => setZoom(1)}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth />
        </Button>
        <Button
          id="zoom-down"
          color="dark"
          disabled={zoomLevel === map.getMinZoom()}
          onClick={() => setZoom(-1)}
        >
          <FontAwesomeIcon icon={faMinus} fixedWidth />
        </Button>
      </ButtonGroup>
    </>
  );
};

export default ZoomControl;
