import React from "react";
import { useContext } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { ToolContext } from "../context/ToolContext";
import IconRadioGrp from "./IconRadioGrp";

export const Identifiers = ({ faults, result, setValue, getFaults }) => {
  const { toolState } = useContext(ToolContext);

  return (
    <>
      <ListGroup flush>
        <ListGroupItem
          tag="a"
          action
          className={`d-flex justify-content-between text-${result.isSet ? result.color : "black"}`}
          onClick={() => setValue({ isSet: !result.isSet }, result)}
        >
          <span className="mr-4">
            {result.name}
            {faults.length > 0 && toolState.showResults && getFaults(result) > 0 && (
              <span className="badge badge-danger ml-1">{getFaults(result)}</span>
            )}
          </span>
          <span>
            {result.isSet && result.season && (
              <IconRadioGrp
                array={[
                  {
                    name: "summer",
                    color: "warning",
                    icon: "sun",
                  },
                  {
                    name: "winter",
                    color: "info",
                    icon: "snowflake",
                  },
                  {
                    name: "All",
                    color: "light",
                    icon: "all",
                  },
                ]}
                value={result.season}
                setValue={(e) => setValue(e, result)}
              />
            )}
            <span className="list-group-item-icon">
              <i className={`icon-${result.isSet ? "check" : "cross"}-solid`}></i>
            </span>
          </span>
        </ListGroupItem>
      </ListGroup>
    </>
  );
};
