import { FormGroup, Label } from "reactstrap";
import * as phase from "../../constants/phase";
import RadioBtnGrp from "../RadioBtnGrp";
import { transformerTypes } from "../../constants/groupUpdateTypes";

const PhaseUpdate = ({ minimised, setMinimised, value, setValue, valueWarning, selected }) => {
  const isTransformerType = () =>
    selected === "Transformers" || transformerTypes.includes(selected);

  return (
    <div className="form-body bg-white-alpha expandable-form">
      <Label for="phases" onClick={() => setMinimised(!minimised)}>
        <span className={`icon-chevron-${!minimised ? "down" : "right"}`}></span>Phase
      </Label>
      {!minimised && (
        <FormGroup>
          <RadioBtnGrp
            items={isTransformerType() ? phase.TRANSFORMER_PHASES : phase.CONSUMER_PHASES}
            changeValue={(e) => setValue(e.value)}
            selectedValue={value}
          />
          {valueWarning && (
            <div className="text-warning py-1">
              <i className="icon-exclamation-solid"></i> This phase is incompatible with one or more
              of the selected transformers
            </div>
          )}
        </FormGroup>
      )}
    </div>
  );
};

export default PhaseUpdate;
