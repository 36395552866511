export const getFileManagementSortItems = () => {
  return [
    { key: "studyName", value: "File Name" },
    { key: "studyCreatedDate", value: "Created Date", selected: true },
  ];
};

export const getAscDescSortItems = () => {
  return [
    { key: "ascending", value: "Ascending" },
    { key: "descending", value: "Descending", selected: true },
  ];
};
