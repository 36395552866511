export const getFormName = (type, selectedType) => {
  let typeName;
  if (type) {
    typeName =
      type === "cable" ? "cable" : type === "transformer" ? "transformer" : "groupedConnection";
  } else {
    typeName =
      selectedType === "Conductors"
        ? "cable"
        : selectedType === "Transformers"
          ? "transformer"
          : "groupedConnection";
  }

  return typeName;
};
