import { useState } from "react";
import { Button } from "reactstrap";
import { DropDownOption } from "./config/ConfigDataMap";

interface Props {
  isDropdown: boolean;
  isModified: boolean;
  canEditCell: boolean;
  value: string;
  dropDownOptions: DropDownOption[];
  viewStyle: object;
  handleRevertToDefault: () => string;
  handleValidate: (value: string) => boolean;
  handleSave: (value: string) => string;
}

const CellEdit = ({
  isDropdown,
  isModified,
  canEditCell,
  value,
  dropDownOptions,
  viewStyle,
  handleRevertToDefault,
  handleValidate,
  handleSave,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  return (
    <>
      <div className="d-flex align-items-center">
        {!editMode && (
          <>
            <span style={viewStyle}>
              {isDropdown ? dropDownOptions.find((opt) => opt.value === value)?.label : value}
            </span>
            {canEditCell && (
              <Button
                title="Edit"
                className={"icon-edit-solid btn btn-light"}
                onClick={() => {
                  setWarningMessage("");
                  setEditedValue(value);
                  setEditMode(!editMode);
                }}
              />
            )}
            {canEditCell && isModified && (
              <Button
                title="Revert to Defaults"
                className={"icon-undo-solid btn btn-light"}
                onClick={() => {
                  setWarningMessage(handleRevertToDefault());
                }}
              />
            )}
            {!canEditCell && isModified && (
              <Button
                title="Cannot Revert to Defaults"
                className={"icon-undo-solid btn btn-light"}
                disabled
              />
            )}
            {!canEditCell && (
              <Button title="Cannot Edit" className={"icon-lock-solid btn btn-light"} disabled />
            )}
          </>
        )}
        {editMode && (
          <>
            {isDropdown ? (
              <select
                defaultValue={value}
                onChange={(e) => {
                  setEditedValue(e.target.value);
                }}
              >
                {dropDownOptions.map((opt) => (
                  <option value={opt.value}>&nbsp;&nbsp;&nbsp;{opt.label}&nbsp;&nbsp;&nbsp;</option>
                ))}
              </select>
            ) : (
              <input
                type="number"
                placeholder="Enter Value"
                autoComplete="off"
                defaultValue={value}
                onChange={(e) => {
                  setEditedValue(e.target.value);
                }}
              />
            )}
            <Button
              title="Save changes"
              className={"icon-check-solid btn btn-light"}
              onClick={() => {
                const isValid = handleValidate(editedValue);
                if (!isValid) {
                  setWarningMessage("Please provide a valid value");
                } else {
                  setWarningMessage(handleSave(editedValue));
                  setEditMode(!editMode);
                }
              }}
            />
            <Button
              title="Discard changes"
              className="icon-cross-solid btn btn-light"
              onClick={() => {
                setWarningMessage("");
                setEditMode(!editMode);
              }}
            />
          </>
        )}
      </div>
      {!!warningMessage.length && <span className="text-danger">{warningMessage}</span>}
    </>
  );
};

export default CellEdit;
