interface LocalOverrideConfig {
  cableAutoSelects: CableAutoSelect[] | null;
  consumerLoadProfiles: ConsumerLoadProfile[] | null;
  constraints: OverrideConstraint[] | null;
  transformerRatings: TransformerRating[] | null;
}

interface OverrideConstraint {
  value: number;
  dataName: string;
}

interface CableAutoSelect {
  cableClass: string;
  name: string;
  autoSelect: boolean;
}

interface ConsumerLoadProfile {
  consumerClass: string;
  loadProfile: string;
  defaultDemandType: string;
  defaultDayDemand: number;
  defaultNightDemand: number;
}

interface TransformerRating {
  mounting: string;
  phaseAngle: number;
  ratingkVA: number;
  autoSelect: boolean;
}

interface AutoSelectGroup {
  name: string;
  cables: string[];
}

interface TransformerAutoSelectGroup {
  groundMounted: boolean;
  phaseAngle: number;
  ratingkVAs: number[];
}

export const hasOverrides = (localOverrideConfig: LocalOverrideConfig | null): boolean => {
  return (
    !!(localOverrideConfig?.cableAutoSelects?.length ?? 0) ||
    !!(localOverrideConfig?.consumerLoadProfiles?.length ?? 0) ||
    !!(localOverrideConfig?.constraints?.length ?? 0) ||
    !!(localOverrideConfig?.transformerRatings?.length ?? 0)
  );
};

export const applyCableAutoSelectOverride = (
  autoSelectGroups: AutoSelectGroup[],
  localOverrideConfig: LocalOverrideConfig,
): AutoSelectGroup[] => {
  let mergedData = autoSelectGroups;

  if (localOverrideConfig?.cableAutoSelects?.length) {
    mergedData = autoSelectGroups.map((grp) => {
      return {
        name: grp.name,
        cables: [...grp.cables],
      };
    });
    localOverrideConfig.cableAutoSelects.forEach((overrideCable) => {
      let group = mergedData.find((grp) => overrideCable.cableClass === grp.name);
      if (overrideCable.autoSelect) {
        if (!group) {
          group = {
            name: overrideCable.cableClass,
            cables: [],
          };
          mergedData.push(group);
        }
        if (!group.cables.includes(overrideCable.name)) {
          group.cables.push(overrideCable.name);
        }
      } else {
        const index = group?.cables.indexOf(overrideCable.name) ?? -1;
        if (index >= 0) {
          group?.cables.splice(index, 1);
        }
      }
    });
  }
  return mergedData;
};

export const applyConstraintsOverride = (
  constraints: any,
  localOverrideConfig: LocalOverrideConfig,
): number => {
  const mergedConstraints = { ...constraints };

  if (localOverrideConfig?.constraints?.length) {
    localOverrideConfig.constraints.forEach((overrideConstraint) => {
      const keyToFind =
        overrideConstraint.dataName === "MaxVoltDropMainsPercent"
          ? "MaxVoltDropPercent"
          : overrideConstraint.dataName;
      const keyName = Object.keys(constraints).find(
        (key) => key.toLowerCase() === keyToFind.toLowerCase(),
      );
      if (keyName) {
        mergedConstraints[keyName] = overrideConstraint.value;
      }
    });
  }
  return mergedConstraints;
};

export const applyConsumerTypeOverride = (
  consumerClass: string,
  sourceType: any,
  localOverrideConfig: LocalOverrideConfig,
): any => {
  const overrideType = localOverrideConfig?.consumerLoadProfiles?.find(
    (f: any) => f.consumerClass === consumerClass && f.loadProfile === sourceType.name,
  );
  let mergedType = sourceType;
  if (overrideType) {
    mergedType = { ...sourceType };
    if (overrideType.defaultDemandType === "AnnualConsumption") {
      mergedType.defaultAnnualConsumptionDay = overrideType.defaultDayDemand;
      mergedType.defaultAnnualConsumptionNight = overrideType.defaultNightDemand;
      mergedType.demandtypeAC = true;
      mergedType.demandtypeMDD = false;
      mergedType.demandtypeMDF = false;
    } else if (overrideType.defaultDemandType === "MaximumDemandDiversified") {
      mergedType.defaultMaximumDemandDiversifiedDay = overrideType.defaultDayDemand;
      mergedType.defaultMaximumDemandDiversifiedNight = overrideType.defaultNightDemand;
      mergedType.demandtypeAC = false;
      mergedType.demandtypeMDD = true;
      mergedType.demandtypeMDF = false;
    } else if (overrideType.defaultDemandType === "MaximumDemandFixed") {
      mergedType.defaultMaximumDemandDay = overrideType.defaultDayDemand;
      mergedType.defaultMaximumDemandNight = overrideType.defaultNightDemand;
      mergedType.demandtypeAC = false;
      mergedType.demandtypeMDD = false;
      mergedType.demandtypeMDF = true;
    }
  }
  return mergedType;
};

export const applyTransformerAutoSelectOverride = (
  autoSelectGroups: TransformerAutoSelectGroup[],
  localOverrideConfig: LocalOverrideConfig,
): TransformerAutoSelectGroup[] => {
  let mergedData = autoSelectGroups;

  if (localOverrideConfig?.transformerRatings?.length) {
    mergedData = autoSelectGroups.map((grp) => {
      return {
        groundMounted: grp.groundMounted,
        phaseAngle: grp.phaseAngle,
        ratingkVAs: [...grp.ratingkVAs],
      };
    });
    localOverrideConfig.transformerRatings.forEach((transformerRating) => {
      const groundMounted = transformerRating.mounting === "Ground";
      let group = mergedData.find(
        (grp) =>
          groundMounted === grp.groundMounted && transformerRating.phaseAngle === grp.phaseAngle,
      );
      if (transformerRating.autoSelect) {
        if (!group) {
          group = {
            groundMounted: groundMounted,
            phaseAngle: transformerRating.phaseAngle,
            ratingkVAs: [],
          };
          mergedData.push(group);
        }
        if (!group.ratingkVAs.includes(transformerRating.ratingkVA)) {
          group.ratingkVAs.push(transformerRating.ratingkVA);
          group.ratingkVAs.sort((a, b) => b - a);
        }
      } else {
        const index = group?.ratingkVAs.indexOf(transformerRating.ratingkVA) ?? -1;
        if (index >= 0) {
          group?.ratingkVAs.splice(index, 1);
        }
      }
    });
  }

  return mergedData;
};
