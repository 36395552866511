import { addGroupedConnectionSubChild } from "../../app/networkSlice";
import { v4 as uuid } from "uuid";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";
import { AppDispatch } from "../../app/store";
import { ConnectionPoint } from "../../model/viewModel/connectionPoint";
import { GroupedConnectionPoint } from "../../model/viewModel/groupedConnectionPoint";

export const shouldShowSubGroupForm = (
  ringfencedTypes: RingfencedElement[],
  selected: string,
): boolean => {
  const shouldShow = (type: string) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some((p) => !shouldShow(p.name));
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const applySubGroupChange = (
  ringfencedFiltered: RingfencedElement[],
  subGroups: ConnectionPoint[],
  setSubGroups: (subGroups: ConnectionPoint[]) => void,
  dispatchRedux: AppDispatch,
): void => {
  ringfencedFiltered.forEach((p) => {
    subGroups.forEach((child) => {
      const doUpdate = (point: GroupedConnectionPoint) => {
        const newChild = { ...child, geometry: p.geometry, parentId: point.id, id: uuid() };
        dispatchRedux(
          addGroupedConnectionSubChild({
            id: p.id,
            child: newChild,
            groupedConnectionPointId: point.id,
            isGroupUpdate: false,
          }),
        );
      };
      if (p.ringfencedPoint) {
        doUpdate(p.ringfencedPoint);
      } else {
        p.groupedConnectionPoints.forEach((q) => {
          doUpdate(q);
        });
      }
    });
  });

  setSubGroups([]);
};
