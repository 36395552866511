import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";

const ShowNearbyAssetToggle = ({ type, name }) => {
  const SUBSTATION = "substation";

  const { formState } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { clientSettings } = formState;
  const { useAntData } = toolState;

  const toggleVisibility = () => {
    if (type === SUBSTATION) {
      setToolState({
        showNearbyAssets: {
          ...toolState.showNearbyAssets,
          substations: !toolState.showNearbyAssets.substations,
        },
      });
    }
  };

  const isShown = () => {
    if (toolState.showNearbyAssets.disabled) {
      return false;
    }

    if (type === SUBSTATION) {
      return toolState.showNearbyAssets.substations;
    }
  };

  const toggleUseAntData = () => {
    setToolState({
      useAntData: !toolState.useAntData,
    });
  };

  return (
    <div className="d-flex">
      <div className="show-nearby-assets px-3 pb-2" disabled={toolState.showNearbyAssets.disabled}>
        <span onClick={() => toggleVisibility()}>
          <FontAwesomeIcon icon={isShown() ? faCheck : faTimes} fixedWidth />
          {`Show Nearby ${name}`}
        </span>
      </div>

      {clientSettings.features.DataBasedANTEnabled &&
        clientSettings.features.ImportNetworkUseGeometryOptionEnabled && (
          <div className="show-nearby-assets pb-2">
            <span onClick={toggleUseAntData}>
              <FontAwesomeIcon icon={!useAntData ? faCheck : faTimes} style={{ width: 20 }} />
              {"Use Geometry"}
            </span>
          </div>
        )}
    </div>
  );
};

export default ShowNearbyAssetToggle;
