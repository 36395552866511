export interface ConfigMapData {
  pages: ConfigMapPage[];
}

export interface ConfigMapPage {
  pageDetails: ConfigMap;
  keyColumns: string[];
  groupingColumns: string[];
  overridableColumns: string[];
  overridableRows: string[];
  correctionLimits: CorrectionLimit[];
  dependantColumns: DependantColumn[];
  optionLists: DropDownOptionList[];
  columns: ConfigMapColumn[];
}

export interface DependantColumn {
  sourceColumn: string;
  sourceValue: string;
  targetColumn: string;
  defaultColumn: string;
}

export interface ConfigMapColumn {
  displayName: string;
  dataName: string;
  hidden: boolean;
}

export interface ConfigMap {
  displayName: string;
  dataName: string;
}

export interface CorrectionLimit {
  editedRow: string;
  limitRow: string;
  descriptionColumn: string;
  raiseToLimit: boolean;
}

export interface DropDownOptionList {
  column: string;
  options: DropDownOption[];
}

export interface DropDownOption {
  label: string;
  value: string;
}

export const DataMap: ConfigMapData = {
  pages: [
    {
      pageDetails: {
        displayName: "Cable Auto Select",
        dataName: "cableAutoSelects",
      },
      keyColumns: ["cableClass", "name"],
      groupingColumns: ["cableClass"],
      overridableColumns: ["autoSelect"],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [
        {
          column: "autoSelect",
          options: [
            { label: "Auto-Select", value: "true" },
            { label: "No", value: "false" },
          ],
        },
      ],
      columns: [
        { displayName: "Cable Class", dataName: "cableClass", hidden: false },
        { displayName: "Cable Type", dataName: "name", hidden: false },
        { displayName: "Auto-Select", dataName: "autoSelect", hidden: false },
      ],
    },
    {
      pageDetails: {
        displayName: "Cable Ratings",
        dataName: "cableRatingProfiles",
      },
      keyColumns: [],
      groupingColumns: [],
      overridableColumns: [],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [],
      columns: [
        { displayName: "Cable Type", dataName: "cableType", hidden: false },
        { displayName: "Season", dataName: "profileName", hidden: false },
        { displayName: "Rating Type", dataName: "ratingType", hidden: false },
        { displayName: "Rating (A)", dataName: "ratingA", hidden: false },
      ],
    },
    {
      pageDetails: {
        displayName: "Cable Type Properties",
        dataName: "cableTypes",
      },
      keyColumns: [],
      groupingColumns: [],
      overridableColumns: [],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [],
      columns: [
        { displayName: "Cable Type", dataName: "cableType", hidden: false },
        {
          displayName: "Operating Phase Resistance (miliohms/m)",
          dataName: "operatingPhaseResistancePerMetre",
          hidden: false,
        },
        {
          displayName: "Operating Neutral Resistance (miliohms/m)",
          dataName: "operatingNeutralResistancePerMetre",
          hidden: false,
        },
        {
          displayName: "Operating Phase Reactance (miliohms/m)",
          dataName: "operatingPhaseReactancePerMetre",
          hidden: false,
        },
        {
          displayName: "Operating Neutral Reactance (miliohms/m)",
          dataName: "operatingNeutralReactancePerMetre",
          hidden: false,
        },
        {
          displayName: "Fault Phase Resistance (miliohms/m)",
          dataName: "faultPhaseResistancePerMetre",
          hidden: false,
        },
        {
          displayName: "Fault Neutral Resistance (miliohms/m)",
          dataName: "faultNeutralResistancePerMetre",
          hidden: false,
        },
        {
          displayName: "Fault Phase Reactance (miliohms/m)",
          dataName: "faultPhaseReactancePerMetre",
          hidden: false,
        },
        {
          displayName: "Fault Neutral Reactance (miliohms/m)",
          dataName: "faultNeutralReactancePerMetre",
          hidden: false,
        },
      ],
    },
    {
      pageDetails: {
        displayName: "Constraint Thresholds",
        dataName: "constraints",
      },
      keyColumns: ["dataName"],
      groupingColumns: [],
      overridableColumns: ["value"],
      overridableRows: [
        "MaxVoltDropMainsPercent",
        "MaxVoltDropServicePercent",
        "MaxVoltRisePercent",
        "LoopOperatingImpedanceMainsThreshold",
        "LoopOperatingImpedanceServiceThreshold",
      ],
      correctionLimits: [
        {
          editedRow: "MaxVoltDropMainsPercent",
          limitRow: "MaxVoltDropServicePercent",
          descriptionColumn: "description",
          raiseToLimit: false,
        },
        {
          editedRow: "MaxVoltDropServicePercent",
          limitRow: "MaxVoltDropMainsPercent",
          descriptionColumn: "description",
          raiseToLimit: true,
        },
        {
          editedRow: "LoopOperatingImpedanceMainsThreshold",
          limitRow: "LoopOperatingImpedanceServiceThreshold",
          descriptionColumn: "description",
          raiseToLimit: false,
        },
        {
          editedRow: "LoopOperatingImpedanceServiceThreshold",
          limitRow: "LoopOperatingImpedanceMainsThreshold",
          descriptionColumn: "description",
          raiseToLimit: true,
        },
      ],
      dependantColumns: [],
      optionLists: [],
      columns: [
        { displayName: "Description", dataName: "description", hidden: false },
        { displayName: "Value", dataName: "value", hidden: false },
        { displayName: "DataName", dataName: "dataName", hidden: true },
      ],
    },
    {
      pageDetails: {
        displayName: "Customer Profiles",
        dataName: "consumerLoadProfiles",
      },
      keyColumns: ["consumerClass", "loadProfile"],
      groupingColumns: [],
      overridableColumns: ["defaultDemandType", "defaultDayDemand", "defaultNightDemand"],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "AnnualConsumption",
          targetColumn: "defaultDayDemand",
          defaultColumn: "defaultAnnualConsumptionDay",
        },
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "AnnualConsumption",
          targetColumn: "defaultNightDemand",
          defaultColumn: "defaultAnnualConsumptionNight",
        },
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "MaximumDemandDiversified",
          targetColumn: "defaultDayDemand",
          defaultColumn: "defaultMaximumDemandDiversifiedDay",
        },
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "MaximumDemandDiversified",
          targetColumn: "defaultNightDemand",
          defaultColumn: "defaultMaximumDemandDiversifiedNight",
        },
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "MaximumDemandFixed",
          targetColumn: "defaultDayDemand",
          defaultColumn: "defaultMaximumDemandFixedDay",
        },
        {
          sourceColumn: "defaultDemandType",
          sourceValue: "MaximumDemandFixed",
          targetColumn: "defaultNightDemand",
          defaultColumn: "defaultMaximumDemandFixedNight",
        },
      ],
      optionLists: [
        {
          column: "defaultDemandType",
          options: [
            { label: "Annual Consumption", value: "AnnualConsumption" },
            { label: "Maximum Demand (Diversified)", value: "MaximumDemandDiversified" },
            { label: "Maximum Demand (Fixed)", value: "MaximumDemandFixed" },
          ],
        },
      ],
      columns: [
        { displayName: "Consumer Class", dataName: "consumerClass", hidden: false },
        { displayName: "Load Profile", dataName: "loadProfile", hidden: false },
        { displayName: "Default Demand Type", dataName: "defaultDemandType", hidden: false },
        { displayName: "Default Day Demand", dataName: "defaultDayDemand", hidden: false },
        { displayName: "Default Night Demand", dataName: "defaultNightDemand", hidden: false },

        { displayName: "", dataName: "defaultAnnualConsumptionDay", hidden: true },
        { displayName: "", dataName: "defaultAnnualConsumptionNight", hidden: true },
        { displayName: "", dataName: "defaultMaximumDemandDiversifiedDay", hidden: true },
        { displayName: "", dataName: "defaultMaximumDemandDiversifiedNight", hidden: true },
        { displayName: "", dataName: "defaultMaximumDemandFixedDay", hidden: true },
        { displayName: "", dataName: "defaultMaximumDemandFixedNight", hidden: true },
      ],
    },
    {
      pageDetails: {
        displayName: "System Voltage",
        dataName: "globalSettings",
      },
      keyColumns: [],
      groupingColumns: [],
      overridableColumns: [],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [],
      columns: [{ displayName: "System Voltage (V)", dataName: "systemVoltage", hidden: false }],
    },
    {
      pageDetails: {
        displayName: "Minimum Fault Levels",
        dataName: "minimumFaultLevels",
      },
      keyColumns: [],
      groupingColumns: [],
      overridableColumns: [],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [],
      columns: [
        { displayName: "Cable Type", dataName: "cableType", hidden: false },
        { displayName: "Fuse Rating (A)", dataName: "fuseRatingA", hidden: false },
        { displayName: "Minimum Fault Level (A)", dataName: "minimumFaultLevelA", hidden: false },
      ],
    },
    {
      pageDetails: {
        displayName: "Transformer Properties",
        dataName: "transformerRatings",
      },
      keyColumns: ["mounting", "ratingkVA", "phaseAngle"],
      groupingColumns: ["mounting", "phaseAngle"],
      overridableColumns: ["autoSelect"],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [
        {
          column: "autoSelect",
          options: [
            { label: "Auto-Select", value: "true" },
            { label: "No", value: "false" },
          ],
        },
      ],
      columns: [
        { displayName: "Mounting", dataName: "mounting", hidden: false },
        { displayName: "Phase Angle", dataName: "phaseAngle", hidden: false },
        { displayName: "Rating (kVA)", dataName: "ratingkVA", hidden: false },
        { displayName: "Resistance (O)", dataName: "resistanceO", hidden: false },
        { displayName: "Reactance (O)", dataName: "reactanceO", hidden: false },
        { displayName: "Default Fuse Rating (A)", dataName: "defaultFuseRatingA", hidden: false },
        { displayName: "Auto-Select", dataName: "autoSelect", hidden: false },
      ],
    },
    {
      pageDetails: {
        displayName: "Transformer Ratings",
        dataName: "transformerRatingProfiles",
      },
      keyColumns: [],
      groupingColumns: [],
      overridableColumns: [],
      overridableRows: [],
      correctionLimits: [],
      dependantColumns: [],
      optionLists: [],
      columns: [
        { displayName: "Season", dataName: "profileName", hidden: false },
        { displayName: "Mounting", dataName: "mounting", hidden: false },
        { displayName: "Transformer Size (kVA)", dataName: "transformerSizekVA", hidden: false },
        { displayName: "Rating Type", dataName: "ratingType", hidden: false },
        { displayName: "Rating (kVA)", dataName: "ratingkVA", hidden: false },
      ],
    },
  ],
};
