export const conductorTypes = [
  "Mains Overhead Line",
  "Mains Underground Cable",
  "Service Overhead Line",
  "Service Underground Cable",
];
export const transformerTypes = ["Ground-mounted Transformer", "Pole-mounted Transformer"];
export const propertyTypes = [
  "Domestic Property",
  "Small Commercial Property",
  "Large Commercial Property",
  "Industrial Property",
];
export const lctTypes = ["Generator", "Heat Pump", "EV Chargepoint"];
export const nodeTypes = ["Node", "Service End Node"];

export const groupedTypes: GroupedTypes = {
  Properties: propertyTypes,
  Transformers: transformerTypes,
  LCT: lctTypes,
  Conductors: conductorTypes,
  Nodes: nodeTypes,
};

export interface GroupedTypes {
  [key: string]: string[];
  Properties: string[];
  LCT: string[];
  Conductors: string[];
  Nodes: string[];
}

export enum DemandChangeTypeEnum {
  Absolute = "Absolute",
  Relative = "Relative",
}
