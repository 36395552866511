import React from "react";
import { Button, ButtonGroup } from "reactstrap";

const BtnGrp = ({ arr, value, setValue }) => {
  return (
    <ButtonGroup size="sm">
      {arr.map((item) => (
        <Button
          key={item.id || item}
          color="dark"
          onClick={() => setValue(item.id || item)}
          active={value === (item.id || item)}
        >
          {item.name || item}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default BtnGrp;
