// this service is to enable backwards compatibility for networks that were made before some issues were fixed
import { getDefaultUnbalancedPercent } from "../utils/consumerDefaults";

export const cleanseDownstreamFeederNumbers = (network) => {
  const processedCableIds = [];
  const feeders = network.cables.filter((f) => f.isFirstLeg !== "false");
  feeders.forEach((feeder) => {
    processedCableIds.push(feeder.id);
  });
  feeders.forEach((feeder) => {
    setDownstreamFeederNumberRecursive(feeder, network, processedCableIds);
  });
};

export const setNewDefaults = (network, reference) => {
  network.groupedConnections.forEach((groupedConnection) => {
    groupedConnection.groupedConnectionPoints.forEach((groupedConnectionPoint) => {
      if (groupedConnectionPoint.lowLoadedPhase === undefined) {
        groupedConnectionPoint.lowLoadedPhase = "L1";
      }

      if (groupedConnectionPoint.unbalancePercent === undefined) {
        groupedConnectionPoint.unbalancePercent = getDefaultUnbalancedPercent(
          {
            transformerProperties: {},
            network: network,
          },
          network.transformers,
          network.cables,
          groupedConnection.id,
        );
      }
    });
  });

  if (network.transformers) {
    network.transformers.forEach((transformer) => {
      if (!transformer.designVoltage) {
        transformer.designVoltage = reference.constraints.systemVoltage;
      }
    });
  }
};

const setDownstreamFeederNumberRecursive = (upstreamCable, network, processedCableIds) => {
  const connectedCables = network.cables.filter(
    (c) =>
      c.startAssetId === upstreamCable.endAssetId &&
      processedCableIds.findIndex((p) => c.id === p) === -1,
  );

  if (!connectedCables) {
    return;
  }

  connectedCables.forEach((cable) => {
    processedCableIds.push(cable.id);

    cable.feederNumber = upstreamCable.feederNumber;
    setDownstreamFeederNumberRecursive(cable, network, processedCableIds);
  });
};
