import { getConnectedCablesToNode } from ".//networkFunctions";
import * as linkBoxConstants from "../constants/linkBox";
import * as alert from "../constants/alert";
import { updateGroupedConnectionLinkBoxConnectivity } from "../app/networkSlice";

const CODE_E223 = "E223";
const CODE_E224 = "E224";

export const removeLinkBoxCableFromNode = (groupedConnections, cableId, nodeId, dispatchRedux) => {
  const node = groupedConnections.find((p) => p.id === nodeId);

  if (!node || !node.linkBox || !node.linkBox.connectivity || !node.linkBox.connectivity.closed) {
    return;
  }

  dispatchRedux(
    updateGroupedConnectionLinkBoxConnectivity({
      id: node.id,
      name: "closed",
      value: node.linkBox.connectivity.closed.filter((c) => c !== cableId),
    }),
  );
};

export const removeLinkBoxCable = (
  clientSettings,
  cables,
  groupedConnections,
  cableId,
  dispatchRedux,
) => {
  if (!clientSettings.features.ConfigurableLinkBoxEnabled) {
    return;
  }

  const cable = cables.find((p) => p.id === cableId);

  if (!cable) {
    return;
  }

  removeLinkBoxCableFromNode(groupedConnections, cableId, cable.startAssetId, dispatchRedux);
  removeLinkBoxCableFromNode(groupedConnections, cableId, cable.endAssetId, dispatchRedux);
};

export const attachSplitCableToLinkBox = (
  clientSettings,
  groupedConnections,
  originalCableId,
  newCableId,
  nodeId,
  dispatchRedux,
) => {
  if (!clientSettings.features.ConfigurableLinkBoxEnabled) {
    return;
  }

  const node = groupedConnections.find((p) => p.id === nodeId);

  if (!node || !node.linkBox || !node.linkBox.connectivity || !node.linkBox.connectivity.closed) {
    return;
  }

  if (node.linkBox.connectivity.closed.includes(originalCableId)) {
    const closed = [
      ...node.linkBox.connectivity.closed.filter((c) => c !== originalCableId),
      newCableId,
    ];
    dispatchRedux(
      updateGroupedConnectionLinkBoxConnectivity({ id: node.id, name: "closed", value: closed }),
    );
  }
};

export const listConnectedCables = (cables, clickedAsset) => {
  const defaultLabels = ["A", "B", "C", "D"];

  const c = getConnectedCablesToNode(cables, clickedAsset);

  const connectedCables = c
    .slice(0, 4)
    .concat(Array.from({ length: Math.max(0, 4 - c.length) }, () => undefined))
    .map((cable, index) => ({
      cable,
      cableId: cable?.id,
      title: cable?.annotation?.length > 0 ? cable.annotation : defaultLabels[index],
    }));

  return connectedCables;
};

export const validateLinkBoxCables = (cables, clickedAsset) => {
  if (clickedAsset.pole && clickedAsset.pole.enabled && clickedAsset.pole.poleLinkEnabled) {
    if (getConnectedCablesToNode(cables, clickedAsset).length > 2) {
      const error = {
        code: CODE_E224,
        level: alert.LEVEL_ERROR,
        link: clickedAsset.id,
        description: "Pole link cannot be connected to more than 2 cables",
      };
      return error;
    }
  } else if (clickedAsset.linkBox) {
    const ways = clickedAsset.linkBox.numberOfWays === linkBoxConstants.FOURWAY ? 4 : 2;
    if (getConnectedCablesToNode(cables, clickedAsset).length > ways) {
      const error = {
        code: CODE_E223,
        level: alert.LEVEL_ERROR,
        link: clickedAsset.id,
        description: `Link box cannot be connected to more than ${ways} cables`,
      };
      return error;
    }
  }
  return undefined;
};
