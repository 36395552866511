import React, { useState, useContext } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { ToolContext } from "../context/ToolContext";

const AlertModal = () => {
  const { toolState, setToolState } = useContext(ToolContext);
  const [modal, setModal] = useState(true);
  const { messages, className } = toolState.alert;

  const toggle = () => {
    setModal(!modal);
    setTimeout(() => {
      const _toolState = toolState;
      _toolState.alert = {};
      setToolState(_toolState);
    }, 150);
  };

  const refineMessages = () => {
    const descriptions = messages.map((o) => o.description);
    const duplicateCount = descriptions.reduce((a, c) => {
      a[c] = (a[c] || 0) + 1;
      return a;
    }, {});
    const deduplicatedMessages = messages.filter(
      ({ description }, index) => !descriptions.includes(description, index + 1),
    );
    return deduplicatedMessages.map((msg) => ({
      ...msg,
      count: duplicateCount[msg.description],
    }));
  };

  const refinedMessages = refineMessages();

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
        <div className={`bg-${className} text-white`}>
          <ModalBody className="d-flex justify-content-between">
            {refinedMessages.length > 1 ? (
              <ul>
                {refinedMessages.map((msg) => (
                  <li key={msg.code}>
                    {msg.description}
                    {msg.count > 1 ? ` x ${msg.count}` : ""}
                  </li>
                ))}
              </ul>
            ) : (
              <span>
                {refinedMessages[0].description}{" "}
                {refinedMessages[0].count > 1 ? ` x ${refinedMessages[0].count}` : ""}
              </span>
            )}
            <Button
              outline
              color="light"
              size="sm"
              onClick={toggle}
              style={{ marginLeft: 10, height: 35 }}
            >
              Close
            </Button>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default AlertModal;
