import { ConnectionPoint } from "../model/viewModel/connectionPoint";
import { HasGroupedConnectionPoints } from "../model/viewModel/hasGroupedConnectionPoints";
import { Reference } from "../model/reference/referenceTypes";

const checkConsumer = (
  reference: Reference,
  consumer: ConnectionPoint,
  nonDefaultTypes: string[],
) => {
  const consumerType = reference.consumers.types.find((t) => t.name === consumer.consumerType);

  if (
    consumerType?.eligibleForAdmdAdjustment &&
    nonDefaultTypes.indexOf(consumer.consumerType) < 0 &&
    (parseInt(consumer.consumptionValue1 as any) !== consumerType.defaultAnnualConsumptionDay ||
      parseInt(consumer.consumptionValue2 as any) !== consumerType.defaultAnnualConsumptionNight)
  ) {
    nonDefaultTypes.push(consumer.consumerType);
  }
};

export const checkAdmdConsumerTypes = (
  reference: Reference,
  groupedConnections: HasGroupedConnectionPoints[],
  nonDefaultTypes: string[],
) => {
  groupedConnections.forEach((c) => {
    c.groupedConnectionPoints.forEach((consumer) => {
      checkConsumer(reference, consumer, nonDefaultTypes);

      consumer.subGroupConnectionPoints.forEach((subConsumer) => {
        checkConsumer(reference, subConsumer, nonDefaultTypes);
      });
    });
  });
};
