import { useState, useContext } from "react";
import { Input, InputGroup, ListGroup, ListGroupItem, Button } from "reactstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheck, faUndo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { findAll } from "../app/networkSlice";
import { useLocalStorage } from "../utils/useLocalStorage";

const propertyFilter = [
  "annotation",
  "consumerType",
  "cableType",
  "id",
  "startAssetId",
  "endAssetId",
  "nodeNumber",
  "wpdId",
];

const getAssetType = (asset) => {
  let assetType;
  switch (asset.styles.type) {
    case "transformer":
      assetType = "Transformer";
      break;
    case "cable":
      assetType = "Conductor";
      break;
    case "connectionPoint":
      assetType = "Customer";
      break;
    case "groupedConnection":
      if (asset.groupedConnectionPoints.length) {
        assetType = "Customer";
        break;
      } else {
        assetType = "Node";
        break;
      }
    default:
  }
  return assetType;
};

const StudySearch = () => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { formState } = useContext(FormContext);
  const { network } = formState;
  const [searchValue, setSearchValue] = useState("");
  const [totalMatches, setTotalMatches] = useState("");

  const assetTypes = ["Transformer", "Conductor", "Customer", "Node"];
  const [assetFilter, setAssetFilter] = useLocalStorage("assetFilter", assetTypes);

  const allAssets = useSelector((state) => findAll(state));

  const { leafletMap } = toolState;

  const createAssetFilter = (name) => {
    let newAssetFilter = [...assetFilter];
    if (newAssetFilter.includes(name)) {
      newAssetFilter = newAssetFilter.filter((a) => a !== name);
    } else {
      newAssetFilter.push(name);
    }
    setAssetFilter(newAssetFilter);
  };

  const wildcardMatch = (text, pattern) => {
    const regexPattern = new RegExp(
      "^" +
        pattern
          // Escape all escapable characters except * and ?
          .replace(/[/\-\\^$+.()|[\]{}]/g, "\\$&")
          .replace(/\?/g, ".")
          .replace(/\*/g, ".*") +
        "$",
    );

    return regexPattern.test(text);
  };

  const matchProperty = (asset) => {
    const trimmedSearchValue = searchValue.trim();
    return Object.entries(asset)
      .filter(([k, v]) => propertyFilter.includes(k))
      .some(
        (a) =>
          a[1] && wildcardMatch(a[1].toString().toLowerCase(), trimmedSearchValue.toLowerCase()),
      );
  };

  const matchAsset = (asset) => {
    if (!assetFilter.includes(getAssetType(asset))) return;

    let propertyMatch = matchProperty(asset);
    if (!propertyMatch && asset.groupedConnectionPoints) {
      propertyMatch = asset.groupedConnectionPoints.some((connection) => matchProperty(connection));
    }
    return propertyMatch;
  };

  const getMatchedSearchAssets = () => {
    let matchedSearchAssets = [];
    allAssets.forEach((a) => {
      if (matchAsset(a.asset)) matchedSearchAssets.push(a.asset);
    });
    return matchedSearchAssets;
  };

  const getGeometries = (matchedSearchAssets) => {
    let matchedGeometries = [];
    matchedSearchAssets.forEach((a) => {
      matchedGeometries.push(a.geometry);
    });
    return matchedGeometries;
  };

  const handleSearch = () => {
    const matchedSearchAssets = getMatchedSearchAssets(network);
    setTotalMatches(matchedSearchAssets.length);

    const matchedSearchIds = matchedSearchAssets.map((m) => m.id);
    const _toolState = toolState;
    _toolState.matchedSearchIds = matchedSearchIds;
    setToolState(_toolState);

    const matchedGeometries = getGeometries(matchedSearchAssets);
    matchedGeometries.length && leafletMap.fitBounds(matchedGeometries);
  };

  const clearSearch = () => {
    const _toolState = toolState;
    _toolState.matchedSearchIds = [];
    setToolState(_toolState);
    setSearchValue("");
    setTotalMatches("");
  };

  return (
    <div className="m-3">
      <ListGroup flush className="mb-3">
        {assetTypes.map((asset) => (
          <ListGroupItem
            key={asset}
            tag="a"
            className="d-flex justify-content-between"
            onClick={() => createAssetFilter(asset)}
          >
            <span>{asset}</span>
            <span className="list-group-item-icon">
              <FontAwesomeIcon icon={assetFilter.includes(asset) ? faCheck : faTimes} />
            </span>
          </ListGroupItem>
        ))}
      </ListGroup>
      <InputGroup className="w-100 mb-2">
        <Input
          id="findAsset"
          name="findAsset"
          placeholder="Find"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          maxLength="50"
        />
        <Button color="primary" disabled={!searchValue} onClick={() => handleSearch()}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </InputGroup>
      <div className="my-2">
        <b>Wildcard Search</b>
        <ul>
          <li>Enter * for a sequence of characters (including empty space)</li>
          <li>Enter ? for a single character</li>
        </ul>
      </div>
      {totalMatches !== "" && <p className="text-right">{totalMatches} matched assets found</p>}
      <Button color="secondary" className="w-100" onClick={() => clearSearch()}>
        <FontAwesomeIcon icon={faUndo} /> Reset
      </Button>
    </div>
  );
};

export default StudySearch;
