import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { forgottenPasswordRequest, loginRequest } from "./authConfig";

/**
 * Houses the handling of the global events
 */
const useMsalEvents = () => {
  const { instance } = useMsal();

  const [error, setError] = useState();

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  useEffect(() => {
    const errorHandlerCallbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error) {
          if (
            message.error.errorMessage &&
            message.error.errorMessage.indexOf("AADB2C90091") > -1
          ) {
            instance.loginRedirect(loginRequest);
            return;
          }
        }
      }

      if (message.error) {
        setError(message.error);
      }
    });

    return () => {
      if (errorHandlerCallbackId) {
        instance.removeEventCallback(errorHandlerCallbackId);
      }
    };
  }, [instance]);

  return {
    error,
  };
};

export default useMsalEvents;
