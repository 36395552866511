import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const EATooltip = ({ text, target, placement }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      placement={placement}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
      delay={1000}
    >
      {text}
    </Tooltip>
  );
};

export default EATooltip;
