// @flow
import { WelderProperties } from "../model/viewModel/welderProperties";

export const getWelderProperties = (
  rating,
  ratingIsDefault,
  ratingUnit,
  numberOfPhases,
  numberOfPhasesIsDefault,
  numberOfBursts,
  numberOfBurstsIsDefault,
  numberOfBurstsUnit,
  pointOfWaveControl,
  powerFactor,
  powerFactorIsDefault,
  efficiency,
  efficiencyIsDefault,
  hvFaultLevel,
  xrRatio,
  annotation,
  voc,
  vocIsDefault,
  geometry,
  styles,
) => {
  let welderProperties: WelderProperties = new WelderProperties();
  welderProperties.rating = rating;
  welderProperties.ratingIsDefault = ratingIsDefault;
  welderProperties.ratingUnit = ratingUnit;
  welderProperties.numberOfPhases = numberOfPhases;
  welderProperties.numberOfPhasesIsDefault = numberOfPhasesIsDefault;
  welderProperties.numberOfBursts = numberOfBursts;
  welderProperties.numberOfBurstsIsDefault = numberOfBurstsIsDefault;
  welderProperties.numberOfBurstsUnit = numberOfBurstsUnit;
  welderProperties.pointOfWaveControl = pointOfWaveControl;
  welderProperties.powerFactor = powerFactor;
  welderProperties.powerFactorIsDefault = powerFactorIsDefault;
  welderProperties.efficiency = efficiency;
  welderProperties.efficiencyIsDefault = efficiencyIsDefault;
  welderProperties.hvFaultLevel = hvFaultLevel;
  welderProperties.xrRatio = xrRatio;
  welderProperties.annotation = annotation;
  welderProperties.voc = voc;
  welderProperties.vocIsDefault = vocIsDefault;
  welderProperties.geometry = geometry;
  welderProperties.styles = styles;
  return welderProperties;
};
