// @flow

interface INameValueProperty {
  name: string;
  value: mixed; // this is "mixed" to work with Object.entries in getObjectPropertiesFrom (see cimFactory).
}

export default class NameValueProperty implements INameValueProperty {
  constructor(name: string, value: mixed) {
    this.name = name;
    this.value = value;
  }

  name: string;
  value: mixed;
}
