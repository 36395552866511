import { useContext } from "react";
import { Button, FormGroup, Input, Label, Row, Col } from "reactstrap";

import { ToolContext } from "../context/ToolContext";

import "../range.css";

const SitePlanForm = ({ sitePlan }) => {
  const { toolState, setToolState } = useContext(ToolContext);
  const { sitePlans, originalSitePlans, leafletMap } = toolState;

  const removeTransformPathLayers = () => {
    leafletMap.eachLayer(function (layer) {
      if (layer.transform && layer.transform._handlersGroup) {
        const transformLayers = Object.values(layer.transform._handlersGroup._layers).map((l) => l);
        transformLayers.forEach((lyr) => {
          leafletMap.removeLayer(lyr);
        });
      }
    });
  };

  const changeOpacity = (e) => {
    const _toolState = { ...toolState };
    const _sitePlans = sitePlans;
    _sitePlans.find((s) => s.id === sitePlan.id).opacity = e.target.value;
    _toolState.sitePlans = _sitePlans;
    _toolState.updateSitePlan += 1;
    setToolState(_toolState);
  };

  const changePosition = (e, width) => {
    const parsedValue = parseFloat(e.target.value);
    if (!parsedValue) return;

    const _toolState = { ...toolState };
    const _sitePlans = sitePlans;
    const _sitePlan = _sitePlans.find((s) => s.id === sitePlan.id);

    const dimension = width ? "width" : "height";
    const otherDimension = width ? "height" : "width";

    if (_sitePlan.uniformScaling) {
      _sitePlan[otherDimension] = _sitePlan[otherDimension] * (parsedValue / _sitePlan[dimension]);
    }
    _sitePlan[dimension] = parsedValue;

    _toolState.sitePlans = _sitePlans;
    _toolState.updateSitePlan += 1;
    _toolState.refreshSitePlan = true;
    setToolState(_toolState);
    removeTransformPathLayers();
  };

  const changeUniformScaling = (e) => {
    const _toolState = { ...toolState };
    const _sitePlans = sitePlans;
    _sitePlans.find((s) => s.id === sitePlan.id).uniformScaling = !sitePlan.uniformScaling;
    _toolState.sitePlans = _sitePlans;
    _toolState.updateSitePlan += 1;
    setToolState(_toolState);
  };

  const changeLockSitePlanPosition = () => {
    const _toolState = { ...toolState };
    const _sitePlans = sitePlans;
    _sitePlans.find((s) => s.id === sitePlan.id).lockSitePlanPosition = !_sitePlans.find(
      (s) => s.id === sitePlan.id,
    ).lockSitePlanPosition;
    _toolState.sitePlans = _sitePlans;
    _toolState.updateSitePlan += 1;
    setToolState(_toolState);
  };

  const resetSitePlan = () => {
    deleteSitePlan(true);
  };

  const deleteSitePlan = (reset) => {
    const _toolState = { ...toolState };
    _toolState.sitePlans = sitePlans.filter((s) => s.id !== sitePlan.id);
    if (!reset) {
      _toolState.originalSitePlans = originalSitePlans.filter((s) => s.id !== sitePlan.id);
    }
    _toolState.updateSitePlan = 0;
    _toolState.selectedSitePlanId = undefined;
    _toolState.resetSitePlan = reset ? _toolState.resetSitePlan + 1 : 0;

    setToolState(_toolState);
    removeTransformPathLayers();
    document.getElementById("importSitePlanPdf").value = "";
    document.getElementById("loadSitePlanPdf").value = "";
  };

  return (
    <div className="p-3">
      <FormGroup>
        <Label for="fileName">File Name</Label>
        <p>
          <b>{sitePlan.name}</b>
        </p>
      </FormGroup>
      <FormGroup>
        <Label for="sitePlanOpacity">Opacity</Label>
        <Input
          type="range"
          name="sitePlanOpacity"
          onChange={changeOpacity}
          min={0.25}
          max={1}
          step={0.05}
          value={sitePlan.opacity}
        />
      </FormGroup>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <Label for="width">Width (m)</Label>
            <Input
              type="number"
              step="1"
              min="1"
              id="width"
              name="width"
              value={parseInt(sitePlan.width)}
              onChange={(e) => changePosition(e, true)}
            />
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label for="height">Height (m)</Label>
            <Input
              type="number"
              step="1"
              min="1"
              id="height"
              name="height"
              value={parseInt(sitePlan.height)}
              onChange={(e) => changePosition(e, false)}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Button
          color="dark"
          block
          onClick={() => changeUniformScaling()}
          className={sitePlan.uniformScaling ? "active" : ""}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span>Uniform Scaling</span>
            <i className={`icon-${sitePlan.uniformScaling ? "check" : "cross"}-solid`}></i>
          </div>
        </Button>
        <Button
          color="dark"
          block
          onClick={() => changeLockSitePlanPosition()}
          className={sitePlan.lockSitePlanPosition ? "active" : ""}
        >
          <div className="d-flex justify-content-between align-items-center">
            <span>Lock Site Plan Position</span>
            <i className={`icon-${sitePlan.lockSitePlanPosition ? "check" : "cross"}-solid`}></i>
          </div>
        </Button>
      </FormGroup>
      <Button color="primary" block onClick={resetSitePlan}>
        Reset
      </Button>
      <Button id="site-plan-delete" color="danger" block onClick={() => deleteSitePlan(false)}>
        Delete
      </Button>
    </div>
  );
};

export default SitePlanForm;
