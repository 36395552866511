import { loginRequest } from "../authConfig";

const useBearerToken = () => {
  const acquireToken = async (instance, accounts) => {
    const token = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    return token.accessToken;
  };

  const getToken = async (instance, accounts) => {
    return accounts && accounts.length ? await acquireToken(instance, accounts) : "";
  };

  return getToken;
};

export default useBearerToken;
