import { useState } from "react";
import { ButtonGroup, Button, Modal, ModalBody } from "reactstrap";

const MultipleTransfomersModal = ({ action, transformerCount }) => {
  const [modal, setModal] = useState(true);

  const toggle = (value) => {
    setModal(!modal);
    action(value);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" keyboard={false}>
        <div className="bg-warning text-light">
          <ModalBody className="d-flex justify-content-between">
            <div>
              {transformerCount < 5
                ? "Add imported network to existing study, or replace?"
                : "Replace Study? No more than 5 networks can be added"}
            </div>
            <ButtonGroup style={{ marginLeft: 10, height: 35 }}>
              {transformerCount < 5 && (
                <Button
                  outline
                  color="light"
                  size="sm"
                  onClick={() => toggle("add")}
                  className="text-nowrap"
                >
                  Add
                </Button>
              )}
              <Button
                outline
                color="light"
                size="sm"
                onClick={() => toggle("replaceAll")}
                className="text-nowrap"
              >
                Replace
              </Button>
              <Button outline color="light" size="sm" onClick={() => toggle()}>
                Cancel
              </Button>
            </ButtonGroup>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default MultipleTransfomersModal;
