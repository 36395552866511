import { Table } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { clearResults } from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import CellEdit from "./CellEdit";
import TableHeader from "./ConfigTableHeader";

const ConfigTab = ({ page, theme, currentConfigIsApplied }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { localOverrideConfig, clientSettings } = formState;
  const [sortColumn, setSortColumn] = useState(-1);
  const [sortColumnAsc, setSortColumnAsc] = useState(true);
  const [optionSearchValue, setOptionSearchValue] = useState({});
  const dispatchRedux = useDispatch();

  const columnDataNames = page.columns.map((c) => c.dataName);
  const keyColumnDataNames = page.keyColumns;
  const overridableColumnDataNames = page.overridableColumns;
  const persistColumnDataNames = [...keyColumnDataNames, ...overridableColumnDataNames];

  const isAutoSelect = (colIndex) => columnDataNames[colIndex] === "autoSelect";
  const isDropdown = (colIndex) =>
    page.optionLists.some((opt) => opt.column === columnDataNames[colIndex]);

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const getDropDownOptions = (colIndex) => {
    const optionsList = page.optionLists.find((opt) => opt.column === columnDataNames[colIndex]);
    if (optionsList) {
      return optionsList.options;
    }
    return [];
  };

  const getRowKey = (row) => {
    const keyValues = keyColumnDataNames.map((k) => row[columnDataNames.indexOf(k)]);
    const key = keyValues.join("-");
    return key;
  };

  const getGroupKey = (row) => {
    const keyValues = page.groupingColumns.map((k) => row[columnDataNames.indexOf(k)]);
    const key = keyValues.join("-");
    return key;
  };

  const getOverrideRowKey = (overrideRow) => {
    const keyValues = keyColumnDataNames.map((k) => overrideRow[k]);
    const key = keyValues.join("-");
    return key;
  };

  const getResolvedValue = (row, colIndex) => {
    const rowKey = getRowKey(row);
    const propertyName = columnDataNames[colIndex];
    let value = row[colIndex];

    if (currentConfigIsApplied && localOverrideConfig?.[page.dataName]?.length) {
      const overrideRow = localOverrideConfig[page.dataName].find(
        (r) => getOverrideRowKey(r) === rowKey,
      );
      if (overrideRow) {
        value = overrideRow[propertyName];
      }
    }
    return value.toString();
  };

  const applyCorrection = (row, colIndex, value) => {
    const rowKey = getRowKey(row);
    const correctionLimit = page.correctionLimits.find((c) => c.editedRow === rowKey);
    if (correctionLimit) {
      const limitRow = page.rows.find((r) => getRowKey(r) === correctionLimit.limitRow);
      const descriptionColumnIndex = columnDataNames.indexOf(correctionLimit.descriptionColumn);
      const limitDescription = limitRow[descriptionColumnIndex];
      const limitValue = getResolvedValue(limitRow, colIndex);
      if (correctionLimit.raiseToLimit) {
        if (Number(value) < Number(limitValue)) {
          return {
            newValue: limitValue,
            warningMessage: `The value cannot be less than ${limitDescription}. The value has been corrected.`,
          };
        }
      } else {
        if (Number(value) > Number(limitValue)) {
          return {
            newValue: limitValue,
            warningMessage: `The value cannot be more than ${limitDescription}. The value has been corrected.`,
          };
        }
      }
    }
    return { newValue: value, warningMessage: "" };
  };

  const canOverrideCell = (colIndex) => {
    if (!clientSettings.features.LocalOverrideConfigEnabled) {
      return false;
    }
    if (!currentConfigIsApplied) {
      return false;
    }
    const colDataname = columnDataNames[colIndex];
    if (!overridableColumnDataNames.includes(colDataname)) {
      return false;
    }
    return true;
  };

  const canEditCell = (row, colIndex) => {
    if (page.overridableRows.length) {
      if (!page.overridableRows.includes(getRowKey(row))) {
        return false;
      }
    }
    if (isAutoSelect(colIndex)) {
      // Prevent the last Auto-Select item in a group to be turned off:
      if (getResolvedValue(row, colIndex) === "true") {
        const groupKey = getGroupKey(row);
        const allRowsInGroup = page.rows.filter((r) => getGroupKey(r) === groupKey);
        const noOfAutoSelects = allRowsInGroup.filter(
          (r) => getResolvedValue(r, colIndex) === "true",
        ).length;
        if (noOfAutoSelects <= 1) {
          return false;
        }
      }
    }

    return true;
  };

  const toOverrideRow = (tableRow) => {
    const overrideRow = {};
    columnDataNames.forEach((name, colIndex) => {
      if (persistColumnDataNames.includes(name)) {
        overrideRow[name] = tableRow[colIndex];
      }
    });
    return overrideRow;
  };

  useEffect(() => {
    setSortColumn(-1);
    setSortColumnAsc(true);
    setOptionSearchValue({});
  }, [page.title]);

  const sort = (columnName) => {
    if (sortColumn === columnName) {
      setSortColumnAsc(!sortColumnAsc);
    } else {
      setSortColumn(columnName);
      setSortColumnAsc(true);
    }
  };

  const isExactMatch = (row, overrideRow) => {
    return persistColumnDataNames.every(
      (propertyName) =>
        row[columnDataNames.indexOf(propertyName)].toString() ===
        overrideRow?.[propertyName]?.toString(),
    );
  };

  const orderedRows = [...page.rows];
  if (sortColumn !== -1) {
    orderedRows.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      if (!sortColumnAsc) {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
  }

  const filteredRows = orderedRows.filter((row) => {
    return row.every((q, colIndex) => {
      const search = optionSearchValue[colIndex];
      if (search === undefined || search.trim() === "") {
        return true;
      }

      const cellValue = getResolvedValue(row, colIndex);

      const cellText = isDropdown(colIndex)
        ? getDropDownOptions(colIndex).find((opt) => opt.value === cellValue)?.label
        : cellValue;

      if (cellText?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
  });

  const updateDependantColumns = (row, overrideRow, colIndex, value) => {
    const columnDataName = columnDataNames[colIndex];
    const dependantColumns = page.dependantColumns.filter(
      (d) => d.sourceColumn === columnDataName && d.sourceValue === value,
    );
    dependantColumns.forEach((d) => {
      const defaultColumnIndex = columnDataNames.indexOf(d.defaultColumn);
      const defaultValue = row[defaultColumnIndex];
      overrideRow[d.targetColumn] = defaultValue;
    });
  };

  const handleSaveRow = (row, colIndex, value) => {
    let _localOverrideConfig = { ...localOverrideConfig };
    if (!_localOverrideConfig[page.dataName]) {
      _localOverrideConfig[page.dataName] = [];
    }
    const rowKey = getRowKey(row);
    let overrideRowIndex = _localOverrideConfig[page.dataName].findIndex(
      (r) => getOverrideRowKey(r) === rowKey,
    );
    const overrideRow =
      overrideRowIndex >= 0
        ? _localOverrideConfig[page.dataName][overrideRowIndex]
        : toOverrideRow(row);

    const { newValue, warningMessage } = applyCorrection(row, colIndex, value);
    value = newValue;

    const propertyName = columnDataNames[colIndex];
    overrideRow[propertyName] = isAutoSelect(colIndex)
      ? value.toString() === "true"
      : isDropdown(colIndex)
        ? value.toString()
        : Number(value);

    updateDependantColumns(row, overrideRow, colIndex, value);

    if (isExactMatch(row, overrideRow)) {
      if (overrideRowIndex >= 0) {
        _localOverrideConfig[page.dataName].splice(overrideRowIndex, 1);
        if (!_localOverrideConfig[page.dataName].length) {
          delete _localOverrideConfig[page.dataName];
        }
      }
    } else {
      if (overrideRowIndex < 0) {
        _localOverrideConfig[page.dataName].push(overrideRow);
      }
    }

    hideResults();

    dispatch({
      form: "localOverrideConfig",
      obj: _localOverrideConfig,
      type: "REPLACE_STATE",
    });
    return warningMessage;
  };

  const validate = (colIndex, value) => {
    if (isDropdown(colIndex)) {
      return true;
    }
    if (value.match(/^\d[\d\.]*$/)) {
      return true;
    }
    return false;
  };

  const handleRevertToDefault = (row, colIndex) => handleSaveRow(row, colIndex, row[colIndex]);

  const getIsModified = (row, colIndex) => {
    const rowKey = getRowKey(row);
    const propertyName = columnDataNames[colIndex];
    if (localOverrideConfig?.[page.dataName]?.length) {
      const overrideRow = localOverrideConfig[page.dataName].find(
        (r) => getOverrideRowKey(r) === rowKey,
      );
      if (overrideRow) {
        const modifiedValue = overrideRow[propertyName];
        return modifiedValue.toString() !== row[colIndex].toString();
      }
    }
    return false;
  };

  const getStyle = (row, colIndex) => {
    if (isAutoSelect(colIndex)) {
      if (getResolvedValue(row, colIndex) === "true") {
        return { color: "green" };
      }
    }
    return {};
  };

  return (
    <>
      <h5 className="mb-4">{page.title}</h5>
      <Table className={`table-sm table-custom table-custom-${theme} table-sortable`}>
        <thead>
          <tr className="mb-4">
            {page.columns
              .filter((c) => !c.hidden)
              .map((column) => (
                <TableHeader
                  key={column.displayName}
                  column={column}
                  page={page}
                  sort={sort}
                  sortColumn={sortColumn}
                  sortColumnAsc={sortColumnAsc}
                  optionSearchValue={optionSearchValue}
                  setOptionSearchValue={setOptionSearchValue}
                />
              ))}
          </tr>
        </thead>
        <tbody>
          {filteredRows.map((row) => (
            <tr>
              {row.map(
                (cell, colIndex) =>
                  !page.columns[colIndex].hidden && (
                    <td>
                      {canOverrideCell(colIndex) ? (
                        <CellEdit
                          key={`${getRowKey(row)}-${colIndex}`}
                          isDropdown={isDropdown(colIndex)}
                          isModified={getIsModified(row, colIndex)}
                          canEditCell={canEditCell(row, colIndex)}
                          value={getResolvedValue(row, colIndex)}
                          dropDownOptions={getDropDownOptions(colIndex)}
                          viewStyle={getStyle(row, colIndex)}
                          handleRevertToDefault={() => handleRevertToDefault(row, colIndex)}
                          handleValidate={(value) => validate(colIndex, value)}
                          handleSave={(value) => handleSaveRow(row, colIndex, value)}
                        />
                      ) : (
                        <>
                          <span style={getStyle(row, colIndex)}>
                            {getDropDownOptions(colIndex)?.find(
                              (opt) => opt.value === cell.toString(),
                            )?.label ?? cell.toString()}
                          </span>
                        </>
                      )}
                    </td>
                  ),
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ConfigTab;
