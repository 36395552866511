// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import B2XApp from "./B2XApp";
import App from "./App";
import "./scss/custom.scss";
import "./style.css";
import * as serviceWorker from "./serviceWorker";
const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const { REACT_APP_B2B, REACT_APP_B2C } = process.env;
const b2x = REACT_APP_B2B === "true" || REACT_APP_B2C === "true";

const root = ReactDOM.createRoot(document.getElementById("root") || document.createElement("div"));
root.render(<React.StrictMode>{b2x ? <B2XApp pca={msalInstance} /> : <App />}</React.StrictMode>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
