import styled from "styled-components";
import * as color from "../../constants/colors";

const colorMap = {
  red: color.RED,
  danger: color.DANGER,
  primary: color.PRIMARY,
  amber: color.AMBER,
  warning: color.WARNING,
  green: color.GREEN,
  success: color.SUCCESS,
  default: color.DEFAULT,
  "three-phase": color.THREE_PHASE,
  dark: color.DARK,
  colourblindRed: color.COLOURBLIND_RED,
  colourblindAmber: color.COLOURBLIND_AMBER,
  colourblindGreen: color.COLOURBLIND_GREEN,
};

export const IconLabel = styled.span`
  position: relative;

  &::after {
    ${({ name }) => (name ? "content: attr(data-name);" : "")}
    background-color: ${({ bg }) => colorMap[bg] || bg};

    display: inline-block;
    font-size: 0.875rem;
    padding: 0.125rem 0.25rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    top: 18px;
    left: 32px;
    position: absolute;
  }
`;
