import { updateGroupedConnection } from "../../app/networkSlice";
import { AppDispatch } from "../../app/store";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";

export const applyPoleChange = (
  ringfencedFiltered: RingfencedElement[],
  poleStatus: string,
  dispatchRedux: AppDispatch,
): void => {
  ringfencedFiltered.forEach((p) => {
    if (p.pole && p.pole.status !== poleStatus) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "pole",
          value: { ...p.pole, status: poleStatus },
        }),
      );
    }
  });
};

export const applyPotEndChange = (
  ringfencedFiltered: RingfencedElement[],
  potEndStatus: string,
  dispatchRedux: AppDispatch,
): void => {
  ringfencedFiltered.forEach((p) => {
    if (p.potEnds && p.potEnds.some((q: any) => q.status !== potEndStatus)) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "potEnds",
          value: p.potEnds.map((q: any) => ({ ...q, status: potEndStatus })),
        }),
      );
    }
  });
};

export const applyLinkBoxChange = (
  ringfencedFiltered: RingfencedElement[],
  linkBoxStatus: string,
  dispatchRedux: AppDispatch,
): void => {
  ringfencedFiltered.forEach((p) => {
    if (p.linkBox && p.linkBox.status !== linkBoxStatus) {
      dispatchRedux(
        updateGroupedConnection({
          id: p.id,
          name: "linkBox",
          value: { ...p.linkBox, status: linkBoxStatus },
        }),
      );
    }
  });
};
