// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { Welder } from "./welder";
import { WelderProperties } from "./welderProperties";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import { convertAndFormat } from "../../utils/numberFunctions";

export function createWelderFromConductingEquipment(
  conductingEquipment: ConductingEquipment,
  substationId: any,
): Welder {
  const welderProperties = new WelderProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  welderProperties.geometry = getLatLngFromWktPoint(geometryString);
  welderProperties.rating = conductingEquipment.getPropertyValue("rating");
  welderProperties.ratingIsDefault =
    conductingEquipment.getPropertyValue("ratingIsDefault") === "true";
  welderProperties.ratingUnit = conductingEquipment.getPropertyValue("ratingUnit");
  welderProperties.numberOfPhases = conductingEquipment.getPropertyValue("numberOfPhases");
  welderProperties.numberOfPhasesIsDefault =
    conductingEquipment.getPropertyValue("numberOfPhasesIsDefault") === "true";
  welderProperties.numberOfBursts = Number(conductingEquipment.getPropertyValue("numberOfBursts"));
  welderProperties.numberOfBurstsIsDefault =
    conductingEquipment.getPropertyValue("numberOfBurstsIsDefault") === "true";
  welderProperties.numberOfBurstsUnit = conductingEquipment.getPropertyValue("numberOfBurstsUnit");

  welderProperties.pointOfWaveControl =
    conductingEquipment.getPropertyValue("pointOfWaveControl") === "true";

  welderProperties.powerFactor = Number(conductingEquipment.getPropertyValue("powerFactor"));
  welderProperties.powerFactorIsDefault =
    conductingEquipment.getPropertyValue("powerFactorIsDefault") === "true";
  welderProperties.efficiency = Number(conductingEquipment.getPropertyValue("efficiency"));
  welderProperties.efficiencyIsDefault =
    conductingEquipment.getPropertyValue("efficiencyIsDefault") === "true";
  welderProperties.hvFaultLevel = Number(conductingEquipment.getPropertyValue("hvFaultLevel"));

  welderProperties.xrRatio = Number(conductingEquipment.getPropertyValue("xrRatio"));
  welderProperties.annotation = conductingEquipment.getPropertyValue("annotation");
  welderProperties.voc = Number(conductingEquipment.getPropertyValue("voc"));
  welderProperties.vocIsDefault = conductingEquipment.getPropertyValue("voc") === "true";

  welderProperties.styles = getAssetStyleInfo(conductingEquipment.getPropertyValue("class"));

  welderProperties.maxVoltDropPercent = conductingEquipment.getPropertyValue("maxVoltDropPercent");
  welderProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") === "true";
  welderProperties.maxVoltRisePercent = conductingEquipment.getPropertyValue("maxVoltRisePercent");
  welderProperties.maxVoltRiseTotal = conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  welderProperties.maxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("maxVoltRiseGeneration");
  welderProperties.maxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("maxVoltRiseUnbalance");
  welderProperties.earthLoopImpedance =
    conductingEquipment.getPropertyValue("earthLoopImpedance") == null
      ? null
      : Number(conductingEquipment.getPropertyValue("earthLoopImpedance"));

  welderProperties.threePhaseFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("threePhaseFaultCurrent"),
  );

  welderProperties.phaseToPhaseFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("phaseToPhaseFaultCurrent"),
  );

  welderProperties.singlePhaseToEarthFaultCurrent = convertAndFormat(
    conductingEquipment.getPropertyValue("singlePhaseToEarthFaultCurrent"),
  );

  welderProperties.flickerPassResult =
    conductingEquipment.getPropertyValue("flickerPassResult") === "true";

  welderProperties.flickerDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerDeltaVoltage"),
  );

  welderProperties.flickerP28Limit = Number(
    conductingEquipment.getPropertyValue("flickerP28Limit"),
  );

  welderProperties.flickerStartingPassResult =
    conductingEquipment.getPropertyValue("flickerStartingPassResult") === "true";

  welderProperties.flickerStartingDeltaVoltage = Number(
    conductingEquipment.getPropertyValue("flickerStartingDeltaVoltage"),
  );

  welderProperties.impedanceLoopOperating =
    conductingEquipment.getPropertyValue("impedanceLoopOperating");

  welderProperties.impedanceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceLoopOperatingPassResult") === "true";

  welderProperties.impedanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "impedanceSourceLoopOperating",
  );

  welderProperties.impedanceSourceLoopOperatingPassResult =
    conductingEquipment.getPropertyValue("impedanceSourceLoopOperatingPassResult") === "true";

  welderProperties.impedanceLoopFault = conductingEquipment.getPropertyValue("impedanceLoopFault");
  welderProperties.impedancePhaseOperating =
    conductingEquipment.getPropertyValue("impedancePhaseOperating");
  welderProperties.impedanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "impedanceSourcePhaseOperating",
  );
  welderProperties.impedanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "impedanceSourcePhasePhaseOperating",
  );
  welderProperties.impedancePhaseFault =
    conductingEquipment.getPropertyValue("impedancePhaseFault");
  welderProperties.resistanceLoopOperating =
    conductingEquipment.getPropertyValue("resistanceLoopOperating");
  welderProperties.resistanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "resistanceSourceLoopOperating",
  );
  welderProperties.resistanceLoopFault =
    conductingEquipment.getPropertyValue("resistanceLoopFault");
  welderProperties.resistancePhaseOperating = conductingEquipment.getPropertyValue(
    "resistancePhaseOperating",
  );
  welderProperties.resistanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "resistanceSourcePhaseOperating",
  );
  welderProperties.resistanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "resistanceSourcePhasePhaseOperating",
  );
  welderProperties.resistancePhaseFault =
    conductingEquipment.getPropertyValue("resistancePhaseFault");
  welderProperties.reactanceSourceLoopOperating = conductingEquipment.getPropertyValue(
    "reactanceSourceLoopOperating",
  );
  welderProperties.reactanceLoopFault = conductingEquipment.getPropertyValue("reactanceLoopFault");
  welderProperties.reactancePhaseOperating =
    conductingEquipment.getPropertyValue("reactancePhaseOperating");
  welderProperties.reactanceSourcePhaseOperating = conductingEquipment.getPropertyValue(
    "reactanceSourcePhaseOperating",
  );
  welderProperties.reactanceSourcePhasePhaseOperating = conductingEquipment.getPropertyValue(
    "reactanceSourcePhasePhaseOperating",
  );
  welderProperties.reactancePhaseFault =
    conductingEquipment.getPropertyValue("reactancePhaseFault");

  welderProperties.AllmaxVoltDropPercent =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercent");
  welderProperties.AllmaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPercentPeriod",
  );
  welderProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") === "true";
  welderProperties.AllmaxVoltDropPhase =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPhase");
  welderProperties.AllmaxVoltRisePercent =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercent");
  welderProperties.AllmaxVoltRisePercent =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePercent");
  welderProperties.AllmaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseTotal");
  welderProperties.AllmaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "AllmaxVoltRiseGeneration",
  );
  welderProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  welderProperties.AllmaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePercentPeriod",
  );
  welderProperties.AllmaxVoltRisePhase =
    conductingEquipment.getPropertyValue("AllmaxVoltRisePhase");
  welderProperties.AllmaxVoltage = conductingEquipment.getPropertyValue("AllmaxVoltage");
  welderProperties.AllmaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase1");
  welderProperties.AllmaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase2");
  welderProperties.AllmaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllmaxVoltagePhase3");
  welderProperties.AllminVoltage = conductingEquipment.getPropertyValue("AllminVoltage");
  welderProperties.AllminVoltagePhase1 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase1");
  welderProperties.AllminVoltagePhase2 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase2");
  welderProperties.AllminVoltagePhase3 =
    conductingEquipment.getPropertyValue("AllminVoltagePhase3");
  welderProperties.wintermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercent",
  );
  welderProperties.wintermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercentPeriod",
  );
  welderProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPassResult") === "true";
  welderProperties.wintermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPhase");
  welderProperties.wintermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercent",
  );
  welderProperties.wintermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseTotal");
  welderProperties.wintermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseGeneration",
  );
  welderProperties.wintermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseUnbalance",
  );
  welderProperties.wintermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercentPeriod",
  );
  welderProperties.wintermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("wintermaxVoltRisePhase");
  welderProperties.wintermaxVoltage = conductingEquipment.getPropertyValue("wintermaxVoltage");
  welderProperties.wintermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase1");
  welderProperties.wintermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase2");
  welderProperties.wintermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("wintermaxVoltagePhase3");
  welderProperties.winterminVoltage = conductingEquipment.getPropertyValue("winterminVoltage");
  welderProperties.winterminVoltagePhase1 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase1");
  welderProperties.winterminVoltagePhase2 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase2");
  welderProperties.winterminVoltagePhase3 =
    conductingEquipment.getPropertyValue("winterminVoltagePhase3");
  welderProperties.summermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercent",
  );
  welderProperties.summermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercentPeriod",
  );
  welderProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPassResult") === "true";
  welderProperties.summermaxVoltDropPhase =
    conductingEquipment.getPropertyValue("summermaxVoltDropPhase");
  welderProperties.summermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercent",
  );
  welderProperties.summermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("summermaxVoltRiseTotal");
  welderProperties.summermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseGeneration",
  );
  welderProperties.summermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseUnbalance",
  );
  welderProperties.summermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercentPeriod",
  );
  welderProperties.summermaxVoltRisePhase =
    conductingEquipment.getPropertyValue("summermaxVoltRisePhase");
  welderProperties.summermaxVoltage = conductingEquipment.getPropertyValue("summermaxVoltage");
  welderProperties.summermaxVoltagePhase1 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase1");
  welderProperties.summermaxVoltagePhase2 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase2");
  welderProperties.summermaxVoltagePhase3 =
    conductingEquipment.getPropertyValue("summermaxVoltagePhase3");
  welderProperties.summerminVoltage = conductingEquipment.getPropertyValue("summerminVoltage");
  welderProperties.summerminVoltagePhase1 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase1");
  welderProperties.summerminVoltagePhase2 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase2");
  welderProperties.summerminVoltagePhase3 =
    conductingEquipment.getPropertyValue("summerminVoltagePhase3");
  welderProperties.phaseImpedance = conductingEquipment.getPropertyValue("phaseImpedance");
  welderProperties.loopImpedance = conductingEquipment.getPropertyValue("loopImpedance");
  welderProperties.nodeNumber = conductingEquipment.getPropertyValue("nodeNumber");

  if (parseInt(conductingEquipment.getPropertyValue("phase1AutoConsumers") ?? 0) > 0) {
    welderProperties.phaseAllocation = "L1 + ";
  } else {
    welderProperties.phaseAllocation = "";
  }

  if (parseInt(conductingEquipment.getPropertyValue("phase2AutoConsumers") ?? 0) > 0) {
    welderProperties.phaseAllocation = welderProperties.phaseAllocation + "L2 + ";
  }

  if (parseInt(conductingEquipment.getPropertyValue("phase3AutoConsumers") ?? 0) > 0) {
    welderProperties.phaseAllocation = welderProperties.phaseAllocation + "L3 + ";
  }

  if (welderProperties.phaseAllocation)
    welderProperties.phaseAllocation = welderProperties.phaseAllocation
      .trimRight()
      .replace(/\+\s*$/, "")
      .trimRight();

  welderProperties.rootTransformerId = conductingEquipment.getPropertyValue("rootTransformerId");
  welderProperties.substationId =
    conductingEquipment.getPropertyValue("substationId") ?? substationId;

  return new Welder(conductingEquipment.id, welderProperties);
}
