// @flow

export default class PropertyContainer {
  constructor(properties: NameValueProperty[]) {
    this.properties = properties;
  }

  properties: NameValueProperty[];

  getPropertyValue(propertyName: string): mixed {
    const property = this.properties.find((property) => property.name === propertyName);

    return property ? property.value : null;
  }

  getPropertyByName(propertyName: string): NameValueProperty {
    return this.properties.find((property) => property.name === propertyName);
  }
}
