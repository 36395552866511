// @flow
import { LatLng } from "./simpleTypes";

export interface IWelderProperties {
  rating: number;
  ratingIsDefault: Boolean;
  ratingUnit: string;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfBursts: number;
  numberOfBurstsIsDefault: Boolean;
  numberOfBurstsUnit: string;
  pointOfWaveControl: Boolean;
  powerFactor: number;
  powerFactorIsDefault: Boolean;
  efficiency: number;
  efficiencyIsDefault: Boolean;
  hvFaultLevel: number;
  xrRatio: number;
  annotation: string;
  voc: number;
  vocIsDefault: Boolean;
  geometry: LatLng;
  styles: any;
  maxVoltDropPercent: ?number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRisePercent: ?number;
  maxVoltRiseTotal: ?number;
  maxVoltRiseGeneration: ?number;
  maxVoltRiseUnbalance: ?number;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: ?number;
  AllmaxVoltDropPercentPeriod: ?number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: ?number;
  AllmaxVoltRisePercent: ?number;
  AllmaxVoltRiseTotal: ?number;
  AllmaxVoltRiseGeneration: ?number;
  AllmaxVoltRiseUnbalance: ?number;
  AllmaxVoltRisePercentPeriod: ?number;
  AllmaxVoltRisePhase: ?number;
  AllmaxVoltage: ?number;
  AllmaxVoltagePhase1: ?number;
  AllmaxVoltagePhase2: ?number;
  AllmaxVoltagePhase3: ?number;
  AllminVoltage: ?number;
  AllminVoltagePhase1: ?number;
  AllminVoltagePhase2: ?number;
  AllminVoltagePhase3: ?number;
  wintermaxVoltDropPercent: ?number;
  wintermaxVoltDropPercentPeriod: ?number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: ?number;
  wintermaxVoltRisePercent: ?number;
  wintermaxVoltRiseTotal: ?number;
  wintermaxVoltRiseGeneration: ?number;
  wintermaxVoltRiseUnbalance: ?number;
  wintermaxVoltRisePercentPeriod: ?number;
  wintermaxVoltRisePhase: ?number;
  wintermaxVoltage: ?number;
  wintermaxVoltagePhase1: ?number;
  wintermaxVoltagePhase2: ?number;
  wintermaxVoltagePhase3: ?number;
  winterminVoltage: ?number;
  winterminVoltagePhase1: ?number;
  winterminVoltagePhase2: ?number;
  winterminVoltagePhase3: ?number;
  summermaxVoltDropPercent: ?number;
  summermaxVoltDropPercentPeriod: ?number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: ?number;
  summermaxVoltRisePercent: ?number;
  summermaxVoltRiseTotal: ?number;
  summermaxVoltRiseGeneration: ?number;
  summermaxVoltRiseUnbalance: ?number;
  summermaxVoltRisePercentPeriod: ?number;
  summermaxVoltRisePhase: ?number;
  summermaxVoltage: ?number;
  summermaxVoltagePhase1: ?number;
  summermaxVoltagePhase2: ?number;
  summermaxVoltagePhase3: ?number;
  summerminVoltage: ?number;
  summerminVoltagePhase1: ?number;
  summerminVoltagePhase2: ?number;
  summerminVoltagePhase3: ?number;
  phaseImpedance: ?number;
  loopImpedance: ?number;
  phaseAllocation: string;
  nodeNumber: number;
  rootTransformerId: string;
}

export class WelderProperties implements IWelderProperties {
  rating: number;
  ratingIsDefault: Boolean;
  ratingUnit: string;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  numberOfBursts: number;
  numberOfBurstsIsDefault: Boolean;
  numberOfBurstsUnit: string;
  pointOfWaveControl: Boolean;
  powerFactor: number;
  powerFactorIsDefault: Boolean;
  efficiency: number;
  efficiencyIsDefault: Boolean;
  hvFaultLevel: number;
  xrRatio: number;
  annotation: string;
  voc: number;
  vocIsDefault: Boolean;
  geometry: LatLng;
  styles: any;
  maxVoltDropPercent: ?number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRisePercent: ?number;
  maxVoltRiseTotal: ?number;
  maxVoltRiseGeneration: ?number;
  maxVoltRiseUnbalance: ?number;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: ?number;
  AllmaxVoltDropPercentPeriod: ?number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: ?number;
  AllmaxVoltRisePercent: ?number;
  AllmaxVoltRiseTotal: ?number;
  AllmaxVoltRiseGeneration: ?number;
  AllmaxVoltRiseUnbalance: ?number;
  AllmaxVoltRisePercentPeriod: ?number;
  AllmaxVoltRisePhase: ?number;
  AllmaxVoltage: ?number;
  AllmaxVoltagePhase1: ?number;
  AllmaxVoltagePhase2: ?number;
  AllmaxVoltagePhase3: ?number;
  AllminVoltage: ?number;
  AllminVoltagePhase1: ?number;
  AllminVoltagePhase2: ?number;
  AllminVoltagePhase3: ?number;
  wintermaxVoltDropPercent: ?number;
  wintermaxVoltDropPercentPeriod: ?number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: ?number;
  wintermaxVoltRisePercent: ?number;
  wintermaxVoltRiseTotal: ?number;
  wintermaxVoltRiseGeneration: ?number;
  wintermaxVoltRiseUnbalance: ?number;
  wintermaxVoltRisePercentPeriod: ?number;
  wintermaxVoltRisePhase: ?number;
  wintermaxVoltage: ?number;
  wintermaxVoltagePhase1: ?number;
  wintermaxVoltagePhase2: ?number;
  wintermaxVoltagePhase3: ?number;
  winterminVoltage: ?number;
  winterminVoltagePhase1: ?number;
  winterminVoltagePhase2: ?number;
  winterminVoltagePhase3: ?number;
  summermaxVoltDropPercent: ?number;
  summermaxVoltDropPercentPeriod: ?number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: ?number;
  summermaxVoltRisePercent: ?number;
  summermaxVoltRiseTotal: ?number;
  summermaxVoltRiseGeneration: ?number;
  summermaxVoltRiseUnbalance: ?number;
  summermaxVoltRisePercentPeriod: ?number;
  summermaxVoltRisePhase: ?number;
  summermaxVoltage: ?number;
  summermaxVoltagePhase1: ?number;
  summermaxVoltagePhase2: ?number;
  summermaxVoltagePhase3: ?number;
  summerminVoltage: ?number;
  summerminVoltagePhase1: ?number;
  summerminVoltagePhase2: ?number;
  summerminVoltagePhase3: ?number;
  phaseImpedance: ?number;
  loopImpedance: ?number;
  phaseAllocation: string;
  nodeNumber: number;
  objectId: string;
  rootTransformerId: string;
  substationId: string;
}
