import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { FormGroup, Input, Label, Button } from "reactstrap";

import { ConnectionPoint } from "../model/viewModel/connectionPoint";
import { GroupedConnectionPoint } from "../model/viewModel/groupedConnectionPoint";
import { ToolContext } from "../context/ToolContext";
import { FormContext } from "../context/FormContext";
import { consumerDefaults } from "../utils/consumerDefaults";
import { getConnectionPointProperties } from "../states/connectionPoint";
import ConsumerSelector from "./ConsumerSelector";
import LinkBoxSelector from "./LinkBoxSelector";
import MultiOccupancySelector from "./MultiOccupancySelector";
import NestedConsumerForm from "./NestedConsumerForm";
import PotEndSelector from "./PotEndSelector";
import NodeFuseSelector from "./NodeFuseSelector";
import PoleSelector from "./PoleSelector";
import { getFormName } from "../utils/formUtil";
import { nodeIsConnectedToOverheadLine } from "../utils/networkFunctions";
import { getCutOutSizeDefaultValue } from "../utils/consumerDefaults";
import { getRingfencedNetworkAssets, getRingfencedTypes } from "./RingfenceTool";
import * as statusConstants from "../constants/status";
import * as poleProperties from "../constants/poleProperties";
import {
  addCableChild,
  addCableSubChild,
  addGroupedConnectionChild,
  addGroupedConnectionSubChild,
  addTransformerChild,
  addTransformerSubChild,
  clearResults,
  getAllGeometryAssets,
  removeCableChild,
  removeGroupedConnectionChild,
  removeTransformerChild,
  updateCable,
  updateCableChild,
  updateConnectionPoint,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateMotor,
  updateNode,
  updatePointOfConnection,
  updateTransformer,
  updateTransformerChild,
  updateWelder,
} from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { CONSUMER_LIST } from "../constants/consumers";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";
import DynamicPropertiesList from "../components/DynamicPropertiesList";

const ConnectionForm = ({ selectedType, Item }) => {
  const { formState, dispatch } = useContext(FormContext);
  const { toolState, setToolState } = useContext(ToolContext);
  const { activeTool, clickedAsset, mode, ringfencedFiltered } = toolState;
  const [annotation, setAnnotation] = useState("");
  const { type } = toolState.activeTool;
  const {
    reference,
    localOverrideConfig,
    cableProperties,
    transformerProperties,
    groupedConnectionProperties,
    clientSettings,
  } = formState;

  const { poleHeightIsDefault, stayAngleIsDefault, poleTermLengthIsDefault, fuseIsDefault } =
    groupedConnectionProperties;

  useEffect(() => {
    if (!clickedAsset) return;
    setAnnotation(clickedAsset.annotation || "");
  }, [clickedAsset]);

  const dispatchRedux = useDispatch();

  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const cables = useSelector((state) => state.network.present.cables);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [allGeometryAssets]);

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    _toolState.errors = {};

    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const addItem = (parentConsumer, item, count) => {
    hideResults();
    let _consumer = {
      ...CONSUMER_LIST.find((y) => y.class === item),
    };

    const consumer = consumerDefaults(reference.consumers, _consumer.class, localOverrideConfig);

    let groupedConnectionPoint;

    //if parentid exists, then add connectionPoint with parentid and isSubGroupConnection=true

    const id = uuid();

    if (parentConsumer) {
      groupedConnectionPoint = new ConnectionPoint(
        id,
        getConnectionPointProperties(
          consumer["consumerType"],
          false,
          true,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          consumer["consumptionValue1"],
          false,
          consumer["consumptionValue2"],
          false,
          consumer["consumptionType"],
          false,
          activeTool &&
            (activeTool.type === "groupedConnection" || activeTool.type === "connectionPoint") &&
            clickedAsset
            ? clickedAsset.geometry
            : [],
          "Single",
          false,
          [],
          _consumer,
          parentConsumer ? parentConsumer.id : null,
          true,
          _consumer.name === "Generator",
          null,
          "",
          statusConstants.NEW,
        ),
      );
    } else {
      groupedConnectionPoint = new GroupedConnectionPoint(
        id,
        getConnectionPointProperties(
          consumer["consumerType"],
          false,
          true,
          count,
          0,
          0,
          0,
          false,
          0,
          null,
          consumer["consumptionValue1"],
          false,
          consumer["consumptionValue2"],
          false,
          consumer["consumptionType"],
          false,
          activeTool &&
            (activeTool.type === "groupedConnection" || activeTool.type === "connectionPoint") &&
            clickedAsset
            ? clickedAsset.geometry
            : [],
          "Single",
          false,
          [count, 0, 0, 0],
          _consumer,
          null,
          false,
          _consumer.name === "Generator",
          null,
          "",
          statusConstants.NEW,
          getCutOutSizeDefaultValue(_consumer.class),
        ),
        [],
        count,
      );
    }

    if (clickedAsset && clickedAsset.styles.type === type) {
      if (getFormName(type, selectedType) === "transformer") {
        if (parentConsumer) {
          dispatchRedux(
            addTransformerSubChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
              groupedConnectionPointId: parentConsumer.id,
              isGroupUpdate: false,
            }),
          );
        } else {
          dispatchRedux(
            addTransformerChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
            }),
          );
        }
      } else if (getFormName(type, selectedType) === "groupedConnection") {
        if (parentConsumer) {
          dispatchRedux(
            addGroupedConnectionSubChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
              groupedConnectionPointId: parentConsumer.id,
              isGroupUpdate: false,
            }),
          );
        } else {
          dispatchRedux(
            addGroupedConnectionChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
            }),
          );
          dispatchRedux(
            updateGroupedConnection({ id: clickedAsset.id, name: "status", value: "new" }),
          );
        }
      } else if (getFormName(type, selectedType) === "cable") {
        if (parentConsumer) {
          dispatchRedux(
            addCableSubChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
              groupedConnectionPointId: parentConsumer.id,
              isGroupUpdate: false,
            }),
          );
        } else {
          dispatchRedux(
            addCableChild({
              id: clickedAsset.id,
              child: groupedConnectionPoint,
            }),
          );
        }
      } else {
        console.error(
          `Unexpected type found.  Current type: ${getFormName(
            type,
            selectedType,
          )}.  Expected: transformer, groupedConnection, cable`,
        );
      }
    }

    dispatch({
      form: `${getFormName(type, selectedType)}Properties`,
      obj: groupedConnectionPoint,
      id: parentConsumer ? parentConsumer.id : null,
      type: "ADD_ITEM",
    });

    if (mode === "select-group") {
      ringfencedFiltered.forEach((i) => {
        let groupedConnectionPoint;

        //if parentid exists, then add connectionPoint with parentid and isSubGroupConnection=true

        if (parentConsumer) {
          groupedConnectionPoint = new ConnectionPoint(
            uuid(),
            getConnectionPointProperties(
              consumer["consumerType"],
              false,
              true,
              parentConsumer["phaseAutoConsumers"],
              parentConsumer["phase1Consumers"],
              parentConsumer["phase2Consumers"],
              parentConsumer["phase3Consumers"],
              parentConsumer["balancedLoad"],
              parentConsumer["unbalancePercent"],
              parentConsumer["lowLoadedPhase"],
              consumer["consumptionValue1"],
              false,
              consumer["consumptionValue2"],
              false,
              consumer["consumptionType"],
              false,
              i.geometry,
              "Single",
              false,
              parentConsumer["phaseMap"],
              _consumer,
              parentConsumer ? parentConsumer.id : null,
              true,
              _consumer.name === "Generator",
              id,
            ),
          );
        } else {
          groupedConnectionPoint = new GroupedConnectionPoint(
            uuid(),
            getConnectionPointProperties(
              consumer["consumerType"],
              false,
              true,
              count,
              0,
              0,
              0,
              false,
              0,
              null,
              consumer["consumptionValue1"],
              false,
              consumer["consumptionValue2"],
              false,
              consumer["consumptionType"],
              false,
              i.geometry,
              "Single",
              false,
              [count, 0, 0, 0],
              _consumer,
              null,
              false,
              _consumer.name === "Generator",
              id,
            ),
            [],
            count,
          );
        }
        if (getFormName(type, selectedType) === "transformer") {
          if (parentConsumer) {
            dispatchRedux(
              addTransformerSubChild({
                id: i.id,
                child: groupedConnectionPoint,
                groupedConnectionPointId: parentConsumer.id,
                isGroupUpdate: true,
              }),
            );
          } else {
            dispatchRedux(addTransformerChild({ id: i.id, child: groupedConnectionPoint }));
          }
        } else if (getFormName(type, selectedType) === "groupedConnection") {
          if (parentConsumer) {
            dispatchRedux(
              addGroupedConnectionSubChild({
                id: i.id,
                child: groupedConnectionPoint,
                groupedConnectionPointId: parentConsumer.id,
                isGroupUpdate: true,
              }),
            );
          } else {
            dispatchRedux(
              addGroupedConnectionChild({
                id: i.id,
                child: groupedConnectionPoint,
              }),
            );
          }
        } else if (getFormName(type, selectedType) === "cable") {
          if (parentConsumer) {
            dispatchRedux(
              addCableSubChild({
                id: i.id,
                child: groupedConnectionPoint,
                groupedConnectionPointId: parentConsumer.id,
                isGroupUpdate: true,
              }),
            );
          } else {
            dispatchRedux(addCableChild({ id: i.id, child: groupedConnectionPoint }));
          }
        } else {
          console.error(
            `Unexpected type found.  Current type: ${getFormName(
              type,
              selectedType,
            )}.  Expected: transformer, groupedConnection, cable`,
          );
        }

        const _toolState = toolState;
        const _ringfenced = getRingfencedNetworkAssets(allGeometryAssets, toolState.drawBoundary);
        const ringfencedTypes = getRingfencedTypes(_ringfenced);

        _toolState.ringfenced = _ringfenced;
        _toolState.ringfencedTypes = ringfencedTypes;
        _toolState.ringfenceUpdated = true;
        setToolState(_toolState);
      });
    }
  };

  const getConsumerProperties = () => {
    if (type) {
      switch (type) {
        case "cable":
          return cableProperties;
        case "transformer":
          return transformerProperties;
        case "groupedConnection":
        case "connectionPoint":
          return groupedConnectionProperties;
        default:
          return null;
      }
    } else {
      switch (selectedType) {
        case "Conductors":
          return cableProperties;
        case "Transformers":
          return transformerProperties;
        case "Node":
          return groupedConnectionProperties;
        default:
          return null;
      }
    }
  };

  const isNode = (onlyConnectionless) => {
    if (Item) {
      return false;
    }

    const consumerProperties = getConsumerProperties();

    if (!consumerProperties) {
      return false;
    }

    if (onlyConnectionless) {
      return (
        !consumerProperties.groupedConnectionPoints ||
        !consumerProperties.groupedConnectionPoints.length
      );
    }

    return (
      consumerProperties.groupedConnectionPoints &&
      consumerProperties.groupedConnectionPoints.length
    );
  };

  const shouldShowAddConnection = () => {
    if (!isNode(true)) {
      return true;
    }

    if (groupedConnectionProperties.linkBox) {
      return false;
    }

    if (groupedConnectionProperties.fuse) {
      return false;
    }

    if (groupedConnectionProperties.pole && groupedConnectionProperties.pole.enabled) {
      return false;
    }

    return !(groupedConnectionProperties.potEnds && groupedConnectionProperties.potEnds.length > 0);
  };

  const updateConsumerCount = (consumer) => {
    const _toolState = toolState;

    store.dispatch(startBatchAction()); // Batch closed in handler for consumerCountChangedId

    _toolState.consumerCountChangedId = consumer.id;
    setToolState(_toolState);

    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    dispatch({
      form: `${getFormName(type, selectedType)}Properties`,
      id: consumer.id,
      parentId: undefined,
      field: "count",
      value: consumer.count,
      type: "UPDATE_FIELD2",
    });

    if (clickedAsset) {
      switch (getFormName(type, selectedType)) {
        case "transformer":
          dispatchRedux(
            updateTransformerChild({
              id: clickedAsset.id,
              childUpdate: {
                id: consumer.id,
                name: "count",
                value: consumer.count,
              },
              isGroupUpdate: false,
            }),
          );
          break;
        case "groupedConnection":
          dispatchRedux(
            updateGroupedConnectionChild({
              id: clickedAsset.id,
              childUpdate: {
                id: consumer.id,
                name: "count",
                value: consumer.count,
              },
              isGroupUpdate: false,
            }),
          );
          break;
        case "cable":
          dispatchRedux(
            updateCableChild({
              id: clickedAsset.id,
              childUpdate: {
                id: consumer.id,
                name: "count",
                value: consumer.count,
              },
              isGroupUpdate: false,
            }),
          );
          break;
        default:
          console.error(
            `Unexpected type found.  Current type: ${getFormName(
              type,
              selectedType,
            )}.  Expected: transformer, groupedConnection, cable`,
          );
      }
    }
  };

  const removeItem = (childConsumer, consumer = null) => {
    hideResults();
    const consumerId = consumer.id;
    const childConsumerId = childConsumer ? childConsumer.id : null;

    const _formState = { ...formState };
    _formState.network.existing = false;

    store.dispatch(startBatchAction());
    try {
      setCableAssetStartIdsToConsumerParentId(consumer.parentId);

      dispatch({
        form: `${getFormName(type, selectedType)}Properties`,
        childConsumerId: childConsumerId,
        consumerId: consumerId,
        type: "REMOVE_ITEM",
      });

      if (clickedAsset) {
        if (getFormName(type, selectedType) === "transformer") {
          dispatchRedux(
            removeTransformerChild({
              id: clickedAsset.id,
              consumerId,
              childConsumerId,
              isGroupUpdate: false,
            }),
          );
        } else if (getFormName(type, selectedType) === "groupedConnection") {
          dispatchRedux(
            removeGroupedConnectionChild({
              id: clickedAsset.id,
              consumerId,
              childConsumerId,
              isGroupUpdate: false,
            }),
          );
        } else if (getFormName(type, selectedType) === "cable") {
          dispatchRedux(
            removeCableChild({
              id: clickedAsset.id,
              consumerId,
              childConsumerId,
              isGroupUpdate: false,
            }),
          );
        } else {
          console.error(
            `Unexpected type found.  Current type: ${getFormName(
              type,
              selectedType,
            )}.  Expected: transformer, groupedConnection, cable`,
          );
        }
      }

      if (mode === "select-group") {
        ringfencedFiltered.forEach((i) => {
          if (getFormName(type, selectedType) === "transformer") {
            dispatchRedux(
              removeTransformerChild({
                id: i.id,
                consumerId,
                childConsumerId,
                isGroupUpdate: true,
              }),
            );
          } else if (getFormName(type, selectedType) === "groupedConnection") {
            dispatchRedux(
              removeGroupedConnectionChild({
                id: i.id,
                consumerId,
                childConsumerId,
                isGroupUpdate: true,
              }),
            );
          } else if (getFormName(type, selectedType) === "cable") {
            dispatchRedux(
              removeCableChild({
                id: i.id,
                consumerId,
                childConsumerId,
                isGroupUpdate: true,
              }),
            );
          } else {
            console.error(
              `Unexpected type found.  Current type: ${getFormName(
                type,
                selectedType,
              )}.  Expected: transformer, groupedConnection, cable`,
            );
          }
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const setCableAssetStartIdsToConsumerParentId = (assetId) => {
    const index = groupedConnections.findIndex((x) => x.id === assetId);

    if (index > -1) {
      let groupedConnection = groupedConnections[index];

      if (groupedConnection.styles.class === "connection") {
        groupedConnection.groupedConnectionPoints.forEach((gcp) => {
          cables
            .filter((c) => c.startAssetId === gcp.id)
            .forEach((c) =>
              dispatchRedux(
                updateCable({
                  id: c.id,
                  name: "startAssetId",
                  value: gcp.parentId,
                }),
              ),
            );
        });
      }
    }
  };

  const handleChange = (e) => {
    let connectionId;
    getConsumerProperties().groupedConnectionPoints.forEach((consumer, consumerIndex) => {
      if (consumerIndex === 0) {
        connectionId = consumer.id;
      }
    });
    changeInputValue(e, connectionId);
  };

  const changeInputValue = (e, connectionId) => {
    const name = e.name ? e.name : e.target.name;
    const value = e.value !== undefined ? e.value : e.target.value;
    const formName = getFormName(type, selectedType);

    store.dispatch(startBatchAction());

    try {
      if (connectionId) {
        dispatch({
          form: `${formName}Properties`,
          id: connectionId,
          parentId: null,
          field: name,
          value: value,
          type: "UPDATE_FIELD2",
        });
        if (clickedAsset) {
          switch (formName) {
            case "transformer":
              dispatchRedux(
                updateTransformerChild({
                  id: clickedAsset.id,
                  childUpdate: { id: connectionId, name, value },
                  isGroupUpdate: false,
                }),
              );
              break;
            case "groupedConnection":
              if (name === "annotation") {
                dispatchRedux(updateGroupedConnection({ id: clickedAsset.id, name, value }));
              } else {
                dispatchRedux(
                  updateGroupedConnectionChild({
                    id: clickedAsset.id,
                    childUpdate: { id: connectionId, name, value },
                    isGroupUpdate: false,
                  }),
                );
              }
              break;
            case "cable":
              dispatchRedux(
                updateCableChild({
                  id: clickedAsset.id,
                  childUpdate: { id: connectionId, name, value },
                  isGroupUpdate: false,
                }),
              );
              break;
            default:
              console.error(
                `Unexpected type found.  Current type: ${formName}.  Expected: transformer, groupedConnection, cable`,
              );
          }
        }
      } else {
        dispatch({
          form: `${formName}Properties`,
          field: name,
          value: value,
          type: "UPDATE_FIELD",
        });
        if (clickedAsset) {
          switch (formName) {
            case "welder":
              dispatchRedux(updateWelder({ id: clickedAsset.id, name, value }));
              break;
            case "motor":
              dispatchRedux(updateMotor({ id: clickedAsset.id, name, value }));
              break;
            case "pointOfConnection":
              dispatchRedux(updatePointOfConnection({ id: clickedAsset.id, name, value }));
              break;
            case "transformer":
              dispatchRedux(updateTransformer({ id: clickedAsset.id, name, value }));
              break;
            case "groupedConnection":
              dispatchRedux(updateGroupedConnection({ id: clickedAsset.id, name, value }));
              break;
            case "node":
              dispatchRedux(updateNode({ id: clickedAsset.id, name, value }));
              break;
            case "connectionPoint":
              dispatchRedux(updateConnectionPoint({ id: clickedAsset.id, name, value }));
              break;
            case "cable":
              dispatchRedux(updateCable({ id: clickedAsset.id, name, value }));
              break;
            default:
              console.error(
                `Unexpected type found.  Current type: ${formName}.  Expected: welder, motor, pointOfConnection, transformer, groupedConnection, node, connectionPoint, cable`,
              );
          }
        }
      }
    } finally {
      store.dispatch(endBatchAction());
    }

    if (name === "annotation") {
      setAnnotation(value);
    }
  };

  const getSelectList = () => {
    const groundTypes = reference.groundTypeOverrides?.split(",").map((x) => {
      return { name: x, value: x };
    });
    return groundTypes;
  };

  const defaultsArr = [
    { name: poleHeightIsDefault, label: poleProperties.POLEHEIGHT },
    { name: stayAngleIsDefault, label: poleProperties.STAYANGLE },
    { name: poleTermLengthIsDefault, label: poleProperties.POLETERMLENGTH },
    { name: fuseIsDefault, label: "fuse" },
  ];

  const pointDefaults = ["class"];

  const handleDefault = (e) => {
    if (defaultsArr.some((d) => d.label === e)) {
      dispatch({
        form: "groupedConnectionProperties",
        field: `${e}IsDefault`,
        value: false,
        type: "UPDATE_FIELD",
      });
      dispatchRedux(
        updateGroupedConnection({
          id: clickedAsset.id,
          name: `${e}IsDefault`,
          value: false,
        }),
      );
    }
  };

  const handleAllDefaults = () => {
    store.dispatch(startBatchAction());
    try {
      defaultsArr.forEach((d) => {
        if (d.name) handleDefault(d.label);
      });
      const properties = getConsumerProperties();
      if (properties) {
        const points = [];
        pointDefaults.forEach((e) => {
          properties.groupedConnectionPoints.forEach((consumer) => {
            points.push({ ...consumer, [`${e}IsDefault`]: false });
            dispatchRedux(
              updateGroupedConnectionChild({
                id: clickedAsset.id,
                childUpdate: {
                  id: consumer.id,
                  name: `${e}IsDefault`,
                  value: false,
                },
                isGroupUpdate: false,
              }),
            );
          });
        });
        dispatch({
          form: "groupedConnectionProperties",
          field: "groupedConnectionPoints",
          value: points,
          type: "UPDATE_FIELD",
        });
      }
    } finally {
      store.dispatch(endBatchAction());
    }
  };

  const handleChangeAnnotation = (e) => {
    changeInputValue(e);
  };

  const hasChildDefaults = () => {
    const properties = getConsumerProperties();
    if (!properties) {
      return false;
    }

    return properties.groupedConnectionPoints.some((p) => p.classIsDefault);
  };

  const handlePointDefault = (updatedConsumer, name) => {
    const properties = getConsumerProperties();
    if (properties) {
      const points = [];
      properties.groupedConnectionPoints.forEach((consumer) => {
        if (consumer === updatedConsumer) {
          points.push({ ...consumer, [`${name}IsDefault`]: false });
          dispatchRedux(
            updateGroupedConnectionChild({
              id: clickedAsset.id,
              childUpdate: {
                id: consumer.id,
                name: `${name}IsDefault`,
                value: false,
              },
              isGroupUpdate: false,
            }),
          );
        } else {
          points.push(consumer);
        }
      });
      dispatch({
        form: "groupedConnectionProperties",
        field: "groupedConnectionPoints",
        value: points,
        type: "UPDATE_FIELD",
      });
    }
  };

  const [isLinkbox, setIsLinkBox] = useState(!!groupedConnectionProperties.linkBox);
  useEffect(() => {
    setIsLinkBox(!!groupedConnectionProperties.linkBox);
  }, [groupedConnectionProperties.linkBox]);

  return (
    <>
      {(hasChildDefaults() || defaultsArr.find((d) => d.name === true)) && (
        <Button color="warning" outline block className="mb-3" onClick={() => handleAllDefaults()}>
          Resolve all default warnings
        </Button>
      )}
      {isNode(true) && clickedAsset && (
        <FormGroup>
          <Input
            id="annotation"
            name="annotation"
            className="w-100"
            placeholder="Enter label/annotation"
            value={annotation}
            onChange={handleChangeAnnotation}
            maxLength="50"
          />
        </FormGroup>
      )}

      {clickedAsset && isLinkbox && clientSettings.features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"Junction"} />
      )}

      {clickedAsset && !isLinkbox && clientSettings.features.DynamicPropertiesEnabled && (
        <DynamicPropertiesList propertyType={"ConnectivityNode"} />
      )}

      {clientSettings.features.DebugEnabled && (
        <div>
          <label>DEBUG</label>
          {clickedAsset &&
            ["connectionPoint", "groupedConnection"].includes(clickedAsset.styles.type) &&
            clickedAsset.id && (
              <div className="no-gutters row p-3 mb-1 bg-dark">
                <div className="col-4">Asset Id:</div>
                <div className="col">{clickedAsset.id}</div>
              </div>
            )}

          {clickedAsset &&
            ["connectionPoint", "groupedConnection"].includes(clickedAsset.styles.type) &&
            clickedAsset.id && (
              <div className="no-gutters row p-3 mb-1 bg-dark">
                <div className="col-4">Node Number:</div>
                <div className="col">{clickedAsset.nodeNumber}</div>
              </div>
            )}

          {clickedAsset &&
            ["connectionPoint", "groupedConnection"].includes(clickedAsset.styles.type) &&
            clickedAsset.id && (
              <div className="no-gutters row p-3 mb-1 bg-dark">
                <div className="col-4">Phase Impedance:</div>
                <div className="col">{clickedAsset.phaseImpedance}</div>
              </div>
            )}

          {clickedAsset &&
            ["connectionPoint", "groupedConnection"].includes(clickedAsset.styles.type) &&
            clickedAsset.id && (
              <div className="no-gutters row p-3 mb-1 bg-dark">
                <div className="col-4">Loop Impedance:</div>
                <div className="col">{clickedAsset.loopImpedance}</div>
              </div>
            )}
        </div>
      )}
      {!Item
        ? getConsumerProperties() &&
          getConsumerProperties().groupedConnectionPoints &&
          getConsumerProperties().groupedConnectionPoints.map((consumer, consumerIndex) =>
            consumerIndex === 0 ? (
              <div className="d-flex justify-content-between mb-2" key={consumerIndex}>
                {clickedAsset && (
                  <Input
                    id="annotation"
                    name="annotation"
                    className="w-100"
                    placeholder="Enter label/annotation"
                    value={annotation}
                    onChange={handleChange}
                    maxLength="50"
                    disabled={!clickedAsset}
                  />
                )}
              </div>
            ) : null,
          )
        : null}
      {clientSettings.features.CostingEnabled && isNode(false) && clickedAsset ? (
        <MultiOccupancySelector />
      ) : null}
      {clientSettings.features.CostingEnabled &&
      isNode(true) &&
      clickedAsset &&
      nodeIsConnectedToOverheadLine(cables, clickedAsset) ? (
        <PoleSelector handleDefault={handleDefault} />
      ) : null}
      {getConsumerProperties() &&
        getConsumerProperties().groupedConnectionPoints &&
        getConsumerProperties().groupedConnectionPoints.map((consumer, consumerIndex) => (
          <div key={consumer.id} className="bg-white-alpha" style={{ marginBottom: "1rem" }}>
            <NestedConsumerForm
              consumer={consumer}
              consumerIndex={consumerIndex}
              removeItem={removeItem}
              updateConsumerCount={updateConsumerCount}
              selectedType={selectedType}
              handlePointDefault={handlePointDefault}
            ></NestedConsumerForm>
            {consumer.subGroupConnectionPoints.map((childConsumer, childConsumerIndex) => (
              <div
                key={childConsumer.id}
                style={{
                  padding: "0 .75rem .75rem",
                }}
              >
                <div className="bg-white-alpha">
                  <NestedConsumerForm
                    consumer={consumer}
                    consumerIndex={childConsumerIndex}
                    parentId={consumer.id}
                    childConsumer={childConsumer}
                    removeItem={removeItem}
                    selectedType={selectedType}
                  ></NestedConsumerForm>
                </div>
              </div>
            ))}
            <div
              style={{
                padding: "0 .75rem .75rem",
                margin: ".75rem 0",
                marginTop: consumer.subGroupConnectionPoints?.length === 0 ? -10 : ".75rem 0",
              }}
            >
              <ConsumerSelector
                header="Add Sub Group"
                consumer={consumer}
                count={consumer.count}
                addItem={addItem}
                selectList={CONSUMER_LIST}
              />
            </div>
          </div>
        ))}
      {clientSettings.features.CostingEnabled && isNode(true) && (
        <FormGroup>
          <Label for="overrideGroundType">Ground Type Override</Label>
          <Input
            type="select"
            name="overrideGroundType"
            id="overrideGroundType"
            value={
              groupedConnectionProperties.overrideGroundType ??
              reference.groundTypeOverrideDefaults.groundTypeOverride
            }
            onChange={changeInputValue}
          >
            {getSelectList().map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      {clientSettings.features.ConfigurableLinkBoxEnabled && isNode(true) && (
        <>{!groupedConnectionProperties.pole?.enabled && <LinkBoxSelector />}</>
      )}
      {clientSettings.features.CostingEnabled && isNode(true) && <PotEndSelector />}
      {isNode(true) && (
        <FormGroup>
          {clientSettings.features.NodeFusesEnabled && (
            <NodeFuseSelector handleDefault={handleDefault} />
          )}
        </FormGroup>
      )}
      {shouldShowAddConnection() && (
        <ConsumerSelector header="Add Connection" addItem={addItem} selectList={CONSUMER_LIST} />
      )}
    </>
  );
};

export default ConnectionForm;
