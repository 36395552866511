import { createLayerComponent } from "@react-leaflet/core";
import L from "leaflet";
import "leaflet-imageoverlay-rotated";

const createImageOverlay = (props, context) => {
  /*@ts-ignore*/
  const instance = new L.imageOverlay.rotated(
    props.url,
    props.topLeft,
    props.topRight,
    props.bottomLeft,
    {
      opacity: props.opacity,
      interactive: false,
    },
  );
  return { instance, context };
};

const updateImageOverlay = (instance, props, prevProps) => {
  if (prevProps.topLeft !== props.topLeft) {
    if (instance.setTopLeft) instance.setTopLeft(props.topLeft);
  }
  if (prevProps.topRight !== props.topRight) {
    if (instance.setTopRight) instance.setTopRight(props.topRight);
  }
  if (prevProps.bottomLeft !== props.bottomLeft) {
    if (instance.setBottomLeft) instance.setBottomLeft(props.bottomLeft);
  }
};

const ImageOverlayRotated = createLayerComponent(createImageOverlay, updateImageOverlay);
export default ImageOverlayRotated;
