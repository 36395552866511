import React from "react";
import FaultFilterGroup from "./FaultFilterGroup";

const FaultFilter = ({ faults, resultCategories, onCheckboxBtnClick, defaultSeason }) => {
  return (
    <>
      {resultCategories.map((cat) => (
        <FaultFilterGroup
          key={cat.id}
          categoryId={cat.id}
          faults={faults}
          group={cat.category}
          categories={cat.info}
          onCheckboxBtnClick={onCheckboxBtnClick}
          defaultSeason={defaultSeason}
        />
      ))}
    </>
  );
};
export default FaultFilter;
