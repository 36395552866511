import { v4 as uuid } from "uuid";
import {
  addGroupedConnectionChild,
  addTransformerChild,
  updateGroupedConnection,
  updateGroupedConnectionPole,
} from "../../app/networkSlice";
import { AppDispatch } from "../../app/store";
import { transformerTypes } from "../../constants/groupUpdateTypes";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";
import { GroupedConnectionPoint } from "../../model/viewModel/groupedConnectionPoint";
import { GroupedConnection } from "../../model/viewModel/groupedConnection";

const nodeHasConflictingItem = (node: GroupedConnection) =>
  node.pole?.enabled || node.fuse || node.linkBox || node.potEnds?.length > 0;

export const shouldShowConsumerForm = (
  ringfencedTypes: RingfencedElement[],
  selected: string,
): boolean => {
  const shouldShow = (type: string) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
      case "Nodes":
      case "Node":
      case "Service End Node":
      case "Transformers":
      case "Pole-mounted Transformer":
      case "Ground-mounted Transformer":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some((p) => !shouldShow(p.name));
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const reviewConsumerChange = (
  ringfencedFiltered: RingfencedElement[],
  consumers: GroupedConnectionPoint[],
): string | undefined => {
  if (consumers.length === 0) {
    return undefined;
  }
  const count = ringfencedFiltered.filter(
    (p) => p.styles.name === "Node" && nodeHasConflictingItem(p),
  ).length;
  if (count === 0) {
    return undefined;
  }
  return `Node${count > 1 ? "s" : ""} (x${count}) contain${count === 1 ? "s" : ""} a pole or fuse or link box or pot end and cannot have consumers added.  How would you like to continue?`;
};

export const applyConsumerChange = (
  ringfencedFiltered: RingfencedElement[],
  consumers: GroupedConnectionPoint[],
  replaceNodes: boolean,
  setConsumers: (consumers: GroupedConnectionPoint[]) => void,
  dispatchRedux: AppDispatch,
): void => {
  const handledNodes = [] as string[];
  ringfencedFiltered.forEach((p) => {
    if (p.ringfencedPoint) {
      if (handledNodes.includes(p.id)) {
        return;
      }
      handledNodes.push(p.id);
    }
    if (p.styles.name === "Node" && nodeHasConflictingItem(p)) {
      if (!replaceNodes) {
        return;
      }
      if (p.pole) {
        dispatchRedux(updateGroupedConnectionPole({ id: p.id, name: "enabled", value: false }));
      }
      if (p.fuse) {
        dispatchRedux(updateGroupedConnection({ id: p.id, name: "fuse", value: undefined }));
      }
      if (p.linkBox) {
        dispatchRedux(updateGroupedConnection({ id: p.id, name: "linkBox", value: undefined }));
      }
      if (p.potEnds) {
        dispatchRedux(updateGroupedConnection({ id: p.id, name: "potEnds", value: [] }));
      }
    }
    consumers.forEach((child) => {
      const newChildId = uuid();
      const newChild = {
        ...child,
        geometry: p.geometry,
        id: newChildId,
        subGroupConnectionPoints: child.subGroupConnectionPoints.map((q) => ({
          ...q,
          id: uuid(),
          geometry: p.geometry,
          parentId: newChildId,
        })),
      };
      if (transformerTypes.includes(p.styles.name)) {
        dispatchRedux(addTransformerChild({ id: p.id, child: newChild }));
      } else {
        dispatchRedux(addGroupedConnectionChild({ id: p.id, child: newChild }));
      }
    });
  });

  setConsumers([]);
};
