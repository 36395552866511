// @flow

import ConductingEquipment from "../cim/conductingEquipment";
import { createCableFromConductingEquipment } from "./cableFactory";
import {
  isGroupedConnection,
  isSubGroupConnection,
  createGroupedConnectionsFromConductingEquipment,
  isExistingConsumer,
} from "./groupedConnectionFactory";
import { createNodeFromConductingEquipment } from "./nodeFactory";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import { createConnectionPointFromConductingEquipment } from "./connectionPointFactory";
import { createMotorFromConductingEquipment } from "./motorFactory";
import { createWelderFromConductingEquipment } from "./welderFactory";
import { createTransformerFromConductingEquipment } from "./transformerFactory";
import { createPointOfConnectionFromConductingEquipment } from "./pointOfConnectionFactory";
import {
  ACLineSegmentType,
  EnergyConsumerType,
  DistributedEnergyConsumerType,
  PowerTransformerType,
  JunctionType,
  GeneratorConsumerType,
  DistributedGeneratorConsumerType,
  MotorType,
  WelderType,
  PointOfConnectionType,
} from "../cim/cimFactory";
import Terminal from "../cim/terminal";
import { updateGroupedConnection } from "../../app/networkSlice";

export function createNetworkFromAssessmentResponse(
  reference: any,
  response: any,
  dynamicPropertySettings: any,
  substationId: any,
  existingGroupedConnections: any,
  dispatchRedux: any,
  clearAssessmentResults: boolean,
) {
  const cableCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === ACLineSegmentType,
  );
  const transformerCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === PowerTransformerType,
  );

  const existingConsumersGroupedConnectionCimObjects =
    response.data.network.conductingEquipment.filter(
      (ce) =>
        (ce.type === EnergyConsumerType || ce.type === GeneratorConsumerType) &&
        isGroupedConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)) &&
        isExistingConsumer(new ConductingEquipment(ce.id, ce.type, ce.properties)),
    );

  const existingConsumersSubGroupedConnectionCimObjects =
    response.data.network.conductingEquipment.filter(
      (ce) =>
        (ce.type === EnergyConsumerType || ce.type === GeneratorConsumerType) &&
        isSubGroupConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)) &&
        isExistingConsumer(new ConductingEquipment(ce.id, ce.type, ce.properties)),
    );

  const existingConsumersTerminals = response.data.network.terminals.filter((t) =>
    isExistingConsumerTerminal(
      new Terminal(t.conductingEquipmentId, t.connectivityNodeId, t.properties),
    ),
  );

  //consumers and transformer are bounded using terminals and connectivities
  existingConsumersTerminals.forEach((ter) => {
    let transformer = transformerCimObjects.filter(
      (tran) => tran.id === ter.conductingEquipmentId,
    )[0];

    if (transformer) {
      let connectivityNodeId = ter.connectivityNodeId;

      //get all conducting equipment id(exclude transformer)
      let existingConsumerIdsOfTransformer = existingConsumersTerminals
        .filter(
          (ter) =>
            ter.connectivityNodeId === connectivityNodeId &&
            ter.conductingEquipmentId !== transformer.id,
        )
        .map((ter) => ter.conductingEquipmentId);

      let existingConsumerGroupedConnectionsOfTransformer =
        existingConsumersGroupedConnectionCimObjects.filter(
          (consumer) =>
            existingConsumerIdsOfTransformer.filter((id) => consumer.id === id).length > 0,
        );

      let existingConsumerSubGroupConnectionsOfTransformer =
        existingConsumersSubGroupedConnectionCimObjects.filter(
          (consumer) =>
            existingConsumerIdsOfTransformer.filter((id) => consumer.id === id).length > 0,
        );

      let groupedConnectionOfTransformer = createGroupedConnection(
        reference,
        existingConsumerGroupedConnectionsOfTransformer,
        existingConsumerSubGroupConnectionsOfTransformer,
        substationId,
      );

      if (transformer.groupedConnectionPoints) {
        transformer.groupedConnectionPoints = [
          ...transformer.groupedConnectionPoints,
          ...groupedConnectionOfTransformer[0].groupedConnectionPoints,
        ];
      } else {
        transformer.groupedConnectionPoints =
          groupedConnectionOfTransformer[0].groupedConnectionPoints;
      }
    }
  });

  const motorCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === MotorType,
  );
  const welderCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === WelderType,
  );
  const nodeCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === JunctionType,
  );
  const connectionPointCimObjects = response.data.network.conductingEquipment.filter(
    (ce) =>
      (ce.type === EnergyConsumerType || ce.type === GeneratorConsumerType) &&
      !isGroupedConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)) &&
      !isSubGroupConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)),
  );
  const pointOfConnectionCimObjects = response.data.network.conductingEquipment.filter(
    (ce) => ce.type === PointOfConnectionType,
  );
  const groupedConnectionCimObjects = response.data.network.conductingEquipment.filter(
    (ce) =>
      (ce.type === EnergyConsumerType || ce.type === GeneratorConsumerType) &&
      isGroupedConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)) &&
      !isExistingConsumer(new ConductingEquipment(ce.id, ce.type, ce.properties)),
  );

  const subGroupedConnectionCimObjects = response.data.network.conductingEquipment.filter(
    (ce) =>
      (ce.type === EnergyConsumerType || ce.type === GeneratorConsumerType) &&
      isSubGroupConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)) &&
      !isExistingConsumer(new ConductingEquipment(ce.id, ce.type, ce.properties)),
  );
  const cables = cableCimObjects.map((o) =>
    createCableFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      substationId,
      dynamicPropertySettings,
    ),
  );

  const distributedGroupedConnectionCimObjects = response.data.network.conductingEquipment.filter(
    (ce) =>
      (ce.type === DistributedEnergyConsumerType || ce.type === DistributedGeneratorConsumerType) &&
      isGroupedConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)),
  );

  const distributedSubGroupedConnectionCimObjects =
    response.data.network.conductingEquipment.filter(
      (ce) =>
        (ce.type === DistributedEnergyConsumerType ||
          ce.type === DistributedGeneratorConsumerType) &&
        isSubGroupConnection(new ConductingEquipment(ce.id, ce.type, ce.properties)),
    );

  const distributedGroupedConnections =
    distributedGroupedConnectionCimObjects.length === 0
      ? []
      : createGroupedConnection(
          reference,
          distributedGroupedConnectionCimObjects,
          distributedSubGroupedConnectionCimObjects,
        );

  //locate cable
  distributedGroupedConnections.forEach((dgc) =>
    dgc.groupedConnectionPoints.forEach((gcp) => {
      cables.find((c) => c.id === gcp.parentId).groupedConnectionPoints.push(gcp);
    }),
  );

  const nodes = nodeCimObjects.flatMap((o) => {
    const newNode = new ConductingEquipment(o.id, o.type, o.properties);
    const newNodeWpdId = newNode.properties.find((p) => p.name === "wpdId")?.value;
    const newNodeGeometryWkt = newNode.properties.find((p) => p.name === "geometry")?.value;
    const newNodeLinkBox = newNode.properties.find((p) => p.name === "linkBox")?.value;

    if (newNodeWpdId && newNodeGeometryWkt && newNodeLinkBox) {
      const newNodeGeometry = getLatLngFromWktPoint(newNodeGeometryWkt);

      const existingNode = existingGroupedConnections?.find(
        (p) =>
          p.wpdId === newNodeWpdId &&
          newNodeGeometry.lat === p.geometry?.lat &&
          newNodeGeometry.lng === p.geometry?.lng,
      );

      if (existingNode) {
        const closed = [
          ...(existingNode.linkBox?.connectivity?.closed ?? []),
          ...(JSON.parse(newNodeLinkBox).connectivity?.closed ?? []),
        ];

        dispatchRedux(
          updateGroupedConnection({
            id: existingNode.id,
            name: "linkBox",
            value: {
              ...existingNode.linkBox,
              connectivity: {
                closed: closed,
              },
            },
          }),
        );

        cables
          .filter((p) => p.startAssetId === newNode.id)
          .forEach((cable) => {
            cable.startAssetId = existingNode.id;
          });
        cables
          .filter((p) => p.endAssetId === newNode.id)
          .forEach((cable) => {
            cable.endAssetId = existingNode.id;
          });
        return [];
      }
    }

    return {
      ...createNodeFromConductingEquipment(
        reference,
        newNode,
        substationId,
        dynamicPropertySettings,
      ),
      groupedConnectionPoints: [],
    };
  });

  const transformers = transformerCimObjects.map((o) =>
    createTransformerFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      o.groupedConnectionPoints,
      substationId,
    ),
  );
  const motors = motorCimObjects.map((o) =>
    createMotorFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      substationId,
    ),
  );
  const welders = welderCimObjects.map((o) =>
    createWelderFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      substationId,
    ),
  );
  const pointOfConnections = pointOfConnectionCimObjects.map((o) =>
    createPointOfConnectionFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      substationId,
    ),
  );
  const connectionPoints = connectionPointCimObjects.map((o) =>
    createConnectionPointFromConductingEquipment(
      new ConductingEquipment(o.id, o.type, o.properties),
      substationId,
    ),
  );

  const groupedConnections = createGroupedConnection(
    reference,
    groupedConnectionCimObjects,
    subGroupedConnectionCimObjects,
    substationId,
  );

  const clear = (conductingEquipment) => {
    const fields = [
      "maxVoltDropPercent",
      "maxVoltDropPercentPassResult",
      "maxVoltRisePercent",
      "earthLoopImpedance",
      "threePhaseFaultCurrent",
      "phaseToPhaseFaultCurrent",
      "singlePhaseToEarthFaultCurrent",
      "hasFuseWarning",
      "loopImpedanceOperating",
      "loopImpedanceFault",
      "phaseImpedanceOperating",
      "phaseImpedanceFault",
      "resistanceLoopOperating",
      "resistanceLoopFault",
      "resistanceCumulativeOperating",
      "resistanceCumulativeFault",
      "reactanceLoopOperating",
      "reactanceLoopFault",
      "reactanceCumulativeOperating",
      "reactanceCumulativeFault",
      "AllmaxVoltDropPercent",
      "AllmaxVoltDropPercentPeriod",
      "AllmaxVoltDropPercentPassResult",
      "AllmaxVoltDropPhase",
      "AllmaxVoltRisePercent",
      "AllmaxVoltRisePercentPeriod",
      "AllmaxVoltRisePhase",
      "AllmaxVoltage",
      "AllmaxVoltagePhase1",
      "AllmaxVoltagePhase2",
      "AllmaxVoltagePhase3",
      "AllminVoltage",
      "AllminVoltagePhase1",
      "AllminVoltagePhase2",
      "AllminVoltagePhase3",
      "wintermaxVoltDropPercent",
      "wintermaxVoltDropPercentPeriod",
      "wintermaxVoltDropPercentPassResult",
      "wintermaxVoltDropPhase",
      "wintermaxVoltRisePercent",
      "wintermaxVoltRisePercentPeriod",
      "wintermaxVoltRisePhase",
      "wintermaxVoltage",
      "wintermaxVoltagePhase1",
      "wintermaxVoltagePhase2",
      "wintermaxVoltagePhase3",
      "winterminVoltage",
      "winterminVoltagePhase1",
      "winterminVoltagePhase2",
      "winterminVoltagePhase3",
      "summermaxVoltDropPercent",
      "summermaxVoltDropPercentPeriod",
      "summermaxVoltDropPercentPassResult",
      "summermaxVoltDropPhase",
      "summermaxVoltRisePercent",
      "summermaxVoltRisePercentPeriod",
      "summermaxVoltRisePhase",
      "summermaxVoltage",
      "summermaxVoltagePhase1",
      "summermaxVoltagePhase2",
      "summermaxVoltagePhase3",
      "summerminVoltage",
      "summerminVoltagePhase1",
      "summerminVoltagePhase2",
      "summerminVoltagePhase3",
      "flickerDeltaVoltage",
      "flickerPassResult",
      "flickerStartingDeltaVoltage",
      "flickerStartingPassResult",
    ];
    fields.forEach((p) => {
      if (conductingEquipment[p]) {
        conductingEquipment[p] = null;
      }
    });
  };

  if (clearAssessmentResults) {
    nodes.map(clear);
    transformers.map(clear);
    connectionPoints.map(clear);
    cables.map(clear);
    groupedConnections.map(clear);
    motors.map(clear);
    welders.map(clear);
    pointOfConnections.map(clear);
  }

  return {
    transformers: transformers,
    connectionPoints: connectionPoints,
    nodes: [],
    cables: cables,
    groupedConnections: [...groupedConnections, ...nodes],
    motors: motors,
    welders: welders,
    pointOfConnections: pointOfConnections,
  };
}

function isExistingConsumerTerminal(terminal: Terminal): boolean {
  return terminal.getPropertyValue("existingConsumer") === "true";
}
function createGroupedConnection(
  reference: any,
  groupedConnectionCimObjects: any,
  subGroupedConnectionCimObjects: any,
  transformerId: any,
) {
  const conductingEquipmentsGroupByGC = groupBy(groupedConnectionCimObjects, "parentId");

  const groupedConnections = conductingEquipmentsGroupByGC.map(
    (conductingEquipmentsFromSameGroupConnection) =>
      createGroupedConnectionsFromConductingEquipment(
        reference,
        conductingEquipmentsFromSameGroupConnection.map(
          (o) => new ConductingEquipment(o.id, o.type, o.properties),
        ),
        subGroupedConnectionCimObjects.map(
          (o) => new ConductingEquipment(o.id, o.type, o.properties),
        ),
        transformerId,
      ),
  );

  return groupedConnections;
}

export function groupBy(collection, groupByField) {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i].properties.find((p) => p.name === groupByField).value;
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}
