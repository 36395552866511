import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";
import { INodeProperties, NodeProperties } from "./nodeProperties";

export class Node extends Asset implements INodeProperties {
  constructor(id: string, nodeProperties: NodeProperties) {
    super(id);
    this.annotation = nodeProperties.annotation;
    this.geometry = nodeProperties.geometry;
    this.maxVoltDropPercent = nodeProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult = nodeProperties.maxVoltDropPercentPassResult;
    this.maxVoltRiseTotal = nodeProperties.maxVoltRiseTotal;
    this.maxVoltRiseGeneration = nodeProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = nodeProperties.maxVoltRiseUnbalance;
    this.earthLoopImpedance = nodeProperties.earthLoopImpedance;
    this.threePhaseFaultCurrent = nodeProperties.threePhaseFaultCurrent;
    this.phaseToPhaseFaultCurrent = nodeProperties.phaseToPhaseFaultCurrent;
    this.singlePhaseToEarthFaultCurrent = nodeProperties.singlePhaseToEarthFaultCurrent;
    this.hasFuseWarning = nodeProperties.hasFuseWarning;
    this.flickerPassResult = nodeProperties.flickerPassResult;
    this.flickerDeltaVoltage = nodeProperties.flickerDeltaVoltage;
    this.flickerP28Limit = nodeProperties.flickerP28Limit;
    this.flickerStartingPassResult = nodeProperties.flickerStartingPassResult;
    this.flickerStartingDeltaVoltage = nodeProperties.flickerStartingDeltaVoltage;
    this.styles = nodeProperties.styles;
    this.impedanceLoopOperating = nodeProperties.impedanceLoopOperating;
    this.impedanceLoopOperatingPassResult = nodeProperties.impedanceLoopOperatingPassResult;
    this.impedanceSourceLoopOperating = nodeProperties.impedanceSourceLoopOperating;
    this.impedanceSourceLoopOperatingPassResult =
      nodeProperties.impedanceSourceLoopOperatingPassResult;
    this.impedanceLoopFault = nodeProperties.impedanceLoopFault;
    this.impedancePhaseOperating = nodeProperties.impedancePhaseOperating;
    this.impedanceSourcePhaseOperating = nodeProperties.impedanceSourcePhaseOperating;
    this.impedanceSourcePhasePhaseOperating = nodeProperties.impedanceSourcePhasePhaseOperating;
    this.impedancePhaseFault = nodeProperties.impedancePhaseFault;
    this.resistanceLoopOperating = nodeProperties.resistanceLoopOperating;
    this.resistanceSourceLoopOperating = nodeProperties.resistanceSourceLoopOperating;
    this.resistanceLoopFault = nodeProperties.resistanceLoopFault;
    this.resistancePhaseOperating = nodeProperties.resistancePhaseOperating;
    this.resistanceSourcePhaseOperating = nodeProperties.resistanceSourcePhaseOperating;
    this.resistanceSourcePhasePhaseOperating = nodeProperties.resistanceSourcePhasePhaseOperating;
    this.resistancePhaseFault = nodeProperties.resistancePhaseFault;
    this.reactanceLoopOperating = nodeProperties.reactanceLoopOperating;
    this.reactanceSourceLoopOperating = nodeProperties.reactanceSourceLoopOperating;
    this.reactanceLoopFault = nodeProperties.reactanceLoopFault;
    this.reactancePhaseOperating = nodeProperties.reactancePhaseOperating;
    this.reactanceSourcePhaseOperating = nodeProperties.reactanceSourcePhaseOperating;
    this.reactanceSourcePhasePhaseOperating = nodeProperties.reactanceSourcePhasePhaseOperating;
    this.reactancePhaseFault = nodeProperties.reactancePhaseFault;
    this.AllmaxVoltDropPercent = nodeProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod = nodeProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult = nodeProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltDropPhase = nodeProperties.AllmaxVoltDropPhase;
    this.AllmaxVoltRiseTotal = nodeProperties.AllmaxVoltRiseTotal;
    this.AllmaxVoltRiseGeneration = nodeProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = nodeProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod = nodeProperties.AllmaxVoltRisePercentPeriod;
    this.AllmaxVoltRisePhase = nodeProperties.AllmaxVoltRisePhase;
    this.AllmaxVoltage = nodeProperties.AllmaxVoltage;
    this.AllmaxVoltagePhase1 = nodeProperties.AllmaxVoltagePhase1;
    this.AllmaxVoltagePhase2 = nodeProperties.AllmaxVoltagePhase2;
    this.AllmaxVoltagePhase3 = nodeProperties.AllmaxVoltagePhase3;
    this.AllminVoltage = nodeProperties.AllminVoltage;
    this.AllminVoltagePhase1 = nodeProperties.AllminVoltagePhase1;
    this.AllminVoltagePhase2 = nodeProperties.AllminVoltagePhase2;
    this.AllminVoltagePhase3 = nodeProperties.AllminVoltagePhase3;
    this.wintermaxVoltDropPercent = nodeProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod = nodeProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult = nodeProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltDropPhase = nodeProperties.wintermaxVoltDropPhase;
    this.wintermaxVoltRisePercent = nodeProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRiseTotal = nodeProperties.wintermaxVoltRiseTotal;
    this.wintermaxVoltRiseGeneration = nodeProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance = nodeProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePercentPeriod = nodeProperties.wintermaxVoltRisePercentPeriod;
    this.wintermaxVoltRisePhase = nodeProperties.wintermaxVoltRisePhase;
    this.wintermaxVoltage = nodeProperties.wintermaxVoltage;
    this.wintermaxVoltagePhase1 = nodeProperties.wintermaxVoltagePhase1;
    this.wintermaxVoltagePhase2 = nodeProperties.wintermaxVoltagePhase2;
    this.wintermaxVoltagePhase3 = nodeProperties.wintermaxVoltagePhase3;
    this.winterminVoltage = nodeProperties.winterminVoltage;
    this.winterminVoltagePhase1 = nodeProperties.winterminVoltagePhase1;
    this.winterminVoltagePhase2 = nodeProperties.winterminVoltagePhase2;
    this.winterminVoltagePhase3 = nodeProperties.winterminVoltagePhase3;
    this.summermaxVoltDropPercent = nodeProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod = nodeProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult = nodeProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltDropPhase = nodeProperties.summermaxVoltDropPhase;
    this.summermaxVoltRisePercent = nodeProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = nodeProperties.summermaxVoltRiseTotal;
    this.summermaxVoltRiseGeneration = nodeProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance = nodeProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod = nodeProperties.summermaxVoltRisePercentPeriod;
    this.summermaxVoltRisePhase = nodeProperties.summermaxVoltRisePhase;
    this.summermaxVoltage = nodeProperties.summermaxVoltage;
    this.summermaxVoltagePhase1 = nodeProperties.summermaxVoltagePhase1;
    this.summermaxVoltagePhase2 = nodeProperties.summermaxVoltagePhase2;
    this.summermaxVoltagePhase3 = nodeProperties.summermaxVoltagePhase3;
    this.summerminVoltage = nodeProperties.summerminVoltage;
    this.summerminVoltagePhase1 = nodeProperties.summerminVoltagePhase1;
    this.summerminVoltagePhase2 = nodeProperties.summerminVoltagePhase2;
    this.summerminVoltagePhase3 = nodeProperties.summerminVoltagePhase3;
    this.nodeNumber = nodeProperties.nodeNumber;
    this.isServiceLeaf = nodeProperties.isServiceLeaf;
    this.status = nodeProperties.status;
    this.linkBox = nodeProperties.linkBox;
    this.potEnds = nodeProperties.potEnds;
    this.overrideGroundType = nodeProperties.overrideGroundType;
    this.pole = nodeProperties.pole;
    this.poleHeightIsDefault = nodeProperties.poleHeightIsDefault;
    this.stayAngleIsDefault = nodeProperties.stayAngleIsDefault;
    this.poleTermLengthIsDefault = nodeProperties.poleTermLengthIsDefault;
    this.phaseImpedance = nodeProperties.phaseImpedance;
    this.loopImpedance = nodeProperties.loopImpedance;
    this.fuse = nodeProperties.fuse;
    this.rootTransformerId = nodeProperties.rootTransformerId;
    this.substationId = nodeProperties.substationId;
    this.wpdId = nodeProperties.wpdId;
    this.fuseIsDefault = nodeProperties.fuseIsDefault;
  }

  [key: string]: any;
  annotation: String;
  geometry: LatLng;
  maxVoltDropPercent: number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: number | null;
  maxVoltRiseGeneration: number | null;
  maxVoltRiseUnbalance: number | null;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  hasFuseWarning: Boolean;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  styles: any;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: number | null;
  AllmaxVoltDropPercentPeriod: number | null;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: number | null;
  AllmaxVoltRiseTotal: number | null;
  AllmaxVoltRiseGeneration: number | null;
  AllmaxVoltRiseUnbalance: number | null;
  AllmaxVoltRisePercentPeriod: number | null;
  AllmaxVoltRisePhase: number | null;
  AllmaxVoltage: number | null;
  AllmaxVoltagePhase1: number | null;
  AllmaxVoltagePhase2: number | null;
  AllmaxVoltagePhase3: number | null;
  AllminVoltage: number | null;
  AllminVoltagePhase1: number | null;
  AllminVoltagePhase2: number | null;
  AllminVoltagePhase3: number | null;
  wintermaxVoltDropPercent: number | null;
  wintermaxVoltDropPercentPeriod: number | null;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: number | null;
  wintermaxVoltRisePercent: number | null;
  wintermaxVoltRiseTotal: number | null;
  wintermaxVoltRiseGeneration: number | null;
  wintermaxVoltRiseUnbalance: number | null;
  wintermaxVoltRisePercentPeriod: number | null;
  wintermaxVoltRisePhase: number | null;
  wintermaxVoltage: number | null;
  wintermaxVoltagePhase1: number | null;
  wintermaxVoltagePhase2: number | null;
  wintermaxVoltagePhase3: number | null;
  winterminVoltage: number | null;
  winterminVoltagePhase1: number | null;
  winterminVoltagePhase2: number | null;
  winterminVoltagePhase3: number | null;
  summermaxVoltDropPercent: number | null;
  summermaxVoltDropPercentPeriod: number | null;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: number | null;
  summermaxVoltRisePercent: number | null;
  summermaxVoltRiseTotal: number | null;
  summermaxVoltRiseGeneration: number | null;
  summermaxVoltRiseUnbalance: number | null;
  summermaxVoltRisePercentPeriod: number | null;
  summermaxVoltRisePhase: number | null;
  summermaxVoltage: number | null;
  summermaxVoltagePhase1: number | null;
  summermaxVoltagePhase2: number | null;
  summermaxVoltagePhase3: number | null;
  summerminVoltage: number | null;
  summerminVoltagePhase1: number | null;
  summerminVoltagePhase2: number | null;
  summerminVoltagePhase3: number | null;
  nodeNumber: number;
  isServiceLeaf: Boolean;
  status: string;
  linkBox: any;
  potEnds: any;
  overrideGroundType: string;
  pole: any;
  poleHeightIsDefault: Boolean;
  stayAngleIsDefault: Boolean;
  poleTermLengthIsDefault: Boolean;
  phaseImpedance: number | null;
  loopImpedance: number | null;
  fuse: number | null;
  rootTransformerId: string;
  substationId: string;
  wpdId: string;
  fuseIsDefault: Boolean;
}
