export interface UndoableSettings {
  maxHistory: number;
  ignoreActions: string[];
}

export interface UndoableState<T> {
  past: T[];
  present: T;
  future: T[];
  groupDepth: number;
  isSkipping: Boolean;
  customMaxHistory: number | null;
}

export interface UndoableAction {
  type: string;
  [extraProps: string]: any;
}

export const undoAction = () => ({ type: "UNDO" });
export const redoAction = () => ({ type: "REDO" });
export const startBatchAction = () => ({ type: "START" });
export const endBatchAction = () => ({ type: "END" });
export const clearHistoryAction = () => ({ type: "CLEAR" });
export const startSkipAction = () => ({ type: "STARTSKIP" });
export const endSkipAction = () => ({ type: "ENDSKIP" });
export const updateMaxHistory = (value: number) => ({ type: "MAXHISTORY", payload: value });

export const undoable = <S, A extends UndoableAction>(
  reducer: (state: S, action: A) => S,
  settings: UndoableSettings = {
    maxHistory: 5,
    ignoreActions: [],
  },
) => {
  const initialState: UndoableState<S> = {
    past: [],
    present: reducer(undefined as any, {} as A),
    future: [],
    groupDepth: 0,
    isSkipping: false,
    customMaxHistory: settings.maxHistory,
  };

  const addToPast = <S>(state: UndoableState<S>, item: S) => {
    var newPast = [...state.past, item];
    if (newPast.length > (state.customMaxHistory || settings.maxHistory)) {
      newPast.shift();
    }
    return newPast;
  };

  return (state: UndoableState<S> = initialState, action: A): UndoableState<S> => {
    switch (action.type) {
      case "UNDO":
        if (state.past.length === 0) {
          return state;
        }

        const previous = state.past[state.past.length - 1];
        const newPast = state.past.slice(0, -1);
        const newFuture = [state.present, ...state.future];

        return {
          past: newPast,
          present: previous,
          future: newFuture,
          groupDepth: 0,
          isSkipping: false,
          customMaxHistory: state.customMaxHistory,
        };

      case "REDO":
        if (state.future.length === 0) {
          return state;
        }

        const next = state.future[0];
        const newPastForRedo = [...state.past, state.present];
        const newFutureForRedo = state.future.slice(1);

        return {
          past: newPastForRedo,
          present: next,
          future: newFutureForRedo,
          groupDepth: 0,
          isSkipping: false,
          customMaxHistory: state.customMaxHistory,
        };

      case "START":
        return {
          ...state,
          past: state.groupDepth === 0 ? addToPast(state, state.present) : state.past,
          groupDepth: state.groupDepth + 1,
        };

      case "END":
        return {
          ...state,
          groupDepth: Math.max(state.groupDepth - 1, 0),
        };

      case "STARTSKIP":
        return {
          ...state,
          isSkipping: true,
        };

      case "ENDSKIP":
        return {
          ...state,
          isSkipping: false,
        };

      case "CLEAR":
        return {
          ...state,
          past: [],
          future: [],
          isSkipping: false,
          groupDepth: 0,
        };

      case "MAXHISTORY":
        return {
          ...state,
          customMaxHistory: action.payload as number,
        };

      default:
        if (state.isSkipping || settings.ignoreActions.includes(action.type)) {
          return {
            ...state,
            present: reducer(state.present, action),
          };
        }

        const original = JSON.stringify(state.present);
        const newPresent = reducer(JSON.parse(original), action);

        if (state.groupDepth > 0) {
          return {
            ...state,
            present: reducer(JSON.parse(original), action),
            future: [],
          };
        }

        if (JSON.stringify(newPresent) === original) {
          return state;
        }

        return {
          ...state,
          past: addToPast(state, JSON.parse(original)),
          present: newPresent,
          future: [],
        };
    }
  };
};
