import { Button, Input, InputGroup } from "reactstrap";

const ConfigSearch = ({ optionSearchValue, setOptionSearchValue }) => {
  const filterSearch = (e) => {
    const value = e.value !== undefined ? e.value : e.target.value;
    setOptionSearchValue(value);
  };

  return (
    <InputGroup>
      <Input
        type="text"
        placeholder="Search Config"
        bsSize="sm"
        onChange={filterSearch}
        value={optionSearchValue}
        onClick={(e) => e.stopPropagation()}
      ></Input>
      <Button
        className="input-button"
        onClick={(e) => {
          if (optionSearchValue?.length > 0) {
            setOptionSearchValue("");
          }
          e.stopPropagation();
        }}
      >
        <i className={`icon-${optionSearchValue?.length > 0 ? "cross" : "search"}-solid`}></i>
      </Button>
    </InputGroup>
  );
};

export default ConfigSearch;
