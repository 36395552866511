import React from "react";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, Popup, useMap } from "react-leaflet";
import { offsetPosition } from "../utils/offsetPosition";
import { GroupedConnectionPoint } from "../model/viewModel/groupedConnectionPoint";
import { ConnectionPointProperties } from "../model/viewModel/connectionPointProperties";
import "./LeafletPopup.css";

const CounterBadge = ({ asset, value, label, position, popup }) => {
  const map = useMap();

  const styleBadge = (value) => {
    const badge = L.divIcon({
      className: "countMarker",
      html: ReactDOMServer.renderToString(
        <div
          className={`badge rounded-pill bg-primary border text-light p-0`}
          style={{
            backgroundColor: label && "transparent !important",
            border: label && "0 !important ",
          }}
        >
          <span
            style={{
              display: "inline-block",
              fontSize: ".8rem",
              padding: "3px 5.5px 3px 5.5px",
              marginLeft: label > 1 && "-18px",
              backgroundColor: label > 1 && "#1c9d5b",
              borderRadius: label > 1 && "50%",
            }}
          >
            {value && value}
          </span>
        </div>,
      ),
    });
    return badge;
  };

  const toSummaryGroups = (groupedConnectionPoints) => {
    const pointsWithSubConnections = groupedConnectionPoints.filter(
      (gcp) => gcp.subGroupConnectionPoints.length > 0,
    );
    const simplePoints = groupedConnectionPoints.filter(
      (gcp) => !gcp.subGroupConnectionPoints.length,
    );
    const groups = Object.groupBy(simplePoints, (gcp) => `${gcp.styles.class} ${gcp.styles.name}`);
    const combinedPoints = Object.values(groups).map((group) => {
      let connectionPointProperties = new ConnectionPointProperties();
      connectionPointProperties.styles = group[0].styles;
      const count = group.reduce((partialCount, gcp) => partialCount + parseInt(gcp.count), 0);
      return new GroupedConnectionPoint("", connectionPointProperties, [], count, "");
    });
    return combinedPoints.concat(pointsWithSubConnections);
  };

  return (
    <>
      {value && (
        <Marker
          id={asset.id}
          className="counter-badge"
          position={offsetPosition(map, asset.geometry, position[0], position[1])}
          icon={styleBadge((value && value) || (label > 1 && label))}
          zIndexOffset={asset.geometry.lng + 1}
        >
          {popup && (
            <Popup>
              <ul className="m-1 p-0 counter-badge-popup">
                {toSummaryGroups(asset.groupedConnectionPoints).map((gcp, index) => (
                  <li className="list-unstyled" key={index}>
                    <i className={`icon-${gcp.styles.class}`}></i>
                    {gcp.styles.name} x{label > 1 ? label : gcp.count}
                    <ul className="p-0 m-0">
                      {gcp.subGroupConnectionPoints.map((subGcp, subIndex) => (
                        <li className="list-unstyled" key={subIndex}>
                          <span className="text-muted">↳</span>{" "}
                          <i className={`icon-${subGcp.styles.class}`}></i>
                          {subGcp.styles.name}
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </Popup>
          )}
        </Marker>
      )}
    </>
  );
};

export default CounterBadge;
