import { useState } from "react";

import { ListGroup, ListGroupItem } from "reactstrap";

const GroupAsset = ({ asset, selectedAsset, filterAssets }) => {
  const getChildrenCount = () => {
    return asset.children.reduce((total, child) => total + child.count, 0);
  };

  const [minimised, setMinimised] = useState(false);

  const handleMinimised = (e) => {
    setMinimised(!minimised);
    e.stopPropagation();
  };

  if (asset.children) {
    return (
      <>
        <ListGroupItem
          key={asset.name}
          tag="a"
          className={`d-flex justify-content-between ${
            selectedAsset === asset.name ? "text-white" : "text-black"
          }`}
          onClick={() => filterAssets(asset.name, true)}
        >
          <span>
            <span>
              <span
                name="minimise-icon"
                style={{
                  marginRight: "5px",
                  marginLeft: "-15px",
                  display: "inline-block",
                  width: "15px",
                }}
                className={`icon-chevron-${!minimised ? "down" : "right"}`}
                onClick={handleMinimised}
              ></span>
              {asset.name === "Grouped Connection" ? "Connection" : asset.name}
            </span>
            <span className="badge badge-primary ml-1" style={{ fontSize: "100%" }}>
              {getChildrenCount()}
            </span>
          </span>
          <span className="list-group-item-icon">
            <i className={`icon-${selectedAsset === asset.name ? "check" : "cross"}-solid`}></i>
          </span>
        </ListGroupItem>
        {!minimised && (
          <ListGroup flush>
            {asset.children.map((asset) => (
              <ListGroupItem
                key={asset.name}
                tag="a"
                className={`indented d-flex justify-content-between ${
                  selectedAsset === asset.name ? "text-white" : "text-black"
                }`}
                onClick={() => filterAssets(asset.name)}
              >
                <span>
                  <span>{asset.name === "Grouped Connection" ? "Connection" : asset.name}</span>
                  <span className="badge badge-primary ml-1" style={{ fontSize: "100%" }}>
                    {asset.count}
                  </span>
                </span>
                <span className="list-group-item-icon">
                  <i
                    className={`icon-${selectedAsset === asset.name ? "check" : "cross"}-solid`}
                  ></i>
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </>
    );
  } else {
    return (
      <ListGroupItem
        key={asset.name}
        tag="a"
        className={`d-flex justify-content-between ${
          selectedAsset === asset.name ? "text-white" : "text-black"
        }`}
        onClick={() => filterAssets(asset.name)}
      >
        <span>
          <span>{asset.name === "Grouped Connection" ? "Connection" : asset.name}</span>
          <span className="badge badge-primary ml-1" style={{ fontSize: "100%" }}>
            {asset.count}
          </span>
        </span>
        <span className="list-group-item-icon">
          <i className={`icon-${selectedAsset === asset.name ? "check" : "cross"}-solid`}></i>
        </span>
      </ListGroupItem>
    );
  }
};

export default GroupAsset;
