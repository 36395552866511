export const CONSUMER_LIST = [
  {
    name: "Domestic Property",
    class: "domestic",
  },
  {
    name: "Small Commercial Property",
    class: "small-commercial",
  },
  {
    name: "Large Commercial Property",
    class: "large-commercial",
  },
  {
    name: "Industrial Property",
    class: "industrial",
  },
  {
    name: "Generator",
    class: "generator",
  },
  {
    name: "EV Chargepoint",
    class: "evChargePoint",
  },
  {
    name: "Heat Pump",
    class: "heatPump",
  },
];
