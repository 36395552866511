import { LatLng } from "../viewModel/simpleTypes";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";
import { Asset } from "./asset";
import { ConsumptionType, IConnectionPointProperties } from "./connectionPointProperties";

// "Container" object - one per grouped connection icon on screen. Has a collection of GroupedConnectionPoints.
export class GroupedConnection extends Asset implements IConnectionPointProperties {
  constructor(
    id: string,
    geometry: LatLng,
    groupedConnectionPoints: GroupedConnectionPoint[] = [],
    styles: any,
    overrideGroundType: string,
  ) {
    super(id);
    this.groupedConnectionPoints = groupedConnectionPoints;
    this.geometry = geometry;
    this.styles = styles;
    this.overrideGroundType = overrideGroundType;
  }

  [key: string]: any;
  annotation!: string;
  geometry: LatLng;
  groupedConnectionPoints: GroupedConnectionPoint[];
  styles: any;
  consumerType!: string;
  autoPhase!: Boolean;
  phaseAutoConsumers!: number;
  phase1Consumers!: number;
  phase2Consumers!: number;
  phase3Consumers!: number;
  balancedLoad!: Boolean;
  unbalancePercent!: number;
  lowLoadedPhase!: string;
  consumptionValue1!: number;
  consumptionValue2!: number;
  consumptionType!: ConsumptionType;
  maxVoltDropPercent!: number | null;
  maxVoltDropPercentPassResult!: Boolean;
  maxVoltRiseTotal!: number | null;
  maxVoltRiseGeneration!: number | null;
  maxVoltRiseUnbalance!: number | null;
  numberOfPhases!: string;
  parentId!: string | null;
  isSubGroupConnection!: Boolean;
  isGenerator!: Boolean;
  earthLoopImpedance!: number;
  threePhaseFaultCurrent!: number;
  phaseToPhaseFaultCurrent!: number;
  singlePhaseToEarthFaultCurrent!: number;
  phaseMap!: [];
  hasFuseWarning!: Boolean;
  flickerPassResult!: Boolean;
  flickerDeltaVoltage!: number;
  flickerP28Limit!: number;
  flickerStartingPassResult!: Boolean;
  flickerStartingDeltaVoltage!: number;
  impedanceLoopOperating!: string;
  impedanceLoopOperatingPassResult!: Boolean;
  impedanceSourceLoopOperating!: string;
  impedanceSourceLoopOperatingPassResult!: Boolean;
  impedanceLoopFault!: string;
  impedancePhaseOperating!: string;
  impedanceSourcePhaseOperating!: string;
  impedanceSourcePhasePhaseOperating!: string;
  impedancePhaseFault!: string;
  resistanceLoopOperating!: string;
  resistanceSourceLoopOperating!: string;
  resistanceLoopFault!: string;
  resistancePhaseOperating!: string;
  resistanceSourcePhaseOperating!: string;
  resistanceSourcePhasePhaseOperating!: string;
  resistancePhaseFault!: string;
  reactanceLoopOperating!: string;
  reactanceSourceLoopOperating!: string;
  reactanceLoopFault!: string;
  reactancePhaseOperating!: string;
  reactanceSourcePhaseOperating!: string;
  reactanceSourcePhasePhaseOperating!: string;
  reactancePhaseFault!: string;
  AllmaxVoltDropPercent!: number | null;
  AllmaxVoltDropPercentPeriod!: number | null;
  AllmaxVoltDropPercentPassResult!: Boolean;
  AllmaxVoltDropPhase!: number | null;
  AllmaxVoltRiseTotal!: number | null;
  AllmaxVoltRiseGeneration!: number | null;
  AllmaxVoltRiseUnbalance!: number | null;
  AllmaxVoltRisePercentPeriod!: number | null;
  AllmaxVoltRisePhase!: number | null;
  AllmaxVoltage!: number | null;
  AllmaxVoltagePhase1!: number | null;
  AllmaxVoltagePhase2!: number | null;
  AllmaxVoltagePhase3!: number | null;
  AllminVoltage!: number | null;
  AllminVoltagePhase1!: number | null;
  AllminVoltagePhase2!: number | null;
  AllminVoltagePhase3!: number | null;
  wintermaxVoltDropPercent!: number | null;
  wintermaxVoltDropPercentPeriod!: number | null;
  wintermaxVoltDropPercentPassResult!: Boolean;
  wintermaxVoltDropPhase!: number | null;
  wintermaxVoltRisePercent!: number | null;
  wintermaxVoltRiseTotal!: number | null;
  wintermaxVoltRiseGeneration!: number | null;
  wintermaxVoltRiseUnbalance!: number | null;
  wintermaxVoltRisePercentPeriod!: number | null;
  wintermaxVoltRisePhase!: number | null;
  wintermaxVoltage!: number | null;
  wintermaxVoltagePhase1!: number | null;
  wintermaxVoltagePhase2!: number | null;
  wintermaxVoltagePhase3!: number | null;
  winterminVoltage!: number | null;
  winterminVoltagePhase1!: number | null;
  winterminVoltagePhase2!: number | null;
  winterminVoltagePhase3!: number | null;
  summermaxVoltDropPercent!: number | null;
  summermaxVoltDropPercentPeriod!: number | null;
  summermaxVoltDropPercentPassResult!: Boolean;
  summermaxVoltDropPhase!: number | null;
  summermaxVoltRisePercent!: number | null;
  summermaxVoltRiseTotal!: number | null;
  summermaxVoltRiseGeneration!: number | null;
  summermaxVoltRiseUnbalance!: number | null;
  summermaxVoltRisePercentPeriod!: number | null;
  summermaxVoltRisePhase!: number | null;
  summermaxVoltage!: number | null;
  summermaxVoltagePhase1!: number | null;
  summermaxVoltagePhase2!: number | null;
  summermaxVoltagePhase3!: number | null;
  summerminVoltage!: number | null;
  summerminVoltagePhase1!: number | null;
  summerminVoltagePhase2!: number | null;
  summerminVoltagePhase3!: number | null;
  phaseImpedance!: number | null;
  loopImpedance!: number | null;
  phaseAllocation!: string;
  nodeNumber!: number;
  status!: string;
  cutOutSize!: string;
  multiOccupancyContainer: any;
  multiOccupancy!: Boolean;
  moSpareWays!: number;
  linkBox: any;
  potEnds: any;
  overrideGroundType: string;
  pole: any;
  fuse: any;
  consumerTypeIsDefault!: Boolean;
  consumptionValue1IsDefault!: Boolean;
  consumptionValue2IsDefault!: Boolean;
  consumptionTypeIsDefault!: Boolean;
  numberOfPhasesIsDefault!: Boolean;
  groupId!: string | null;
  rootTransformerId!: string;
  substationId!: string;
  feederNumber!: string;
  classIsDefault!: Boolean;
  MPAN!: string;
}
