import {
  updateCable,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateGroupedConnectionPole,
} from "../../app/networkSlice";
import { AppDispatch } from "../../app/store";
import { transformerTypes } from "../../constants/groupUpdateTypes";
import * as statusConstants from "../../constants/status";
import { ClientSettings } from "../../model/reference/settingsTypes";
import { GroupedConnection } from "../../model/viewModel/groupedConnection";
import { GroupedConnectionPoint } from "../../model/viewModel/groupedConnectionPoint";
import { RingfencedElement } from "../../model/viewModel/ringfencedElement";

export const shouldShowLinkBoxStatusForm = (
  clientSettings: ClientSettings,
  selected: string,
  ringfencedFiltered: RingfencedElement[],
): boolean => {
  if (!clientSettings.features.ConfigurableLinkBoxEnabled) {
    return false;
  }
  const shouldShow = (type: string) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.linkBox);
};

export const shouldShowPotEndStatusForm = (
  clientSettings: ClientSettings,
  selected: string,
  ringfencedFiltered: RingfencedElement[],
): boolean => {
  if (!clientSettings.features.CostingEnabled) {
    return false;
  }
  const shouldShow = (type: string) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.potEnds?.length > 0);
};

export const shouldShowPoleStatusForm = (
  clientSettings: ClientSettings,
  selected: string,
  ringfencedFiltered: RingfencedElement[],
): boolean => {
  if (!clientSettings.features.CostingEnabled) {
    return false;
  }
  const shouldShow = (type: string) => {
    switch (type) {
      case "Nodes":
      case "Node":
      case "Service End Node":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    return false;
  }
  return shouldShow(selected) && ringfencedFiltered.some((p) => p.pole?.enabled);
};

export const shouldShowStatusForm = (
  clientSettings: ClientSettings,
  selected: string,
  ringfencedTypes: RingfencedElement[],
): boolean => {
  if (!clientSettings.features.NewExistingStatusEnabled) {
    return false;
  }
  const shouldShow = (type: string) => {
    switch (type) {
      case "Properties":
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Conductors":
      case "Mains Overhead Line":
      case "Mains Underground Cable":
      case "Service Overhead Line":
      case "Service Underground Cable":
      case "LCT":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Connection":
      case "Grouped Connection":
        return true;
      default:
        return false;
    }
  };

  if (selected === "All") {
    const isNotAllowed = ringfencedTypes.some(
      (p) =>
        !shouldShow(p.name) &&
        !["Node", "Service End Node"].includes(p.name) &&
        !transformerTypes.includes(p.name),
    );
    return !isNotAllowed;
  }
  return shouldShow(selected);
};

export const reviewStatusChange = (
  ringfencedFiltered: RingfencedElement[],
  cableType: string | undefined,
  status: string | undefined,
  ohWhitelist: string[],
  ugWhitelist: string[],
): { name: string; count: number }[] => {
  const warnings: { [key: string]: number } = {};
  if (cableType) {
    return [];
  }
  ringfencedFiltered.forEach((p) => {
    switch (p.styles.name) {
      case "Overhead Line":
      case "Underground Cable":
        if (status === statusConstants.NEW || status === statusConstants.REPLACEMENT) {
          const whitelist = p.cableGroup.split("-")[1] === "overhead" ? ohWhitelist : ugWhitelist;
          if (!whitelist.includes(p.cableType) && p.cableType !== "auto") {
            const message = `The cable type for {0} ${
              p.cableGroup.split("-")[1]
            } cable{1} will be set to Auto`;
            warnings[message] = (warnings[message] || 0) + 1;
          }
        }
        break;
    }
  });
  return Object.entries(warnings).map(([name, count]) => ({ name, count }));
};

export const applyStatusChange = (
  ringfencedFiltered: RingfencedElement[],
  dispatchRedux: AppDispatch,
  status: string,
  ohWhitelist: string[],
  ugWhitelist: string[],
  groupedConnections: GroupedConnection[],
): void => {
  ringfencedFiltered.forEach((p) => {
    if (transformerTypes.includes(p.styles.name)) {
      return;
    }

    const doUpdate = (point: GroupedConnectionPoint) => {
      if (point.status !== status) {
        dispatchRedux(
          updateGroupedConnectionChild({
            id: p.id,
            childUpdate: { id: point.id, name: "status", value: status },
            isGroupUpdate: false,
            parentId: null,
          }),
        );
      }
    };

    if (p.groupedConnectionPoints?.length > 0) {
      if (p.ringfencedPoint) {
        doUpdate(p.ringfencedPoint);
      } else {
        p.groupedConnectionPoints.forEach((q) => {
          doUpdate(q);
        });
      }
    }

    const update = {
      id: p.id,
      name: "status",
      value: status,
    };

    switch (p.styles.name) {
      case "Overhead Line":
      case "Underground Cable":
        dispatchRedux(updateCable(update));

        if (p.cableGroup === "mains-overhead") {
          const connectedPoles = groupedConnections.filter(
            (gc) => (gc.id === p.startAssetId || gc.id === p.endAssetId) && gc.pole,
          );

          connectedPoles.forEach((connectedPole) => {
            dispatchRedux(
              updateGroupedConnectionPole({
                id: connectedPole.id,
                name: "status",
                value: status,
              }),
            );
          });
        }

        if (status === statusConstants.NEW || status === statusConstants.REPLACEMENT) {
          const whitelist = p.cableGroup.split("-")[1] === "overhead" ? ohWhitelist : ugWhitelist;
          if (!whitelist.includes(p.cableType)) {
            dispatchRedux(updateCable({ id: p.id, name: "autoSelect", value: true }));
            dispatchRedux(updateCable({ id: p.id, name: "cableType", value: "auto" }));
          }
        }
        break;
      case "Domestic Property":
      case "Small Commercial Property":
      case "Large Commercial Property":
      case "Industrial Property":
      case "Generator":
      case "Heat Pump":
      case "EV Chargepoint":
      case "Grouped Connection":
        dispatchRedux(updateGroupedConnection(update));
        break;
      case "Node":
      case "Service End Node":
        if (p.pole && p.pole !== status) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "pole",
              value: { ...p.pole, status },
            }),
          );
        }
        if (p.potEnds && p.potEnds.some((q: any) => q.status !== status)) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "potEnds",
              value: p.potEnds.map((q: any) => ({ ...q, status })),
            }),
          );
        }
        if (p.linkBox && p.linkBox !== status) {
          dispatchRedux(
            updateGroupedConnection({
              id: p.id,
              name: "linkBox",
              value: { ...p.linkBox, status },
            }),
          );
        }
        break;
    }
  });
};
