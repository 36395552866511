// @flow
import { v4 as uuid } from "uuid"; // generates GUIDs
import NameValueProperty from "./nameValueProperty";
import PropertyContainer from "./propertyContainer";

interface ITerminal {
  id: string;
  conductingEquipmentId: string;
  connectivityNodeId: string;
  properties: NameValueProperty[];
}

export default class Terminal extends PropertyContainer implements ITerminal {
  constructor(
    conductingEquipmentId: string,
    connectivityNodeId: string,
    properties: ?(NameValueProperty[]),
  ) {
    super(properties);
    this.id = uuid();
    this.conductingEquipmentId = conductingEquipmentId;
    this.connectivityNodeId = connectivityNodeId;
  }

  id: string;
  conductingEquipmentId: string;
  connectivityNodeId: string;
  properties: NameValueProperty[];
}
