import { Fragment, useEffect, useState } from "react";
import { CircleMarker, Marker, Polygon, Polyline, useMap, useMapEvents } from "react-leaflet";
import { useMsal } from "@azure/msal-react";
import { getGeometry } from "../services/apiService";
import { convertFeatures } from "../utils/coordsToLatLngs";
import useBearerToken from "../utils/useBearerToken";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";

const GeometryLoader = () => {
  const map = useMap();
  const getToken = useBearerToken();
  const { instance, accounts } = useMsal();

  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    loadAssetData();
  }, []);

  useMapEvents({
    moveend() {
      loadAssetData();
    },
  });

  const loadAssetData = async () => {
    const token = await getToken(instance, accounts);
    getGeometry(token, map.getBounds())
      .then((result) => {
        const features = convertFeatures(result.data.features);
        setData(features);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const styleHeaderText = (properties: any) => {
    const badge = L.divIcon({
      className: "",
      html: ReactDOMServer.renderToString(
        <div
          className="badge p-0"
          style={{
            fontWeight: 700,
            lineHeight: 1,
            whiteSpace: "nowrap",
            color: properties.bgColor,
            fontSize: `${map.getZoom()}px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {properties.headertext}
        </div>,
      ),
    });
    return badge;
  };

  const headerPosition = (coords: any) => {
    return L.polygon(coords).getBounds().getCenter();
  };

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((layer) => (
          <Fragment key={layer.id}>
            {["Polygon", "MultiPolygon"].includes(layer.geometry.type) && (
              <>
                <Marker
                  position={headerPosition(layer.geometry.coordinates)}
                  icon={styleHeaderText(layer.properties)}
                  zIndexOffset={9999}
                />
                <Polygon
                  positions={layer.geometry.coordinates}
                  pathOptions={{
                    fillColor: layer.properties.bgColor,
                    color: layer.properties.color,
                    opacity: layer.properties.bgColorOpacity,
                  }}
                />
              </>
            )}
            {["LineString", "MultiLineString"].includes(layer.geometry.type) && (
              <>
                <Marker
                  position={headerPosition(layer.geometry.coordinates)}
                  icon={styleHeaderText(layer.properties)}
                  zIndexOffset={9999}
                />
                <Polyline
                  positions={layer.geometry.coordinates}
                  pathOptions={{
                    fillColor: layer.properties.bgColor,
                    color: layer.properties.color,
                    opacity: layer.properties.bgColorOpacity,
                  }}
                />
              </>
            )}
            {layer.geometry.type === "Marker" && (
              <CircleMarker
                center={layer.geometry.coordinates}
                pathOptions={{
                  fillColor: layer.properties.bgColor,
                  color: layer.properties.color,
                  opacity: layer.properties.bgColorOpacity,
                }}
                radius={layer.properties.radius}
              />
            )}
          </Fragment>
        ))}
    </>
  );
};

export default GeometryLoader;
