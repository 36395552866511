import { useMap } from "react-leaflet";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { centreNetworkOnMap } from "../utils/networkFunctions";

const CenterMapControl = ({ network, allGeometries }) => {
  const map = useMap();

  return (
    <Button
      color="dark"
      className="mb-2"
      onClick={() => centreNetworkOnMap(network, allGeometries, map)}
      disabled={allGeometries.length < 1}
    >
      <FontAwesomeIcon icon={faCrosshairs} fixedWidth />
    </Button>
  );
};

export default CenterMapControl;
