// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { RagTransformer, RagTransformerProperties } from "./ragTransformer";
import { getLatLngFromWktPoint } from "./geometryWktConverter";
import { v4 as uuid } from "uuid";

export function createRagTransformerFromConductingEquipment(
  conductingEquipment: ConductingEquipment,
): RagTransformer {
  const transformerProperties = new RagTransformerProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  transformerProperties.geometry = getLatLngFromWktPoint(geometryString);

  transformerProperties.styles = getAssetStyleInfo(conductingEquipment.getPropertyValue("class"));
  transformerProperties.rag = conductingEquipment.getPropertyValue("rag");

  return new RagTransformer(conductingEquipment.id, transformerProperties);
}

export function createRagTransformerFromSubstation(substation: any) {
  const transformerProperties = new RagTransformerProperties();
  transformerProperties.geometry = substation.position;
  transformerProperties.styles = getAssetStyleInfo("substation-gmt");
  transformerProperties.rag = "grey";

  return new RagTransformer("PT1-" + uuid(), transformerProperties);
}
