import { useEffect } from "react";
import { useSelector } from "react-redux";
import { checkReduxNetworkPopulated } from "../app/networkSlice";

const ExitMessage = () => {
  const networkChanged = useSelector((state) => state.network.present.networkChanged);
  const isPopulated = useSelector((state) => checkReduxNetworkPopulated(state));

  const checkUnsavedNetwork = (e) => {
    e.preventDefault();

    if (networkChanged && isPopulated) {
      var confirmationMessage =
        "It looks like you have been editing something. " +
        "If you leave before saving, your changes will be lost.";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    } else {
      return void 0;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", checkUnsavedNetwork);

    return function cleanup() {
      window.removeEventListener("beforeunload", checkUnsavedNetwork);
    };
  }, [networkChanged, isPopulated]);
  return null;
};

export default ExitMessage;
