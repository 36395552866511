import {
  updateCable,
  updateCableChild,
  updateGroupedConnection,
  updateGroupedConnectionChild,
  updateTransformer,
  updateTransformerChild,
} from "../app/networkSlice";
import { Cable } from "../model/viewModel/cable";
import { Transformer } from "../model/viewModel/transformer";
import * as CableTypes from "../constants/cable";
import { HasGroupedConnectionPoints } from "../model/viewModel/hasGroupedConnectionPoints";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import { Reference, TransformerTypeReference } from "../model/reference/referenceTypes";

export interface UpgradeMessage {
  code: string;
  message: string;
}

export interface CableTypeConfiguration {
  isMains: Boolean;
  isService: Boolean;
  isOverhead: Boolean;
  isUnderground: Boolean;
}

const toInt = (value: any) => {
  if (typeof value === "string") {
    return parseInt(value);
  }
  return value;
};

export const upgradeTransformers = (
  reference: Reference,
  transformers: Transformer[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Transformer";

  const getSizes = (isGMT: Boolean, numberOfPhases: string): TransformerTypeReference[] => {
    const sizeList = reference.transformers.types.filter(
      (f) =>
        (isGMT && f[numberOfPhases + "phase"].gmt) || (!isGMT && f[numberOfPhases + "phase"].pmt),
    );
    return sizeList;
  };

  transformers.forEach((transformer) => {
    const mounting = transformer.mounting.toLowerCase();
    const numberOfPhases = transformer.numberOfPhases.toLowerCase();

    if (!["gmt", "pmt"].includes(mounting)) {
      return;
    }
    if (!["single", "split", "three"].includes(numberOfPhases)) {
      return;
    }
    const isGMT = mounting === "gmt";

    const sizes = getSizes(isGMT, numberOfPhases);

    if (!sizes.some((size) => size.rating === toInt(transformer.nameplateRating))) {
      messages.push({
        code: messageCode,
        message:
          "Transformer has a size that doesn't exist for its mounting, so it has been set to the default",
      });

      const defaultSize = isGMT
        ? reference.transformers.defaults.gmt
        : reference.transformers.defaults.pmt;

      dispatchRedux(
        updateTransformer({
          id: transformer.id,
          name: "nameplateRating",
          value: defaultSize.toString(),
        }),
      );
      dispatchRedux(
        updateTransformer({ id: transformer.id, name: "nameplateRatingIsDefault", value: true }),
      );
    }
  });

  return messages;
};

export const upgradeCableTypes = (
  reference: Reference,
  cables: Cable[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Cable";

  const getCableNames = (cableType: CableTypeConfiguration): string[] => {
    const sizeList = reference.cables.types
      .filter((p) => !cableType.isOverhead || p.overhead)
      .filter((p) => !cableType.isUnderground || p.underground)
      .filter((p) => !cableType.isMains || p.mains)
      .filter((p) => !cableType.isService || p.service)
      .map((p) => p.name.toLowerCase());
    return sizeList;
  };

  const getCableType = (cableGroup: string): CableTypeConfiguration => {
    switch (cableGroup) {
      case CableTypes.GROUP_MAINS_UG:
        return {
          isOverhead: false,
          isUnderground: true,
          isMains: true,
          isService: false,
        };
      case CableTypes.GROUP_MAINS_OH:
        return {
          isOverhead: true,
          isUnderground: false,
          isMains: true,
          isService: false,
        };
      case CableTypes.GROUP_SERVICE_UG:
        return {
          isOverhead: false,
          isUnderground: true,
          isMains: false,
          isService: true,
        };
      case CableTypes.GROUP_SERVICE_OH:
        return {
          isOverhead: true,
          isUnderground: false,
          isMains: false,
          isService: true,
        };
      default:
        return {
          isOverhead: false,
          isUnderground: false,
          isMains: false,
          isService: false,
        };
    }
  };

  cables.forEach((c) => {
    const name = c.cableType.toLowerCase();
    const cableGroup = c.cableGroup.toLowerCase();

    const cableTypeConfiguration: CableTypeConfiguration = getCableType(cableGroup);

    const unknownCableType = getCableNames(cableTypeConfiguration).includes(name);

    if (!unknownCableType) {
      messages.push({
        code: messageCode,
        message: "Cable has a type that doesn't exist, so it has been set to the default",
      });

      let defaultType = "";
      if (cableTypeConfiguration.isMains) {
        if (cableTypeConfiguration.isUnderground) {
          defaultType = CableTypes.GROUP_MAINS_UG;
        } else {
          defaultType = CableTypes.GROUP_MAINS_OH;
        }
      } else {
        if (cableTypeConfiguration.isUnderground) {
          defaultType = CableTypes.GROUP_SERVICE_UG;
        } else {
          defaultType = CableTypes.GROUP_SERVICE_OH;
        }
      }

      dispatchRedux(
        updateCable({ id: c.id, name: "cableType", value: reference.cables.defaults[defaultType] }),
      );
      dispatchRedux(updateCable({ id: c.id, name: "cableTypeIsDefault", value: true }));
    }
  });

  return messages;
};

export const upgradeConsumers = (
  reference: Reference,
  groupedConnections: HasGroupedConnectionPoints[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Consumer";

  groupedConnections.forEach((c) => {
    c.groupedConnectionPoints.forEach((consumer) => {
      const consumerType = consumer.consumerType.toLowerCase(); // e.g., Elexon 6
      const consumptionType = consumer.consumptionType; // e.g., MaximumDemandDiversified
      const consumerClass = consumer.styles.class; // e.g., large-commercial

      if (!reference.consumers.defaults[consumerClass]) {
        return;
      }

      const allowedConsumerTypes = reference.consumers.types.filter((p) => p[consumerClass]);

      if (!allowedConsumerTypes.some((p) => p.name.toLowerCase() === consumerType)) {
        messages.push({
          code: messageCode,
          message: "Consumer has a profile that doesn't exist, so it has been set to the default",
        });

        let updateChild;
        if (c.styles.type === "transformer") {
          updateChild = updateTransformerChild;
        } else if (c.styles.type === "cable") {
          updateChild = updateCableChild;
        } else {
          updateChild = updateGroupedConnectionChild;
        }

        const defaultConsumerType = reference.consumers.defaults[consumerClass].loadprofile;
        dispatchRedux(
          updateChild({
            id: c.id,
            isGroupUpdate: false,
            childUpdate: { id: consumer.id, name: "consumerType", value: defaultConsumerType },
            parentId: null,
          }),
        );
        dispatchRedux(
          updateChild({
            id: c.id,
            isGroupUpdate: false,
            childUpdate: { id: consumer.id, name: "consumerTypeIsDefault", value: true },
            parentId: null,
          }),
        );

        const defaultConsumerTypeValues = reference.consumers.types.find(
          (p) => p.name === defaultConsumerType,
        );

        if (defaultConsumerTypeValues) {
          const defaultConsumptionValue1 =
            defaultConsumerTypeValues[`default${consumptionType}Day`];
          const defaultConsumptionValue2 =
            defaultConsumerTypeValues[`default${consumptionType}Night`];

          dispatchRedux(
            updateChild({
              id: c.id,
              isGroupUpdate: false,
              childUpdate: {
                id: consumer.id,
                name: "consumptionValue1",
                value: defaultConsumptionValue1,
              },
              parentId: null,
            }),
          );
          dispatchRedux(
            updateChild({
              id: c.id,
              isGroupUpdate: false,
              childUpdate: { id: consumer.id, name: "consumptionValue1IsDefault", value: true },
              parentId: null,
            }),
          );

          dispatchRedux(
            updateChild({
              id: c.id,
              isGroupUpdate: false,
              childUpdate: {
                id: consumer.id,
                name: "consumptionValue2",
                value: defaultConsumptionValue2,
              },
              parentId: null,
            }),
          );
          dispatchRedux(
            updateChild({
              id: c.id,
              isGroupUpdate: false,
              childUpdate: { id: consumer.id, name: "consumptionValue2IsDefault", value: true },
              parentId: null,
            }),
          );
        }
      }
    });
  });

  return messages;
};

export const upgradeGroundTypes = (
  reference: Reference,
  cables: Cable[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Ground type";

  const availableGroundTypes = reference.groundTypeOverrides.split(",");

  cables.forEach((c) => {
    const groundTypes = [...(c.groundTypeOverrides ?? [])];
    let hasAny = false;
    let defaultPosition = groundTypes.findIndex(
      (p) => p.groundType === reference.groundTypeOverrideDefaults.groundTypeOverride,
    );
    const ignoreIndexes: number[] = [];

    groundTypes.forEach((g, i) => {
      if (!availableGroundTypes.includes(g.groundType)) {
        hasAny = true;

        if (defaultPosition === -1) {
          groundTypes[i] = {
            ...g,
            groundType: reference.groundTypeOverrideDefaults.groundTypeOverride,
          };
          defaultPosition = i;
        } else {
          groundTypes[defaultPosition] = {
            ...groundTypes[defaultPosition],
            length: parseFloat(groundTypes[defaultPosition].length) + parseFloat(g.length),
          };
          ignoreIndexes.push(i);
        }
      }
    });

    if (hasAny) {
      const reducedList = groundTypes.filter((p, i) => !ignoreIndexes.includes(i));

      dispatchRedux(
        updateCable({
          id: c.id,
          name: "groundTypeOverrides",
          value: reducedList,
        }),
      );
      dispatchRedux(
        updateCable({
          id: c.id,
          name: "groundTypeOverridesIsDefault",
          value: true,
        }),
      );

      messages.push({
        code: messageCode,
        message: "Cable has a ground type that doesn't exist, so it has been set to the default",
      });
    }
  });

  return messages;
};

export const upgradeFeederFuses = (
  reference: Reference,
  cables: Cable[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Feeder Fuse";

  cables.forEach((c) => {
    if (c.feederFuseSize && !reference.fuses.ratings.includes(toInt(c.feederFuseSize))) {
      messages.push({
        code: messageCode,
        message: "Feeder fuse has a size that doesn't exist, so it has been set to the default",
      });

      dispatchRedux(
        updateCable({
          id: c.id,
          name: "feederFuseSize",
          value: "No rating",
        }),
      );
      dispatchRedux(
        updateCable({
          id: c.id,
          name: "feederFuseSizeIsDefault",
          value: true,
        }),
      );
    }
  });

  return messages;
};

export const upgradeNodeFuses = (
  reference: Reference,
  nodes: GroupedConnection[],
  dispatchRedux: any,
): UpgradeMessage[] => {
  const messages: UpgradeMessage[] = [];

  const messageCode = "UPGRADE: Node Fuse";

  nodes.forEach((n) => {
    if (n.fuse && !reference.fuses.ratings.includes(toInt(n.fuse))) {
      messages.push({
        code: messageCode,
        message: "Node fuse has a size that doesn't exist, so it has been set to the default",
      });

      dispatchRedux(
        updateGroupedConnection({
          id: n.id,
          name: "fuse",
          value: "No rating",
        }),
      );

      dispatchRedux(
        updateGroupedConnection({
          id: n.id,
          name: "fuseIsDefault",
          value: true,
        }),
      );
    }
  });

  return messages;
};
