export const formReducer = (state, action) => {
  if (action.type === "CLEAR_NETWORK") {
    const { form, obj } = action;

    return {
      ...state,
      [form]: obj,
      networkUnsaved: false,
    };
  }
  if (action.type === "SAVE_NETWORK") {
    return {
      ...state,
      networkUnsaved: false,
    };
  }

  if (action.type === "REPLACE_STATE4") {
    const { form, obj } = action;

    return {
      ...state,
      [form]: obj,
      networkUnsaved: true,
    };
  }

  if (action.type === "UPDATE_FIELD2") {
    const { form, field, id, parentId, value } = action;

    const index = state[form]["groupedConnectionPoints"].findIndex(
      (item) => item.id === (parentId || id),
    );

    let path = state[form]["groupedConnectionPoints"][index];
    let _state = { ...state };

    let groupedConnectionPoints = [..._state[form].groupedConnectionPoints].map(
      (groupedConnectionPoint) => {
        return { ...groupedConnectionPoint };
      },
    );

    let subGroupConnectionPoints = [
      ...(groupedConnectionPoints[index].subGroupConnectionPoints ?? []),
    ].map((subGroupConnection) => {
      return { ...subGroupConnection };
    });

    if (parentId) {
      const index2 = path["subGroupConnectionPoints"].findIndex((item) => item.id === id);

      subGroupConnectionPoints[index2][field] = value;
    } else {
      groupedConnectionPoints[index][field] = value;

      //subGroupConnectionPoints.forEach((sgc) => {
      //  sgc[field] = value;
      //});
    }

    groupedConnectionPoints[index].subGroupConnectionPoints = subGroupConnectionPoints;

    _state[form].groupedConnectionPoints = groupedConnectionPoints;

    _state.networkUnsaved = true;

    return _state;
  }
  if (action.type === "UPDATE_FIELD") {
    const { form, field, value } = action;
    return {
      ...state,
      [form]: { ...state[form], [field]: value },
      networkUnsaved: true,
    };
  }
  if (action.type === "REPLACE_STATE") {
    const { form, obj } = action;

    const _state = { ...state };
    _state[form] = { ...obj };
    _state.networkUnsaved = form === "reference" || form === "clientSettings" ? false : true;
    return _state;
  }

  if (action.type === "ADD_ITEM") {
    const { form, id, obj } = action;
    const state_ = {
      ...state,
      network: {
        ...state.network,
      },
    };

    if (id) {
      const groupedConnectionPointIndex = state_[form]["groupedConnectionPoints"].findIndex(
        (item) => item.id === id,
      );

      const subGroupConnectionPoints =
        state_[form]["groupedConnectionPoints"][groupedConnectionPointIndex][
          "subGroupConnectionPoints"
        ];

      const subGroupConnectionPointIndex = subGroupConnectionPoints.findIndex(
        (p) => p.id === obj.id,
      );

      if (subGroupConnectionPointIndex === -1) {
        subGroupConnectionPoints.push(obj);
      }
    } else {
      if (!state_[form]["groupedConnectionPoints"]) {
        state_[form]["groupedConnectionPoints"] = [obj];
      } else {
        const groupedConnectionPointIndex = state_[form]["groupedConnectionPoints"].findIndex(
          (item) => item.id === obj.id,
        );

        if (groupedConnectionPointIndex === -1) {
          state_[form]["groupedConnectionPoints"] = [
            ...state_[form]["groupedConnectionPoints"],
            obj,
          ];
        }
      }
    }

    state_.networkUnsaved = true;
    state_.network.existing = false;

    return state_;
  }
  if (action.type === "REMOVE_ITEM") {
    const { form, childConsumerId, consumerId } = action;
    const state_ = { ...state };

    if (childConsumerId) {
      const index = state_[form]["groupedConnectionPoints"].findIndex(
        (item) => item.id === consumerId,
      );

      state_[form]["groupedConnectionPoints"][index]["subGroupConnectionPoints"] = state_[form][
        "groupedConnectionPoints"
      ][index]["subGroupConnectionPoints"].filter((item) => item.id !== childConsumerId);
    } else {
      state_[form]["groupedConnectionPoints"] = state_[form]["groupedConnectionPoints"].filter(
        (item) => item.id !== consumerId,
      );
    }

    state_.networkUnsaved = true;
    state_.network.existing = false;

    return state_;
  }
};
