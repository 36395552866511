import { Button, ButtonGroup, FormGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const CheckboxBtnGrp = ({
  items,
  balancedLoad,
  changeValue,
  selectedValue,
  handleBalancedLoad,
}) => {
  return items[0].phases.includes("Three") ? (
    <FormGroup>
      <Button
        color="dark"
        block
        onClick={() => handleBalancedLoad()}
        className={balancedLoad ? "active" : ""}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span>Balanced Connection</span>
          <FontAwesomeIcon icon={balancedLoad ? faCheck : faTimes} />
        </div>
      </Button>
    </FormGroup>
  ) : (
    <ButtonGroup className="w-100">
      {items.map((item, i) => (
        <Button
          key={item.value}
          color="dark"
          onClick={() => changeValue(item)}
          active={JSON.stringify(selectedValue) === JSON.stringify(item.field)}
        >
          {item.value}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CheckboxBtnGrp;
