import * as phase from "../constants/phase";
import { GroupedConnectionPoint } from "../model/viewModel/groupedConnectionPoint";

export const setPhaseCount = (consumer: GroupedConnectionPoint) => {
  let autoCount = consumer.phaseAutoConsumers,
    phase1Count = consumer.phase1Consumers,
    phase2Count = consumer.phase2Consumers,
    phase3Count = consumer.phase3Consumers,
    resetAssignedPhases = false;

  const newConsumerCount = consumer.count;

  if (consumer.numberOfPhases === phase.THREE) {
    autoCount = 0;
    phase1Count = newConsumerCount;
    phase2Count = newConsumerCount;
    phase3Count = newConsumerCount;
  } else {
    const consumerCount = autoCount + phase1Count + phase2Count + phase3Count;

    if (newConsumerCount > consumerCount) {
      autoCount = newConsumerCount - (phase1Count + phase2Count + phase3Count);
    } else {
      autoCount = newConsumerCount;
      phase1Count = 0;
      phase2Count = 0;
      phase3Count = 0;
      resetAssignedPhases = true;
    }
  }
  return { autoCount, phase1Count, phase2Count, phase3Count, resetAssignedPhases };
};

export const getAssignedPhasesArray = (consumer: GroupedConnectionPoint) => {
  let assignedPhasesArray = [0, 0, 0, 0];

  consumer.phaseMap.forEach((connection, i) => {
    if (consumer.numberOfPhases === phase.SINGLE) {
      assignedPhasesArray[i] += connection;
    }
    if (consumer.numberOfPhases === phase.TWO) {
      if (i === 0) {
        assignedPhasesArray[0] += connection;
      }
      if (i === 1) {
        assignedPhasesArray[1] += connection;
        assignedPhasesArray[2] += connection;
      }
      if (i === 2) {
        assignedPhasesArray[1] += connection;
        assignedPhasesArray[3] += connection;
      }
      if (i === 3) {
        assignedPhasesArray[2] += connection;
        assignedPhasesArray[3] += connection;
      }
    }
  });
  return assignedPhasesArray;
};
