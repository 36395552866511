import React from "react";

const IconRadioGrp = ({ array, value, setValue }) => {
  const itemSelected = (e, item) => {
    e.stopPropagation();
    setValue({ season: item });
  };

  return (
    <span className="ml-2">
      {array.map((item) => (
        <i
          key={item.name}
          className={`${
            value === item.name ? `text-${item.color}` : "text-black"
          } icon-${item.icon}-solid mr-1`}
          onClick={(e) => itemSelected(e, item.name)}
        ></i>
      ))}
    </span>
  );
};

export default IconRadioGrp;
