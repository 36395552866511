import React from "react";
import "./Spinner.css";

const Spinner = ({ children }) => (
  <>
    <div className="preloader fade show">
      <div className="d-flex flex-column align-items-center justify-content-center center-all">
        <div className="row">
          <div className="spinner-border" style={{ borderWidth: "0.08em" }} role="status">
            <span className="sr-only"></span>
          </div>
        </div>
        <div className="row mt-3 font-weight-bold">
          {children === "Loading..." ? <h6>{children}</h6> : <h6>{children}, please wait...</h6>}
        </div>
      </div>
    </div>
    <div className="preloader-backdrop fade show"></div>
  </>
);

export default Spinner;
