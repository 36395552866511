import { lineString, nearestPointOnLine, point } from "@turf/turf";
import { LatLng } from "leaflet";

interface GeometryLine {
  geometry: GeometryPoint[];
}
interface GeometryPoint {
  lat: number;
  lng: number;
}

export const snapToLine = (asset: GeometryLine, e: GeometryPoint) => {
  const points = asset.geometry.map((a) => [a.lat, a.lng]);

  if (points.length < 2) {
    return undefined;
  }

  const line = lineString(points);
  const newPoint = point([e.lat, e.lng]);
  const snapped = nearestPointOnLine(line, newPoint);
  return new LatLng(snapped.geometry.coordinates[0], snapped.geometry.coordinates[1]);
};
