import { useState, useContext } from "react";
import { Pane, useMap } from "react-leaflet";
import * as L from "leaflet";
import Asset from "./Asset";
import { useSelector } from "react-redux";
import {
  findAll,
  updateConnectionPoint,
  updateGroupedConnection,
  updateMotor,
  updateNode,
  updatePointOfConnection,
  updateTransformer,
  updateWelder,
} from "../app/networkSlice";
import { FormContext } from "../context/FormContext";
import ResultMarker from "./ResultMarker";

const Network = ({
  networkId,
  handleSelect,
  clickedAsset,
  handleMove,
  reshapeCablePoints,
  renderer,
  fallbackRenderer,
}) => {
  const map = useMap();
  const bounds = map.getBounds();
  const mapZoom = map.getZoom();
  const iconSize = useSelector(({ settings }) => settings.iconSize);
  const [isDragging, setIsDragging] = useState(false);
  const { formState } = useContext(FormContext);
  const { clientSettings } = formState;

  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);

  const isNode = (groupedConnection) => {
    return !groupedConnection.groupedConnectionPoints.length && !groupedConnection.linkBox;
  };

  const circleOptions = {
    color: "red",
    fillColor: "red",
    fillOpacity: "1",
  };

  const markerDiameterZoomFilter = [
    135.06, 67.53, 33.76, 16.88, 8.44, 4.31, 2.15, 1.05, 0.53, 0.27, 0.14, 0.07, 0.032,
  ]
    .map((el, k) => ({
      diameter: el,
      zoom: k + parseInt(clientSettings.map.minZoomLevel),
    }))
    .reduce(
      (a, b) => ({
        ...a,
        [b.zoom]: b.diameter,
      }),
      {},
    );

  const propertyIdList = {};
  const nodeIdList = {};

  const newList = groupedConnections.reduce(
    (acc, { groupedConnectionPoints, id, geometry }) => ({
      ...acc,
      [groupedConnectionPoints.length ? "property" : "node"]: [
        ...acc[groupedConnectionPoints.length ? "property" : "node"],
        { id, geometry },
      ],
    }),
    { property: [], node: [] },
  );

  const filterPropertiesAndNodes = (arr, type) => {
    arr.forEach((el) => {
      if (
        (bounds.contains(el.geometry) && type === "property" && propertyIdList[el.id] !== -1) ||
        (type === "node" && nodeIdList[el.id] !== -1)
      ) {
        const newCircle = L.circle(
          [el.geometry.lat, el.geometry.lng],
          markerDiameterZoomFilter[mapZoom] / (type === "property" ? 10 / iconSize : 6.75),
          circleOptions,
        );

        newCircle.addTo(map);

        if (type === "property") {
          propertyIdList[el.id] = 1;
        } else {
          nodeIdList[el.id] = 1;
        }

        if (type === "property") {
          newList.property.forEach(({ geometry, id }) => {
            if (el.id !== id && newCircle.getBounds().contains(geometry)) {
              propertyIdList[id] = -1;
              propertyIdList[el.id] = propertyIdList[el.id] + 1;
            }
          });
        }

        newList.node.forEach(({ geometry, id }) => {
          if (el.id !== id && newCircle.getBounds().contains(geometry)) {
            nodeIdList[id] = -1;
          }
        });
        map.removeLayer(newCircle);
      } else {
        if (type === "property") {
          propertyIdList[el.id] = -1;
        } else {
          nodeIdList[el.id] = -1;
        }
      }
    });
  };

  ["property", "node"].forEach((el) => filterPropertiesAndNodes(newList[el], el));

  const displayOnMapList = {
    propertyFilteredList: [],
    nodeFilteredList: [],
  };

  groupedConnections.forEach((el) => {
    if (typeof propertyIdList[el.id] === "number" && propertyIdList[el.id] > -1) {
      displayOnMapList.propertyFilteredList.push({
        ...el,
        count: propertyIdList[el.id],
      });
    } else if (typeof nodeIdList[el.id] === "number" && nodeIdList[el.id] > -1) {
      displayOnMapList.nodeFilteredList.push(el);
    }
  });

  return (
    <>
      <Pane name="network-transformers" style={{ zIndex: "590" }}>
        {useSelector((state) => state.network.present.transformers).map((transformer) => (
          <Asset
            key={transformer.id}
            networkId={networkId}
            asset={transformer}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateTransformer)}
            highlighted={clickedAsset && transformer.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
          ></Asset>
        ))}
      </Pane>
      <Pane name="network-connection-points" style={{ zIndex: "570" }}>
        {useSelector((state) => state.network.present.connectionPoints).map((connectionPoint) => (
          <Asset
            key={connectionPoint.id}
            networkId={networkId}
            asset={connectionPoint}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateConnectionPoint)}
            highlighted={clickedAsset && connectionPoint.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.motors).map((motor) => (
          <Asset
            key={motor.id}
            networkId={networkId}
            asset={motor}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateMotor)}
            highlighted={clickedAsset && motor.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.welders).map((welder) => (
          <Asset
            key={welder.id}
            networkId={networkId}
            asset={welder}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateWelder)}
            highlighted={clickedAsset && welder.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
          ></Asset>
        ))}
        {useSelector((state) => state.network.present.pointOfConnections).map(
          (pointOfConnection) => (
            <Asset
              key={pointOfConnection.id}
              networkId={networkId}
              asset={pointOfConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updatePointOfConnection)}
              highlighted={clickedAsset && pointOfConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
            ></Asset>
          ),
        )}
        {groupedConnections
          .filter((gc) => !isNode(gc))
          .map((groupedConnection) => (
            <Asset
              key={groupedConnection.id}
              label={groupedConnection.count}
              networkId={networkId}
              asset={groupedConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updateGroupedConnection)}
              highlighted={clickedAsset && groupedConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
            ></Asset>
          ))}
      </Pane>
      <Pane name="network-nodes" style={{ zIndex: 560 }}>
        {groupedConnections
          .filter((gc) => isNode(gc))
          .map((groupedConnection) => (
            <Asset
              key={groupedConnection.id}
              networkId={networkId}
              asset={groupedConnection}
              onClick={handleSelect}
              onMove={(e, a) => handleMove(e, a, updateGroupedConnection)}
              highlighted={clickedAsset && groupedConnection.id === clickedAsset.id}
              reshapeCablePoints={reshapeCablePoints}
              isDragging={isDragging}
              setIsDragging={(value) => setIsDragging(value)}
            ></Asset>
          ))}
        {useSelector((state) => state.network.present.nodes).map((node) => (
          <Asset
            key={node.id}
            networkId={networkId}
            asset={node}
            onClick={handleSelect}
            onMove={(e, a) => handleMove(e, a, updateNode)}
            highlighted={clickedAsset && node.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
          ></Asset>
        ))}
      </Pane>
      <div name="network-cables" style={{ zIndex: 530 }}>
        {useSelector((state) => state.network.present.cables).map((cable) => (
          <Asset
            key={cable.id}
            networkId={networkId}
            asset={cable}
            onClick={handleSelect}
            highlighted={clickedAsset && cable.id === clickedAsset.id}
            reshapeCablePoints={reshapeCablePoints}
            isDragging={isDragging}
            setIsDragging={(value) => setIsDragging(value)}
            renderer={renderer}
            fallbackRenderer={fallbackRenderer}
          ></Asset>
        ))}
      </div>
      <Pane name="network-results" style={{ zIndex: 550 }}>
        {useSelector((state) => findAll(state)).map((asset) => (
          <>
            <ResultMarker
              key={asset.asset.id}
              asset={asset.asset}
              onClick={handleSelect}
              isDragging={isDragging}
            />
          </>
        ))}
      </Pane>
    </>
  );
};

export default Network;
