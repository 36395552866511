export class CachedFile {
  constructor(filename: string, content: string) {
    this.filename = filename;
    this.content = content;
  }
  filename: string;
  content: string;
}

export const validationRules = {
  winDebutStudy: { fileTypes: ".wdf, .gen, .wdi, .wdo", placeholder: "WDF, (GEN), (WDI), (WDO)" },
  smallWorldEnabled: { fileTypes: ".json, .xml", placeholder: "JSON, XML" },
  smallWorldDisabled: { fileTypes: ".json", placeholder: "JSON" },
};
