import { FormEvent, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAuth from "./useAuth";
import "./Login.css";
import WarningModal from "../WarningModal";
import { authorise2 } from "../../services/apiService";

const { REACT_APP_THEME } = process.env;

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const theme = REACT_APP_THEME === "visnet" ? "visnet" : "legacy";
    document.body.classList.add(`bg-${theme}`);
    return () => {
      document.body.classList.remove(`bg-${theme}`);
    };
  }, []);

  let from = location.state?.from?.pathname || "/";

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    setLoading(true);
    authoriseUser(username, password);
  };

  const authoriseUser = (username: string, password: string) => {
    setLoading(true);
    authorise2(username, password)
      .then((response) => {
        if (response.status === 200) {
          auth.login(username);
          navigate(from, { replace: true });
        }
        if (response.status === 403) {
          setError(true);
        }
        setLoading(false);
      })
      .catch((error: Error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      });
  };

  const errorProps = {
    item: false,
    action: () => setError(false),
    msg: "Invalid username or password",
    hideYesButton: true,
    dismissAction: true,
    dismissLabel: "OK",
    className: "danger",
    ref: null,
  };

  return (
    <>
      <Form className="form-signin" onSubmit={handleSubmit}>
        <label htmlFor="username" className="sr-only">
          Username
        </label>
        <Input
          type="text"
          name="username"
          className="form-control text-dark bg-light"
          placeholder="Username"
          required
          autoFocus
        />
        <label htmlFor="password" className="sr-only">
          Password
        </label>
        <Input
          type="password"
          name="password"
          className="form-control text-dark bg-light"
          placeholder="Password"
          required
        />
        <Button size="lg" color="primary" block type="submit">
          {loading && <FontAwesomeIcon icon={faSpinner} spin />} Sign in
        </Button>
      </Form>
      <>{error && <WarningModal {...errorProps} />}</>
    </>
  );
};

export default Login;
