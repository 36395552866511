const DataAlert = ({ updateDefault, original }) => {
  const text = () => {
    if (original?.length > 0) {
      return `Original value: ${original}`;
    }

    return null;
  };

  return (
    <>
      <span className="ml-2" style={{ cursor: "pointer" }} onClick={() => updateDefault()}>
        <i className="text-warning icon-circle-solid"></i>
      </span>
      <span className="ml-2">{text()}</span>
    </>
  );
};

export default DataAlert;
