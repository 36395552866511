import { LatLng } from "./simpleTypes";
import { Asset } from "./asset";
import {
  IPointOfConnectionProperties,
  PointOfConnectionProperties,
} from "./pointOfConnectionProperies";

export class PointOfConnection extends Asset implements IPointOfConnectionProperties {
  constructor(id: string, pointOfConnectionProperties: PointOfConnectionProperties) {
    super(id);
    this.voltDropPercent = pointOfConnectionProperties.voltDropPercent;
    this.loopResistanceMilliohm = pointOfConnectionProperties.loopResistanceMilliohm;
    this.includesTransformerResistance = pointOfConnectionProperties.includesTransformerResistance;
    this.annotation = pointOfConnectionProperties.annotation;
    this.geometry = pointOfConnectionProperties.geometry;
    this.styles = pointOfConnectionProperties.styles;
    this.maxVoltDropPercent = pointOfConnectionProperties.maxVoltDropPercent;
    this.maxVoltDropPercentPassResult = pointOfConnectionProperties.maxVoltDropPercentPassResult;
    this.maxVoltRisePercent = pointOfConnectionProperties.maxVoltRisePercent;
    this.maxVoltRiseTotal = pointOfConnectionProperties.maxVoltRiseTotal;
    this.maxVoltRiseGeneration = pointOfConnectionProperties.maxVoltRiseGeneration;
    this.maxVoltRiseUnbalance = pointOfConnectionProperties.maxVoltRiseUnbalance;
    this.earthLoopImpedance = pointOfConnectionProperties.earthLoopImpedance;
    this.threePhaseFaultCurrent = pointOfConnectionProperties.threePhaseFaultCurrent;
    this.phaseToPhaseFaultCurrent = pointOfConnectionProperties.phaseToPhaseFaultCurrent;
    this.singlePhaseToEarthFaultCurrent =
      pointOfConnectionProperties.singlePhaseToEarthFaultCurrent;
    this.flickerPassResult = pointOfConnectionProperties.flickerPassResult;
    this.flickerDeltaVoltage = pointOfConnectionProperties.flickerDeltaVoltage;
    this.flickerP28Limit = pointOfConnectionProperties.flickerP28Limit;
    this.flickerStartingPassResult = pointOfConnectionProperties.flickerStartingPassResult;
    this.flickerStartingDeltaVoltage = pointOfConnectionProperties.flickerStartingDeltaVoltage;
    this.impedanceLoopOperating = pointOfConnectionProperties.impedanceLoopOperating;
    this.impedanceLoopOperatingPassResult =
      pointOfConnectionProperties.impedanceLoopOperatingPassResult;
    this.impedanceSourceLoopOperating = pointOfConnectionProperties.impedanceSourceLoopOperating;
    this.impedanceSourceLoopOperatingPassResult =
      pointOfConnectionProperties.impedanceSourceLoopOperatingPassResult;
    this.impedanceLoopFault = pointOfConnectionProperties.impedanceLoopFault;
    this.impedancePhaseOperating = pointOfConnectionProperties.impedancePhaseOperating;
    this.impedanceSourcePhaseOperating = pointOfConnectionProperties.impedanceSourcePhaseOperating;
    this.impedanceSourcePhasePhaseOperating =
      pointOfConnectionProperties.impedanceSourcePhasePhaseOperating;
    this.impedancePhaseFault = pointOfConnectionProperties.impedancePhaseFault;
    this.resistanceLoopOperating = pointOfConnectionProperties.resistanceLoopOperating;
    this.resistanceSourceLoopOperating = pointOfConnectionProperties.resistanceSourceLoopOperating;
    this.resistanceLoopFault = pointOfConnectionProperties.resistanceLoopFault;
    this.resistancePhaseOperating = pointOfConnectionProperties.resistancePhaseOperating;
    this.resistanceSourcePhaseOperating =
      pointOfConnectionProperties.resistanceSourcePhaseOperating;
    this.resistanceSourcePhasePhaseOperating =
      pointOfConnectionProperties.resistanceSourcePhasePhaseOperating;
    this.resistancePhaseFault = pointOfConnectionProperties.resistancePhaseFault;
    this.reactanceLoopOperating = pointOfConnectionProperties.reactanceLoopOperating;
    this.reactanceSourceLoopOperating = pointOfConnectionProperties.reactanceSourceLoopOperating;
    this.reactanceLoopFault = pointOfConnectionProperties.reactanceLoopFault;
    this.reactancePhaseOperating = pointOfConnectionProperties.reactancePhaseOperating;
    this.reactanceSourcePhaseOperating = pointOfConnectionProperties.reactanceSourcePhaseOperating;
    this.reactanceSourcePhasePhaseOperating =
      pointOfConnectionProperties.reactanceSourcePhasePhaseOperating;
    this.reactancePhaseFault = pointOfConnectionProperties.reactancePhaseFault;
    this.AllmaxVoltDropPercent = pointOfConnectionProperties.AllmaxVoltDropPercent;
    this.AllmaxVoltDropPercentPeriod = pointOfConnectionProperties.AllmaxVoltDropPercentPeriod;
    this.AllmaxVoltDropPercentPassResult =
      pointOfConnectionProperties.AllmaxVoltDropPercentPassResult;
    this.AllmaxVoltDropPhase = pointOfConnectionProperties.AllmaxVoltDropPhase;
    this.AllmaxVoltRisePercent = pointOfConnectionProperties.AllmaxVoltRisePercent;
    this.AllmaxVoltRiseTotal = pointOfConnectionProperties.AllmaxVoltRiseTotal;
    this.AllmaxVoltRiseGeneration = pointOfConnectionProperties.AllmaxVoltRiseGeneration;
    this.AllmaxVoltRiseUnbalance = pointOfConnectionProperties.AllmaxVoltRiseUnbalance;
    this.AllmaxVoltRisePercentPeriod = pointOfConnectionProperties.AllmaxVoltRisePercentPeriod;
    this.AllmaxVoltRisePhase = pointOfConnectionProperties.AllmaxVoltRisePhase;
    this.AllmaxVoltage = pointOfConnectionProperties.AllmaxVoltage;
    this.AllmaxVoltagePhase1 = pointOfConnectionProperties.AllmaxVoltagePhase1;
    this.AllmaxVoltagePhase2 = pointOfConnectionProperties.AllmaxVoltagePhase2;
    this.AllmaxVoltagePhase3 = pointOfConnectionProperties.AllmaxVoltagePhase3;
    this.AllminVoltage = pointOfConnectionProperties.AllminVoltage;
    this.AllminVoltagePhase1 = pointOfConnectionProperties.AllminVoltagePhase1;
    this.AllminVoltagePhase2 = pointOfConnectionProperties.AllminVoltagePhase2;
    this.AllminVoltagePhase3 = pointOfConnectionProperties.AllminVoltagePhase3;
    this.wintermaxVoltDropPercent = pointOfConnectionProperties.wintermaxVoltDropPercent;
    this.wintermaxVoltDropPercentPeriod =
      pointOfConnectionProperties.wintermaxVoltDropPercentPeriod;
    this.wintermaxVoltDropPercentPassResult =
      pointOfConnectionProperties.wintermaxVoltDropPercentPassResult;
    this.wintermaxVoltDropPhase = pointOfConnectionProperties.wintermaxVoltDropPhase;
    this.wintermaxVoltRisePercent = pointOfConnectionProperties.wintermaxVoltRisePercent;
    this.wintermaxVoltRiseTotal = pointOfConnectionProperties.wintermaxVoltRiseTotal;
    this.wintermaxVoltRiseGeneration = pointOfConnectionProperties.wintermaxVoltRiseGeneration;
    this.wintermaxVoltRiseUnbalance = pointOfConnectionProperties.wintermaxVoltRiseUnbalance;
    this.wintermaxVoltRisePercentPeriod =
      pointOfConnectionProperties.wintermaxVoltRisePercentPeriod;
    this.wintermaxVoltRisePhase = pointOfConnectionProperties.wintermaxVoltRisePhase;
    this.wintermaxVoltage = pointOfConnectionProperties.wintermaxVoltage;
    this.wintermaxVoltagePhase1 = pointOfConnectionProperties.wintermaxVoltagePhase1;
    this.wintermaxVoltagePhase2 = pointOfConnectionProperties.wintermaxVoltagePhase2;
    this.wintermaxVoltagePhase3 = pointOfConnectionProperties.wintermaxVoltagePhase3;
    this.winterminVoltage = pointOfConnectionProperties.winterminVoltage;
    this.winterminVoltagePhase1 = pointOfConnectionProperties.winterminVoltagePhase1;
    this.winterminVoltagePhase2 = pointOfConnectionProperties.winterminVoltagePhase2;
    this.winterminVoltagePhase3 = pointOfConnectionProperties.winterminVoltagePhase3;
    this.summermaxVoltDropPercent = pointOfConnectionProperties.summermaxVoltDropPercent;
    this.summermaxVoltDropPercentPeriod =
      pointOfConnectionProperties.summermaxVoltDropPercentPeriod;
    this.summermaxVoltDropPercentPassResult =
      pointOfConnectionProperties.summermaxVoltDropPercentPassResult;
    this.summermaxVoltDropPhase = pointOfConnectionProperties.summermaxVoltDropPhase;
    this.summermaxVoltRisePercent = pointOfConnectionProperties.summermaxVoltRisePercent;
    this.summermaxVoltRiseTotal = pointOfConnectionProperties.summermaxVoltRiseTotal;
    this.summermaxVoltRiseGeneration = pointOfConnectionProperties.summermaxVoltRiseGeneration;
    this.summermaxVoltRiseUnbalance = pointOfConnectionProperties.summermaxVoltRiseUnbalance;
    this.summermaxVoltRisePercentPeriod =
      pointOfConnectionProperties.summermaxVoltRisePercentPeriod;
    this.summermaxVoltRisePhase = pointOfConnectionProperties.summermaxVoltRisePhase;
    this.summermaxVoltage = pointOfConnectionProperties.summermaxVoltage;
    this.summermaxVoltagePhase1 = pointOfConnectionProperties.summermaxVoltagePhase1;
    this.summermaxVoltagePhase2 = pointOfConnectionProperties.summermaxVoltagePhase2;
    this.summermaxVoltagePhase3 = pointOfConnectionProperties.summermaxVoltagePhase3;
    this.summerminVoltage = pointOfConnectionProperties.summerminVoltage;
    this.summerminVoltagePhase1 = pointOfConnectionProperties.summerminVoltagePhase1;
    this.summerminVoltagePhase2 = pointOfConnectionProperties.summerminVoltagePhase2;
    this.summerminVoltagePhase3 = pointOfConnectionProperties.summerminVoltagePhase3;
    this.phaseImpedance = pointOfConnectionProperties.phaseImpedance;
    this.loopImpedance = pointOfConnectionProperties.loopImpedance;
    this.phaseAllocation = pointOfConnectionProperties.phaseAllocation;
    this.nodeNumber = pointOfConnectionProperties.nodeNumber;
    this.rootTransformerId = pointOfConnectionProperties.rootTransformerId;
    this.substationId = pointOfConnectionProperties.substationId;
  }

  [key: string]: any;
  voltDropPercent: number | null;
  loopResistanceMilliohm: number | null;
  includesTransformerResistance: Boolean;
  annotation: string;
  geometry: LatLng;
  styles: any;
  maxVoltDropPercent: number | null;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRisePercent: number | null;
  maxVoltRiseTotal: number | null;
  maxVoltRiseGeneration: number | null;
  maxVoltRiseUnbalance: number | null;
  earthLoopImpedance: number;
  threePhaseFaultCurrent: number;
  phaseToPhaseFaultCurrent: number;
  singlePhaseToEarthFaultCurrent: number;
  flickerPassResult: Boolean;
  flickerDeltaVoltage: number;
  flickerP28Limit: number;
  flickerStartingPassResult: Boolean;
  flickerStartingDeltaVoltage: number;
  impedanceLoopOperating: string;
  impedanceLoopOperatingPassResult: Boolean;
  impedanceSourceLoopOperating: string;
  impedanceSourceLoopOperatingPassResult: Boolean;
  impedanceLoopFault: string;
  impedancePhaseOperating: string;
  impedanceSourcePhaseOperating: string;
  impedanceSourcePhasePhaseOperating: string;
  impedancePhaseFault: string;
  resistanceLoopOperating: string;
  resistanceSourceLoopOperating: string;
  resistanceLoopFault: string;
  resistancePhaseOperating: string;
  resistanceSourcePhaseOperating: string;
  resistanceSourcePhasePhaseOperating: string;
  resistancePhaseFault: string;
  reactanceLoopOperating: string;
  reactanceSourceLoopOperating: string;
  reactanceLoopFault: string;
  reactancePhaseOperating: string;
  reactanceSourcePhaseOperating: string;
  reactanceSourcePhasePhaseOperating: string;
  reactancePhaseFault: string;
  AllmaxVoltDropPercent: number | null;
  AllmaxVoltDropPercentPeriod: number | null;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltDropPhase: number | null;
  AllmaxVoltRisePercent: number | null;
  AllmaxVoltRiseTotal: number | null;
  AllmaxVoltRiseGeneration: number | null;
  AllmaxVoltRiseUnbalance: number | null;
  AllmaxVoltRisePercentPeriod: number | null;
  AllmaxVoltRisePhase: number | null;
  AllmaxVoltage: number | null;
  AllmaxVoltagePhase1: number | null;
  AllmaxVoltagePhase2: number | null;
  AllmaxVoltagePhase3: number | null;
  AllminVoltage: number | null;
  AllminVoltagePhase1: number | null;
  AllminVoltagePhase2: number | null;
  AllminVoltagePhase3: number | null;
  wintermaxVoltDropPercent: number | null;
  wintermaxVoltDropPercentPeriod: number | null;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltDropPhase: number | null;
  wintermaxVoltRisePercent: number | null;
  wintermaxVoltRiseTotal: number | null;
  wintermaxVoltRiseGeneration: number | null;
  wintermaxVoltRiseUnbalance: number | null;
  wintermaxVoltRisePercentPeriod: number | null;
  wintermaxVoltRisePhase: number | null;
  wintermaxVoltage: number | null;
  wintermaxVoltagePhase1: number | null;
  wintermaxVoltagePhase2: number | null;
  wintermaxVoltagePhase3: number | null;
  winterminVoltage: number | null;
  winterminVoltagePhase1: number | null;
  winterminVoltagePhase2: number | null;
  winterminVoltagePhase3: number | null;
  summermaxVoltDropPercent: number | null;
  summermaxVoltDropPercentPeriod: number | null;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltDropPhase: number | null;
  summermaxVoltRisePercent: number | null;
  summermaxVoltRiseTotal: number | null;
  summermaxVoltRiseGeneration: number | null;
  summermaxVoltRiseUnbalance: number | null;
  summermaxVoltRisePercentPeriod: number | null;
  summermaxVoltRisePhase: number | null;
  summermaxVoltage: number | null;
  summermaxVoltagePhase1: number | null;
  summermaxVoltagePhase2: number | null;
  summermaxVoltagePhase3: number | null;
  summerminVoltage: number | null;
  summerminVoltagePhase1: number | null;
  summerminVoltagePhase2: number | null;
  summerminVoltagePhase3: number | null;
  phaseImpedance: number | null;
  loopImpedance: number | null;
  phaseAllocation: string;
  nodeNumber: number;
  rootTransformerId: string;
  substationId: string;
}
