import getAssetStyleInfo from "../context/AssetStyleInfo";
import { isWelder, isTransformer, isMotor, isNode, isCable } from "./networkFunctions";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import {
  addGroupedConnection,
  deleteGroupedConnection,
  updateCable,
  updateGroupedConnection,
} from "../app/networkSlice";

export const refineRingfencedAssets = (cables, ringfenced) => {
  ringfenced
    .filter((node) => isNode(node) || isWelder(node) || isTransformer(node) || isMotor(node))
    .forEach((node) => {
      if (!allCablesConnectedToNodeAreRingfenced(cables, ringfenced, node)) {
        const index = ringfenced.indexOf(node);
        if (index > -1) ringfenced.splice(index, 1);
      }
    });

  return ringfenced;
};

const allCablesConnectedToNodeAreRingfenced = (cables, ringfenced, node) => {
  const networkCables = cables.filter(
    (c) => c.startAssetId === node.id || c.endAssetId === node.id,
  );

  const ringfencedCables = ringfenced.filter(
    (c) => isCable(c) && (c.startAssetId === node.id || c.endAssetId === node.id),
  );

  return networkCables?.length === ringfencedCables?.length;
};

export const removeGroupedConnection = (
  cables,
  ringfenced,
  asset,
  selected,
  groupedConnections,
  dispatchRedux,
) => {
  const index = groupedConnections.findIndex((x) => x.id === asset.id);

  if (index === -1) {
    // groupedConnection not found, nothing to delete
    return;
  }

  const isConnected = cables.some((c) => c.startAssetId === asset.id || c.endAssetId === asset.id);

  const groupedConnection = groupedConnections[index];

  let canRemoveNode = false;
  let otherConsumersPresent = false;

  if (selected === "All") {
    canRemoveNode = allCablesConnectedToNodeAreRingfenced(cables, ringfenced, asset);
  } else if (
    selected !== "Properties" &&
    asset.ringfencedPoint &&
    groupedConnection.groupedConnectionPoints.some(
      (p) => p.styles.class !== asset.ringfencedPoint?.styles.class,
    )
  ) {
    // We're deleting a specific consumer class and the node contains different classes, so the node can't be deleted
    otherConsumersPresent = true;
  }

  if (groupedConnection.styles.class === "connection") {
    if (asset.ringfencedPoint) {
      cables
        .filter((c) => c.startAssetId === asset.ringfencedPoint.id)
        .forEach((c) =>
          dispatchRedux(
            updateCable({
              id: c.id,
              name: "startAssetId",
              value: asset.ringfencedPoint.parentId,
            }),
          ),
        );
    } else {
      groupedConnection.groupedConnectionPoints.forEach((gcp) => {
        cables
          .filter((c) => c.startAssetId === gcp.id)
          .forEach((c) =>
            dispatchRedux(updateCable({ id: c.id, name: "startAssetId", value: gcp.parentId })),
          );
      });
    }
  }

  if (otherConsumersPresent) {
    dispatchRedux(
      updateGroupedConnection({
        id: groupedConnection.id,
        name: "groupedConnectionPoints",
        value: groupedConnection.groupedConnectionPoints.filter(
          (p) => p.styles.class !== asset.ringfencedPoint.styles.class,
        ),
      }),
    );
  } else {
    dispatchRedux(
      updateGroupedConnection({
        id: groupedConnection.id,
        name: "styles",
        value: getAssetStyleInfo("node"),
      }),
    );
    dispatchRedux(
      updateGroupedConnection({
        id: groupedConnection.id,
        name: "groupedConnectionPoints",
        value: [],
      }),
    );
  }

  if (!otherConsumersPresent && (!isConnected || canRemoveNode)) {
    dispatchRedux(deleteGroupedConnection(groupedConnection.id));
  }
};

export const removePointOfConnection = (reference, cables, asset, dispatchRedux) => {
  const isConnected = cables.some((c) => c.startAssetId === asset.id || c.endAssetId === asset.id);

  if (isConnected) {
    const groupedConnection = createGroupedConnection(reference, asset);
    dispatchRedux(addGroupedConnection(groupedConnection));
  }
};

const createGroupedConnection = (reference, asset) => {
  const groupedConnection = new GroupedConnection(
    asset.id,
    "",
    [],
    "",
    reference.groundTypeOverrideDefaults.groundTypeOverride,
  );
  groupedConnection.geometry = asset.geometry;
  groupedConnection.status = "Existing";
  groupedConnection.styles = getAssetStyleInfo("node");
  groupedConnection.groupedConnectionPoints = [];
  return groupedConnection;
};
