// @flow
import { PointOfConnectionProperties } from "../model/viewModel/pointOfConnectionProperies";

export const getPointOfConnectionProperties = (
  voltDropPercent,
  loopResistanceMilliohm,
  includesTransformerResistance,
  geometry,
  annotation,
  styles,
) => {
  let pointOfConnectionProperties: PointOfConnectionProperties = new PointOfConnectionProperties();
  pointOfConnectionProperties.voltDropPercent = voltDropPercent;
  pointOfConnectionProperties.loopResistanceMilliohm = loopResistanceMilliohm;
  pointOfConnectionProperties.includesTransformerResistance = includesTransformerResistance;
  pointOfConnectionProperties.geometry = geometry;
  pointOfConnectionProperties.annotation = annotation;
  pointOfConnectionProperties.styles = styles;
  return pointOfConnectionProperties;
};
