// @flow
import { LatLng } from "./simpleTypes";

export function getGeometryWktFromLineString(lineGeom): string {
  let wktString = "LINESTRING (";
  for (const point of lineGeom) {
    wktString += point.lat + " " + point.lng + ", ";
  }

  wktString = wktString.substring(0, wktString.length - 2); // trim last comma and space

  return wktString + ")";
}

export function getGeometryWktFromPoint(geometry: LatLng): string {
  return "POINT (" + geometry.lat + " " + geometry.lng + ")";
}

export const getLatLngFromWktPoint = (wktPoint: string): LatLng => {
  try {
    if (wktPoint.toUpperCase().startsWith("POINT") === false) {
      throw new Error("Geometry is not a point");
    }

    var matches = `${wktPoint}`.match(/[+-]?(\d*\.)?\d+/g);

    const [lat, lng] = matches;

    return new LatLng(+lat, +lng);
  } catch {
    return new LatLng(null, null);
  }
};

export const getReversedLatLngFromWktPoint = (wktPoint: string): LatLng => {
  try {
    if (wktPoint.toUpperCase().startsWith("POINT") === false) {
      throw new Error("Geometry is not a point");
    }

    var matches = `${wktPoint}`.match(/[+-]?(\d*\.)?\d+/g);

    const [lat, lng] = matches;

    return new LatLng(+lng, +lat);
  } catch {
    return new LatLng(null, null);
  }
};

export function getLatLngArrayFromWktLineString(wktPoint: string): LatLng[] {
  const numbersOnly = wktPoint.substring(12, wktPoint.length - 1);
  const split = numbersOnly.split(", ");
  let latLngArray = [];

  for (let point of split) {
    const pointSplit = point.split(" ");
    latLngArray.push(new LatLng(Number(pointSplit[0]), Number(pointSplit[1])));
  }

  return latLngArray;
}

export function getReversedLatLngArrayFromWktLineString(wktPoint: string): LatLng[] {
  const numbersOnly = wktPoint.substring(12, wktPoint.length - 1);
  const split = numbersOnly.split(", ");
  let latLngArray = [];

  for (let point of split) {
    const pointSplit = point.split(" ");
    latLngArray.push(new LatLng(Number(pointSplit[1]), Number(pointSplit[0])));
  }

  return latLngArray;
}
