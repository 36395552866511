import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label } from "reactstrap";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import * as statusConstants from "../constants/status";
import * as poleLinkConstants from "../constants/poleLink";
import * as poleProperties from "../constants/poleProperties";
import DataAlert from "./DataAlert";
import { formatLength } from "../utils/numberFunctions";
import { getDefaultPole } from "../utils/poleFunctions";
import { updateGroupedConnection } from "../app/networkSlice";

const PoleSelector = ({ handleDefault }) => {
  const { toolState } = useContext(ToolContext);
  const { formState, dispatch } = useContext(FormContext);
  const { clientSettings, groupedConnectionProperties, reference } = formState;
  const {
    pole,
    poleHeightIsDefault,
    stayAngleIsDefault,
    poleTermLengthIsDefault,
    poleLinkIsDefault,
  } = groupedConnectionProperties;
  const { clickedAsset } = toolState;
  const poleTermLengthEnabled = clientSettings.features.PoleTermLengthEnabled;

  const dispatchRedux = useDispatch();

  const cables = useSelector((state) => state.network.present.cables);

  const togglePoles = () => {
    const newValue = getDefaultPole(
      clientSettings,
      reference,
      cables,
      clickedAsset,
      !(pole?.enabled ?? false),
    );

    updateNode(newValue);
  };

  const togglePoleLink = () => {
    if (pole?.enabled) {
      const newValue = {
        ...pole,
        poleLinkEnabled: !pole.poleLinkEnabled,
      };
      updateNode(newValue);
      handleDefault(poleProperties.POLELINK);
    }
  };

  const updatePoleHeight = (poleHeight) => {
    const newValue = {
      ...pole,
      poleHeight: poleHeight,
    };
    updateNode(newValue);
    handleDefault(poleProperties.POLEHEIGHT);
  };

  const updateStayAngle = (stayAngle) => {
    const newValue = {
      ...pole,
      stayAngle: stayAngle,
    };
    updateNode(newValue);
    handleDefault(poleProperties.STAYANGLE);
  };

  const updatePoleTermLength = (poleTermLength) => {
    const newValue = {
      ...pole,
      poleTermLength: poleTermLength,
      isDefaultPoleTermLength: false,
    };
    updateNode(newValue);
    handleDefault(poleProperties.POLETERMLENGTH);
  };

  const updateStatus = (status) => {
    const newValue = {
      ...pole,
      status: status,
    };
    updateNode(newValue);
  };

  const updatePoleLink = (status) => {
    const newValue = {
      ...pole,
      poleLink: status,
    };
    updateNode(newValue);
    handleDefault(poleProperties.POLELINK);
  };

  const updateNode = (value) => {
    dispatch({
      form: "groupedConnectionProperties",
      field: "pole",
      value: value,
      type: "UPDATE_FIELD",
    });
    dispatchRedux(updateGroupedConnection({ id: clickedAsset.id, name: "pole", value }));
  };

  return (
    <>
      <div
        className="bg-white-alpha d-flex flex-column"
        style={{
          padding: ".75rem",
          margin: ".75rem 0",
          marginBottom: "1rem",
        }}
      >
        <div className="d-flex w-100 flex-row" onClick={() => togglePoles()}>
          <h6
            className="text-light"
            style={{
              fontSize: ".75rem",
              textTransform: "uppercase",
              fontWeight: 200,
            }}
          >
            Poles
          </h6>
          <span className="ml-auto absolute">
            <i className={`icon-${pole && pole.enabled ? "check" : "cross"}-solid pr-2`} />
          </span>
        </div>
        {pole && pole.enabled && (
          <>
            <div>
              <Label for="status">New / Existing / Replacement</Label>
              <Input
                type="select"
                name="status"
                id="status"
                value={pole.status}
                onChange={(e) => updateStatus(e.target.value)}
              >
                <option value={statusConstants.NEW}>New</option>
                <option value={statusConstants.EXISTING}>Existing</option>
                <option value={statusConstants.REPLACEMENT}>Replacement</option>
              </Input>
            </div>
            <div className="mt-2">
              <Label for={poleProperties.POLEHEIGHT}>
                Height (m)
                {poleHeightIsDefault && (
                  <DataAlert updateDefault={() => handleDefault(poleProperties.POLEHEIGHT)} />
                )}
              </Label>
              <Input
                type="select"
                name={poleProperties.POLEHEIGHT}
                id={poleProperties.POLEHEIGHT}
                value={pole.poleHeight}
                onChange={(e) => updatePoleHeight(e.target.value)}
              >
                {formState.reference.pole.poleHeight.map((poleHeight) => (
                  <option key={poleHeight} value={poleHeight}>
                    {poleHeight}
                  </option>
                ))}
              </Input>
            </div>
            <div className="mt-2">
              <Label for={poleProperties.STAYANGLE}>
                Preferred Stay Angle (degrees)
                {stayAngleIsDefault && (
                  <DataAlert updateDefault={() => handleDefault(poleProperties.STAYANGLE)} />
                )}
              </Label>
              <Input
                type="select"
                name={poleProperties.STAYANGLE}
                id={poleProperties.STAYANGLE}
                value={pole.stayAngle}
                onChange={(e) => updateStayAngle(e.target.value)}
              >
                {formState.reference.pole.stayAngle.map((stayAngle) => (
                  <option key={stayAngle} value={stayAngle}>
                    {stayAngle}
                  </option>
                ))}
              </Input>
            </div>
            {poleTermLengthEnabled && (
              <div className="mt-2">
                <Label for={poleProperties.POLETERMLENGTH}>
                  Pole Term Length (m)
                  {poleTermLengthIsDefault && (
                    <DataAlert updateDefault={() => handleDefault(poleProperties.POLETERMLENGTH)} />
                  )}
                </Label>
                <Input
                  type="number"
                  id={poleProperties.POLETERMLENGTH}
                  name={poleProperties.POLETERMLENGTH}
                  placeholder={formatLength(0)}
                  value={pole.poleTermLength}
                  onChange={(e) => updatePoleTermLength(e.target.value)}
                  onBlur={(e) => updatePoleTermLength(formatLength(e.target.value))}
                />
              </div>
            )}
            {clientSettings.features.ConfigurableLinkBoxEnabled && (
              <>
                <div className="mt-2">
                  <div
                    className="d-flex w-100 flex-row"
                    onClick={() => togglePoleLink()}
                    id="togglePoleLink"
                  >
                    <Label for={poleProperties.POLELINK}>
                      Pole Link
                      {poleLinkIsDefault && (
                        <DataAlert updateDefault={() => handleDefault(poleProperties.POLELINK)} />
                      )}
                    </Label>
                    <span className="ml-auto absolute">
                      <i
                        className={`icon-${pole && pole.poleLinkEnabled ? "check" : "cross"}-solid pr-2`}
                      />
                    </span>
                  </div>

                  {pole.poleLinkEnabled && (
                    <Input
                      type="select"
                      name="poleLink"
                      id="poleLink"
                      value={pole.poleLink}
                      onChange={(e) => updatePoleLink(e.target.value)}
                    >
                      <option value={poleLinkConstants.OPEN}>Open</option>
                      <option value={poleLinkConstants.CLOSED}>Closed</option>
                    </Input>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PoleSelector;
