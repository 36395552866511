import { KeyboardEvent } from "react";

export const isKeyboardShortcut = (keyDown: KeyboardEvent<any>, historyModeEnabled: boolean) => {
  return (
    keyDown.key === "Escape" ||
    (historyModeEnabled && keyDown.ctrlKey && keyDown.key === "z") ||
    (historyModeEnabled && keyDown.ctrlKey && keyDown.key === "y") ||
    (!(keyDown.target as HTMLInputElement).classList.contains("form-control") &&
      (keyDown.shiftKey || keyDown.key === "Delete"))
  );
};
