import L, { LatLng } from "leaflet";
import { useState, useEffect, useContext } from "react";
import { flushSync } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { ScaleControl, useMap, useMapEvents, Pane } from "react-leaflet";
import { ButtonGroup } from "reactstrap";
import CenterMapControl from "./CenterMapControl";
import ZoomControl from "./ZoomControl";
import { v4 as uuid } from "uuid";
import { FormContext } from "../context/FormContext";
import { ToolContext } from "../context/ToolContext";
import { getConnectionPointProperties } from "../states/connectionPoint";
import { getCableProperties } from "../states/cable";
import { getTransformerProperties } from "../states/transformer";
import { getMotorProperties } from "../states/motor";
import { getWelderProperties } from "../states/welder";
import { getPointOfConnectionProperties } from "../states/pointOfConnection";
import { consumerDefaults } from "../utils/consumerDefaults";
import { isPositiveNonDecimalNumber } from "../utils/numberFunctions";
import { removePolesForCable } from "../utils/poleFunctions";
import * as fusewayStatus from "../constants/fusewayStatus";
import { removeLinkBoxCable, attachSplitCableToLinkBox } from "../utils/linkBoxFunctions";
import {
  getAssetById,
  canRemoveNetworkAsset,
  getTransformerPhase,
} from "../utils/networkFunctions";
import { createPoles } from "../utils/poleFunctions";
import { removePointOfConnection } from "../utils/ringfenceFunctions";
import { isEmpty } from "../utils/isEmpty";
import {
  getCutOutSizeDefaultValue,
  getDefaultUnbalancedPercent,
  getDefaultLowLoadedPhase,
} from "../utils/consumerDefaults";
import IconDropDrownMenu from "./IconDropDrownMenu";
import Network from "./Network";
import RagNetwork from "./RagNetwork";
import DetectNetwork from "./DetectNetwork";
import NavMenu from "./NavMenu";
import { Cable } from "../model/viewModel/cable";
import { Transformer } from "../model/viewModel/transformer";
import { Motor } from "../model/viewModel/motor";
import { Welder } from "../model/viewModel/welder";
import { PointOfConnection } from "../model/viewModel/pointOfConnection";
import { Node } from "../model/viewModel/node";
import { NodeProperties } from "../model/viewModel/nodeProperties";
import { GroupedConnection } from "../model/viewModel/groupedConnection";
import { GroupedConnectionPoint } from "../model/viewModel/groupedConnectionPoint";
import CableTool from "./CableTool";
import RingfenceTool from "./RingfenceTool";
import MarkerTool from "./MarkerTool";
import ExitMessage from "./ExitMessage";
import NearbyAssets from "./NearbyAssets";
import MapNavigate from "./MapNavigate";
import getAssetStyleInfo from "../context/AssetStyleInfo";
import { lineString } from "@turf/turf";
import * as tab from "../constants/tabs";
import * as statusConstants from "../constants/status";
import lineSplit from "@turf/line-split";
import SitePlanImport from "./SitePlanImport";
import "leaflet-path-transform";
import "leaflet-imageoverlay-rotated";
import Control from "./control/Control";
import { getRingfencedTypes } from "./RingfenceTool";
import {
  clearResults,
  findAll,
  addWelder,
  deleteWelder,
  addMotor,
  deleteMotor,
  addPointOfConnection,
  deletePointOfConnection,
  addTransformer,
  deleteTransformer,
  addGroupedConnection,
  deleteGroupedConnection,
  deleteGroupedConnectionPoints,
  addNode,
  deleteNode,
  deleteConnectionPoint,
  updateGroupedConnection,
  deleteCable,
  updateCable,
  addCable,
  getAllGeometries,
  updateCableArray,
  getAllGeometryAssets,
  updateWelder,
  updateMotor,
  updatePointOfConnection,
  updateTransformer,
  updateGroupedConnectionChild,
  resetFeederNumbers,
  setNetworkCreated,
} from "../app/networkSlice";
import { getResultProperties } from "../utils/referenceFunctions";
import { store } from "../app/store";
import { endBatchAction, startBatchAction } from "../app/undoable";
import ReshapeTool from "./ReshapeTool";
import { calculateDistance, calculateLength, insertPointOnPath } from "../utils/lineFunctions";
import { resetNetworkToolState } from "../utils/networkFunctions";
import { undoAction, redoAction } from "../app/undoable";
import { snapToLine } from "../utils/geometryFunctions";
import PopupResults from "./PopupResults";

const modeMap = [
  { id: "Select", mode: "select" },
  { id: "SelectGroup", mode: "select-group" },
  { id: "Reshape", mode: "re-shape" },
  { id: "SelectResults", mode: "select-results" },
  { id: "Delete", mode: "remove" },
  { id: "DeleteGroup", mode: "remove-group" },
  { id: "SplitCable", mode: "split-cable" },
  {
    id: "GroundMountedTransformer",
    mode: "draw",
  },
  {
    id: "PoleMountedTransformer",
    mode: "draw",
  },
  {
    id: "DomesticProperty",
    mode: "draw",
  },
  {
    id: "SmallCommercialProperty",
    mode: "draw",
  },
  {
    id: "LargeCommercialProperty",
    mode: "draw",
  },
  {
    id: "IndustrialProperty",
    mode: "draw",
  },
  {
    id: "Generator",
    mode: "draw",
  },
  {
    id: "Welder",
    mode: "draw",
  },
  {
    id: "Motor",
    mode: "draw",
  },
  {
    id: "EvChargePoint",
    mode: "draw",
  },
  {
    id: "HeatPump",
    mode: "draw",
  },
  {
    id: "Node",
    mode: "draw",
  },
  {
    id: "GroupedConnection",
    mode: "draw",
  },
  {
    id: "UndergroundCable",
    mode: "draw",
  },
  {
    id: "OverheadLine",
    mode: "draw",
  },
  {
    id: "PointOfConnection",
    mode: "draw",
  },
];

export const getNetworkAsset = (e, allAssets) => {
  let networkAsset = {};

  allAssets.forEach((asset) => {
    if (asset.asset.id === e.id) {
      networkAsset = {
        id: asset.asset.id,
        type: asset.type,
      };
    }
  });

  return networkAsset;
};

const MapControls = ({ renderer, fallbackRenderer }) => {
  const map = useMap();
  const { formState, dispatch } = useContext(FormContext);
  const dispatchRedux = useDispatch();
  const { toolState, setToolState } = useContext(ToolContext);
  const [renderId, setRenderId] = useState();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());
  const [splitCable, setSplitCable] = useState(undefined);
  const [reshapeCablePoint, setReshapeCablePoint] = useState([]);
  const overrideLength = +formState.cableProperties.overrideLength;

  const allAssets = useSelector((state) => findAll(state));
  const pointOfConnections = useSelector((state) => state.network.present.pointOfConnections);
  const transformers = useSelector((state) => state.network.present.transformers);
  const groupedConnections = useSelector((state) => state.network.present.groupedConnections);
  const cables = useSelector((state) => state.network.present.cables);
  const allGeometries = useSelector((state) => getAllGeometries(state));
  const allGeometryAssets = useSelector((state) => getAllGeometryAssets(state));
  const iconSize = useSelector(({ settings }) => settings.iconSize);
  const popupResults = useSelector(({ settings }) => settings.isPopupResultsEnabled);

  const { ragNetworks, network, reference, localOverrideConfig, clientSettings, detectNetworks } =
    formState;

  const {
    groundTypeOverrideDefaults: { groundTypeOverride: defaultGroundTypeOverride } = {},
    transformers: { defaults: transformerDefaults },
    cables: { defaults: cableDefaults },
    consumers: consumerReference,
  } = reference;

  const {
    toolbar,
    selectedTool,
    activeTool,
    activeCategory,
    mode,
    clickedAsset,
    drawCable,
    errors,
    showNearbyAssets,
    sitePlans,
    selectedSitePlanId,
    resetSitePlan,
    refreshSitePlan,
    ringfenced,
    keyDown,
  } = toolState;

  useEffect(() => {
    if (refreshSitePlan) {
      const _toolState = { ...toolState };
      _toolState.refreshSitePlan = false;
      setToolState(_toolState);
    }
  }, [refreshSitePlan]);

  useEffect(() => {
    if (splitCable) {
      handleSplitCable(splitCable);
    }
  }, [splitCable]);

  useEffect(() => {
    if (network.loaded) {
      delete network.loaded;
      delete network.existing;
      network.existing = true;

      dispatch({
        form: "network",
        obj: network,
        type: "REPLACE_STATE",
      });
      setRenderId(uuid());
    }
  }, [network, allAssets]);

  useEffect(() => {
    const _showNearbyAssets = { ...showNearbyAssets };
    const zoomLevel = map.getZoom();

    if (zoomLevel < parseInt(clientSettings.map.minNearbySubstationDisplayZoomLevel)) {
      _showNearbyAssets.disabled = true;
    } else {
      _showNearbyAssets.disabled = false;
    }

    setToolState({
      showNearbyAssets: _showNearbyAssets,
    });
  }, [zoomLevel]);

  map.once("zoomend", function () {
    setZoomLevel(map.getZoom());
  });

  useEffect(() => {
    if (!resetSitePlan) return;
    const _toolState = { ...toolState };

    const sitePlans = _toolState.sitePlans.map((m) => m.id);
    const sitePlan = _toolState.originalSitePlans.find((f) => !sitePlans.includes(f.id));

    const originalSitePlan = { ...sitePlan };
    _toolState.sitePlans.push(originalSitePlan);
    _toolState.resetSitePlan = 0;
    setToolState(_toolState);
    removeTransformPathLayers();
    handleSitePlanClick(originalSitePlan.id);
  }, [resetSitePlan]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      if (errors.messages.length && errors.messages[0].link) {
        const asset = getAssetById(allAssets, errors.messages[0].link);
        if (!asset) {
          return;
        }

        if (Array.isArray(asset.geometry)) {
          map.fitBounds(asset.geometry);
        } else {
          map.setView(asset.geometry);
        }
      } else {
        const networkObjs = allGeometries;
        networkObjs.length > 0 && map.fitBounds(networkObjs);
      }
    }
  }, [errors]);
  const replacePoint = (originalPosition, newPosition) => {
    setReshapeCablePoint(reshapeCablePoint.map((p) => (p === originalPosition ? newPosition : p)));
  };

  useEffect(() => {
    if (keyDown.key === "Escape") {
      handleSelectedItem(0, undefined, 0);
    }
    if (mode === "select" && keyDown.key === "Delete") {
      if (selectedSitePlanId) {
        document.getElementById("site-plan-delete").click();
      } else if (clickedAsset) {
        remove(clickedAsset);
        const _toolState = toolState;
        _toolState.activeTool = "";
        setToolState(_toolState);
      }
    }
    if (keyDown.ctrlKey && keyDown.key === "z") {
      undo();
    }
    if (keyDown.ctrlKey && keyDown.key === "y") {
      redo();
    }
    document.getElementById("wrapper").focus();
  }, [keyDown]);

  const hideResults = () => {
    const _toolState = toolState;
    _toolState.showResults = false;
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));
  };

  const handleSelectedItem = (categoryIndex, item, itemIndex = -1) => {
    if (!item) item = toolbar[categoryIndex].items[selectedTool[categoryIndex]];

    const mode = modeMap.find((mode) => mode.id === item.id).mode;

    if (mode === "select-group" || mode === "remove-group") {
      L.DomUtil.addClass(map._container, "ringfence");
    } else {
      L.DomUtil.removeClass(map._container, "ringfence");
    }

    if (mode === "re-shape" || mode === "select" || mode === "select-group") {
      setReshapeCablePoint([]);
    }

    const _toolState = toolState;
    let activeTool = "";

    if (itemIndex >= 0) {
      _toolState.selectedTool[categoryIndex] = itemIndex;
    }

    if (mode === "draw") {
      activeTool = item;
      _toolState.activeTab = tab.PROPERTIES;
      drawStart(item);
    }

    _toolState.mode = mode;
    _toolState.activeTool = activeTool;
    _toolState.activeCategory = categoryIndex;
    _toolState.clickedAsset = null;
    _toolState.drawMarker = false;
    _toolState.drawCable = false;
    _toolState.drawBoundary = false;
    _toolState.ringfenced = [];
    _toolState.ringfencedFiltered = [];
    _toolState.ringfencedTypes = [];
    _toolState.disableBoundary = false;
    _toolState.selectedSitePlanId = undefined;
    _toolState.searchValue = undefined;
    _toolState.searchValue = "";
    _toolState.matchedSearchIds = [];
    _toolState.feederList = false;
    setToolState(_toolState);
    removeTransformPathLayers();
    document.getElementById("wrapper").focus();
  };

  const removeTransformPathLayers = () => {
    map.eachLayer(function (layer) {
      if (layer.transform && layer.transform._handlersGroup) {
        const transformLayers = Object.values(layer.transform._handlersGroup._layers).map((l) => l);
        transformLayers.forEach((lyr) => {
          map.removeLayer(lyr);
        });
      }
    });
  };

  const drawStart = (item) => {
    if (item.type === "groupedConnection") {
      dispatch({
        form: "groupedConnectionProperties",
        obj: new GroupedConnection(uuid(), "", [], "", defaultGroundTypeOverride),
        type: "REPLACE_STATE",
      });
    }
    if (item.type === "transformer") {
      const mounting = item.class.split("-").pop();
      dispatch({
        form: "transformerProperties",
        obj: getTransformerProperties(
          transformerDefaults[mounting],
          false,
          0,
          mounting,
          [],
          230,
          "Three",
          false,
          "Default",
          false,
          transformerDefaults["fuseSize"],
          false,
          [],
          50,
          false,
          8.0,
          "",
          false,
          null,
          null,
          null,
          reference.constraints.systemVoltage,
        ),
        type: "REPLACE_STATE",
      });
    }

    if (item.type === "connectionPoint") {
      if (item.class === "motor") {
        dispatch({
          form: "motorProperties",
          obj: getMotorProperties(
            50,
            false,
            "kW",
            "Three",
            false,
            "dirOnLine",
            false,
            0.3,
            false,
            null,
            5,
            false,
            "perMinute",
            85,
            false,
            null,
            null,
            "",
            "",
          ),
          type: "REPLACE_STATE",
        });
      } else if (item.class === "welder") {
        dispatch({
          form: "welderProperties",
          obj: getWelderProperties(
            50,
            false,
            "kVA",
            "Three",
            false,
            30,
            false,
            "perMinute",
            false,
            0.3,
            false,
            85,
            false,
            null,
            null,
            "",
            0,
            "",
          ),
          type: "REPLACE_STATE",
        });
      } else if (item.class === "point-of-connection") {
        dispatch({
          form: "pointOfConnectionProperties",
          obj: getPointOfConnectionProperties(0, 0, false, null, "", ""),
          type: "REPLACE_STATE",
        });
      } else {
        const consumer = consumerDefaults(consumerReference, item.class, localOverrideConfig);
        const groupedConnectionPoint = new GroupedConnectionPoint(
          uuid(),
          getConnectionPointProperties(
            consumer["consumerType"],
            false,
            true,
            1,
            0,
            0,
            0,
            false,
            getDefaultUnbalancedPercent(formState, transformers),
            getDefaultLowLoadedPhase(formState, transformers),
            consumer["consumptionValue1"],
            false,
            consumer["consumptionValue2"],
            false,
            consumer["consumptionType"],
            false,
            [],
            consumer["consumerPhases"],
            false,
            [1, 0, 0, 0],
            item,
            null,
            false,
            item.name === "Generator",
            null,
            "",
            statusConstants.NEW,
            getCutOutSizeDefaultValue(item.class),
          ),
          [],
          1,
        );
        dispatch({
          form: "groupedConnectionProperties",
          obj: new GroupedConnection(
            uuid(),
            "",
            [groupedConnectionPoint],
            "",
            defaultGroundTypeOverride,
          ),
          type: "REPLACE_STATE",
        });
      }
    }

    if (item.type === "cable") {
      const cable = `mains-${item.class.split("-")[0]}`;
      dispatch({
        form: "cableProperties",
        obj: getCableProperties(
          [],
          "",
          "",
          0,
          cableDefaults[cable],
          false,
          null,
          cable,
          false,
          getTransformerPhase(formState, transformers),
          false,
          null,
          0,
          false,
          [],
          false,
          "Default",
          "",
          statusConstants.NEW,
          null,
          fusewayStatus.CLOSED,
        ),
        type: "REPLACE_STATE",
      });
    }
  };

  const create = (layer, cableNode = false) => {
    switch (toolState.activeTool.type) {
      case "transformer":
        transformerCreated(layer);
        break;
      case "node":
        nodeCreated(layer);
        break;
      case "cable":
        if (cableNode) {
          const id = uuid();
          const _layer = [...layer.geometry].pop();
          groupedConnectionCreated(_layer, id);
          layer.endAssetId = id;
        }
        cableCreated(layer, undefined, undefined, false, [
          getAssetById(allAssets, layer.startAssetId),
          getAssetById(allAssets, layer.endAssetId),
        ]);
        break;
      case "groupedConnection":
        groupedConnectionCreated(layer);
        break;
      case "connectionPoint":
        switch (toolState.activeTool.class) {
          case "motor":
            motorCreated(layer);
            break;
          case "welder":
            welderCreated(layer);
            break;
          case "point-of-connection":
            pointOfConnectionCreated(layer);
            break;
          default:
            groupedConnectionCreated(layer);
            break;
        }
        break;
      default:
        break;
    }
  };

  const transformerCreated = (layer) => {
    let transformerProperties = formState.transformerProperties;
    transformerProperties.cyclicRating = 0;
    transformerProperties.geometry = layer;
    transformerProperties.systemVoltage = 230;
    const transformer = new Transformer(uuid(), transformerProperties);
    addToReduxNetwork(transformer);
    dispatchRedux(addTransformer(transformer));
  };

  const motorCreated = (layer) => {
    let motorProperties = formState.motorProperties;

    motorProperties.geometry = layer;
    const motor = new Motor(uuid(), motorProperties);
    addToReduxNetwork(motor);
    dispatchRedux(addMotor(motor));
  };

  const welderCreated = (layer) => {
    let welderProperties = formState.welderProperties;

    welderProperties.geometry = layer;
    const welder = new Welder(uuid(), welderProperties);
    addToReduxNetwork(welder);
    dispatchRedux(addWelder(welder));
  };

  const pointOfConnectionCreated = (layer) => {
    let pointOfConnectionProperties = formState.pointOfConnectionProperties;

    pointOfConnectionProperties.geometry = layer;
    const pointOfConnection = new PointOfConnection(uuid(), pointOfConnectionProperties);
    addToReduxNetwork(pointOfConnection);
    dispatchRedux(addPointOfConnection(pointOfConnection));
  };

  const nodeCreated = (layer) => {
    const nodeProperties = new NodeProperties();
    nodeProperties.geometry = layer;
    nodeProperties.styles = getAssetStyleInfo("node");
    const node = new Node(uuid(), nodeProperties);
    addToReduxNetwork(node);
    dispatchRedux(addNode(node));
  };

  const isFirstBranch = (layer) => {
    return transformers.find(
      (transformer) => transformer.id === layer.startAssetId || transformer.id === layer.endAssetId,
    )
      ? true
      : false;
  };

  const getFeederNumber = (nodeIds) => {
    const transformer = transformers.find((p) => nodeIds.includes(p.id));

    const feeders = cables
      .filter(
        (f) =>
          f.isFirstLeg !== "false" &&
          f.feederNumber !== null &&
          isPositiveNonDecimalNumber(f.feederNumber) &&
          transformer &&
          (f.startAssetId === transformer.id || f.endAssetId == transformer.id),
      )
      .sort((a, b) => parseInt(a.feederNumber) - parseInt(b.feederNumber));

    for (var i = 1; i <= feeders.length; i++) {
      if (feeders[i - 1].feederNumber > i) {
        return i;
      }
    }

    return feeders.length + 1;
  };

  const cableCreated = (layer, newNodeId, overrideFactor, isSplitCable, nodes) => {
    const geometry = layer.geometry.map((geo) => L.latLng(geo));
    const lineLength = geometry.reduce((acc, val, idx, arr) => {
      if (idx > 0) return acc + arr[idx - 1].distanceTo(val);
      return acc;
    }, 0);

    let cableProperties = formState.cableProperties;

    if (overrideFactor !== undefined && cableProperties.overrideLength) {
      cableProperties.overrideLength = (lineLength * overrideFactor).toFixed(2);
    }

    cableProperties.geometry = geometry;
    cableProperties.feederNumber = isFirstBranch(layer)
      ? getFeederNumber([layer.startAssetId, layer.endAssetId])
      : null;
    cableProperties.startAssetId = layer.startAssetId;
    cableProperties.endAssetId = layer.endAssetId;
    cableProperties.length = lineLength.toFixed(2);
    cableProperties.fuseSelection = "Default";

    let groundType = defaultGroundTypeOverride;
    if (cableProperties.groundTypeOverrides && cableProperties.groundTypeOverrides.length > 0) {
      groundType = cableProperties.groundTypeOverrides[0].groundType;
    }

    cableProperties.groundTypeOverrides = [
      {
        groundType: groundType,
        length: cableProperties.overrideLength
          ? cableProperties.overrideLength
          : lineLength.toFixed(2),
      },
    ];

    resetDistributedConsumerId(cableProperties);
    const cable = new Cable(uuid(), cableProperties);
    addToReduxNetwork(cable);
    dispatchRedux(addCable(cable));
    createPoles(
      clientSettings,
      nodes,
      newNodeId,
      [...cables, cable],
      dispatchRedux,
      reference,
      undefined,
      undefined,
      isSplitCable,
    );
    let _toolState = toolState;
    _toolState.drawCable = false;
    setToolState(_toolState);

    return cable;
  };

  const getGroupedConnection = (id) => {
    const createGroupedConnectionProperties = () => {
      return new GroupedConnection(id, "", [], "", defaultGroundTypeOverride);
    };
    return id ? createGroupedConnectionProperties() : formState.groupedConnectionProperties;
  };

  const groupedConnectionCreated = (layer, id = "") => {
    let groupedConnection = getGroupedConnection(id);

    var layerGeometry = layer;
    groupedConnection.geometry = layerGeometry;
    groupedConnection.status = statusConstants.NEW;
    //update each groupConnectionPoint geometry
    groupedConnection.groupedConnectionPoints.forEach((gcp) => {
      gcp.geometry = layerGeometry;
      gcp.isGenerator = gcp.styles.name === "Generator" ? true : false;

      //update each connectionPoint geometry
      gcp.subGroupConnectionPoints.forEach((cp) => (cp.geometry = layerGeometry));
    });

    addToReduxNetwork(groupedConnection, id ? getAssetStyleInfo("node") : false);
    dispatchRedux(addGroupedConnection(groupedConnection));

    var groupedConnectionPoints = [];
    const _id = id ? id : uuid();

    if (toolState.activeTool.type !== "groupedConnection") {
      groupedConnectionPoints = JSON.parse(
        JSON.stringify(groupedConnection.groupedConnectionPoints),
      );

      const updateIds = (points, parentId) => {
        if (!points || points.length === 0) {
          return;
        }

        points.forEach((point) => {
          point.id = uuid();
          if (point.parentId) {
            point.parentId = parentId;
          }

          updateIds(point.subGroupConnectionPoints, point.id);
        });
      };

      updateIds(groupedConnectionPoints, _id);
    }

    dispatch({
      form: "groupedConnectionProperties",
      obj: new GroupedConnection(_id, "", groupedConnectionPoints, "", defaultGroundTypeOverride),
      type: "REPLACE_STATE",
    });
  };

  const addToReduxNetwork = (assetId, styles = false) => {
    const _toolState = toolState;
    _toolState.showResults = false;
    _toolState.errors = {};
    setToolState(_toolState);
    dispatchRedux(setNetworkCreated(true));
    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    const _formState = { ...formState };
    _formState.network.existing = false;

    assetId.styles = styles ? styles : toolState.activeTool;
    setRenderId(uuid());
  };

  const updateNetwork = (assetId, assetType, command) => {
    const _toolState = toolState;
    _toolState.showResults = false;
    _toolState.errors = {};
    setToolState(_toolState);
    dispatchRedux(clearResults(getResultProperties(clientSettings)));

    const _formState = { ...formState };
    _formState.network.existing = false;

    if (command === "remove" || command === "removeFromSplit") {
      if (assetType === "groupedConnections") {
        removeGroupedConnection(assetId);
      } else if (assetType === "pointOfConnections") {
        const asset = pointOfConnections.find((a) => a.id === assetId);
        if (asset) {
          removePointOfConnection(reference, cables, asset, dispatchRedux);
          dispatchRedux(deletePointOfConnection(assetId));
        }
      } else {
        if (canRemoveNetworkAsset(cables, assetId, assetType)) {
          if (assetType === "cables" && command === "remove") {
            removePolesForCable(assetId, allAssets, cables, dispatchRedux);
            removeLinkBoxCable(clientSettings, cables, groupedConnections, assetId, dispatchRedux);
          }
          if (assetType === "welders") {
            dispatchRedux(deleteWelder(assetId));
          } else if (assetType === "motors") {
            dispatchRedux(deleteMotor(assetId));
          } else if (assetType === "transformers") {
            dispatchRedux(deleteTransformer(assetId));
          } else if (assetType === "groupedConnections") {
            dispatchRedux(deleteGroupedConnection(assetId));
          } else if (assetType === "nodes") {
            dispatchRedux(deleteNode(assetId));
          } else if (assetType === "connectionPoints") {
            dispatchRedux(deleteConnectionPoint(assetId));
          } else if (assetType === "cables") {
            dispatchRedux(deleteCable(assetId));
          } else {
            console.error(
              `Unexpected type found.  Current type: ${assetType}.  Expected: welders, motors, transformers, groupedConnections, nodes, connectionPoints, cables`,
            );
          }
        }
      }
    }
    dispatch({
      form: "network",
      obj: _formState.network,
      type: "REPLACE_STATE",
    });
    setRenderId(uuid());
  };

  const removeGroupedConnection = (assetId) => {
    const index = groupedConnections.findIndex((x) => x.id === assetId);

    if (index > -1) {
      const isConnected = cables.some(
        (c) => c.startAssetId === assetId || c.endAssetId === assetId,
      );

      let groupedConnection = groupedConnections[index];

      if (groupedConnection.styles.class === "connection") {
        groupedConnection.groupedConnectionPoints.forEach((gcp) => {
          cables
            .filter((c) => c.startAssetId === gcp.id)
            .forEach((c) =>
              dispatchRedux(
                updateCable({
                  id: c.id,
                  name: "startAssetId",
                  value: gcp.parentId,
                }),
              ),
            );
        });
      }

      dispatchRedux(
        updateGroupedConnection({
          id: groupedConnection.id,
          name: "styles",
          value: getAssetStyleInfo("node"),
        }),
      );

      dispatchRedux(deleteGroupedConnectionPoints(assetId));

      if (!isConnected) {
        dispatchRedux(deleteGroupedConnection(assetId));
      }
    }
  };

  const remove = (e) => {
    store.dispatch(startBatchAction());
    if (e.styles.type === "cable") dispatchRedux(resetFeederNumbers());
    const networkAsset = getNetworkAsset(e, allAssets);
    updateNetwork(networkAsset.id, networkAsset.type, "remove");
    store.dispatch(endBatchAction());
  };

  const removeFromSplit = (e) => {
    if (e.styles.type === "cable") dispatchRedux(resetFeederNumbers());
    const networkAsset = getNetworkAsset(e, allAssets);
    updateNetwork(networkAsset.id, networkAsset.type, "removeFromSplit");
  };

  const handleRagNetworkTransformerSelected = (asset) => {
    const _formState = { ...formState };

    var filtered = _formState.ragNetworks.filter((e) => {
      return e.transformers[0].id !== asset.id;
    });

    setToolState({
      searchValue: "",
    });

    dispatch({
      form: "ragNetworks",
      obj: filtered,
      type: "REPLACE_STATE4",
    });
  };

  const updateRingfenced = (asset) => {
    const _toolState = toolState;
    const hasAsset = toolState.ringfenced.some((t) => t.id === asset.id);
    let ringfenced = [];

    if (hasAsset) {
      ringfenced = [...toolState.ringfenced].filter((t) => t.id !== asset.id);
    } else {
      ringfenced = [...toolState.ringfenced, asset];
    }

    const ringfencedTypes = getRingfencedTypes(ringfenced);
    _toolState.ringfenced = ringfenced;
    _toolState.ringfencedTypes = ringfencedTypes;
    _toolState.ringfencedFiltered = ringfenced;
    _toolState.disableBoundary = true;
    _toolState.activeTab = tab.PROPERTIES;
    _toolState.ringfenceUpdated = false;
    setToolState(_toolState);
  };

  const handleSelect = (asset, e = false) => {
    removeTransformPathLayers();
    if (mode === "split-cable" && asset.styles.type !== "cable") return;
    if (mode === "re-shape" && asset.styles.type !== "cable") return;
    if (mode === "select-results") {
      flushSync(() => {
        const _toolState = { ...toolState };
        _toolState.activeTool = "";
        _toolState.clickedAsset = null;
        _toolState.selectedSitePlanId = undefined;
        _toolState.popupResultsAssetId = asset.id;
        setToolState(_toolState);
      });
      return;
    }
    if (mode === "draw") {
      if (activeTool.type === "cable") {
        if (asset.styles.type === "cable") return;
        let _toolState = toolState;
        let _drawCable = {};
        if (drawCable) {
          if (drawCable.startAssetId === asset.id) return;
          _drawCable = drawCable;
          _drawCable.endAssetId = asset.id;
          _drawCable.geometry.push([asset.geometry.lat, asset.geometry.lng]);
          _toolState.drawCable = _drawCable;
          _toolState.clickedAsset = null;
          _toolState.searchValue = null;
          _toolState.matchedSearchIds = [];
          setToolState(_toolState);
          create(drawCable);
        } else {
          _drawCable.startAssetId = asset.id;
          _drawCable.endAssetId = null;
          _drawCable.geometry = [[asset.geometry.lat, asset.geometry.lng]];
          _toolState.drawCable = _drawCable;
          _toolState.clickedAsset = asset;
          setToolState(_toolState);
        }
      }
    } else if (mode === "remove") {
      remove(asset);
    } else if (mode === "select-group" || mode === "remove-group") {
      if (keyDown.shiftKey) {
        const _toolState = toolState;
        _toolState.drawBoundary = false;
        _toolState.groupClick = _toolState.groupClick + 1;
        setToolState(_toolState);
        updateRingfenced(asset);
      }
    } else {
      if (asset.styles.type === "connectionPoint") {
        if (asset.styles.class === "motor") {
          dispatch({
            form: "motorProperties",
            obj: getMotorProperties(
              Number(asset.rating),
              asset.ratingIsDefault,
              asset.ratingUnit,
              asset.numberOfPhases,
              asset.numberOfPhasesIsDefault,
              asset.starter,
              asset.starterIsDefault,
              Number(asset.startingPowerFactor),
              asset.startingPowerFactorIsDefault,
              asset.startingCurrentOverride,
              Number(asset.numberOfStarts),
              asset.numberOfStartsIsDefault,
              asset.numberOfStartsUnit,
              Number(asset.efficiency),
              asset.efficiencyIsDefault,
              Number(asset.hvFaultLevel),
              Number(asset.xrRatio),
              asset.geometry,
              asset.annotation,
            ),
            type: "REPLACE_STATE",
          });
        } else if (asset.styles.class === "welder") {
          dispatch({
            form: "welderProperties",
            obj: getWelderProperties(
              Number(asset.rating),
              asset.ratingIsDefault,
              asset.ratingUnit,
              asset.numberOfPhases,
              asset.numberOfPhasesIsDefault,
              Number(asset.numberOfBursts),
              asset.numberOfBurstsIsDefault,
              asset.numberOfBurstsUnit,
              asset.pointOfWaveControl,
              Number(asset.powerFactor),
              asset.powerFactorIsDefault,
              Number(asset.efficiency),
              asset.efficiencyIsDefault,
              Number(asset.hvFaultLevel),
              Number(asset.xrRatio),
              asset.annotation,
              Number(asset.voc),
              asset.vocIsDefault,
              asset.geometry,
            ),
            type: "REPLACE_STATE",
          });
        } else if (asset.styles.class === "point-of-connection") {
          dispatch({
            form: "pointOfConnectionProperties",
            obj: getPointOfConnectionProperties(
              Number(asset.voltDropPercent),
              Number(asset.loopResistanceMilliohm),
              asset.includesTransformerResistance,
              asset.geometry,
              asset.annotation,
            ),
            type: "REPLACE_STATE",
          });
        } else {
          dispatch({
            form: "groupedConnectionProperties",
            obj: {
              ...asset,
              groupedConnectionPoints: (asset.groupedConnectionPoints ?? []).map((p) => ({
                ...p,
                subGroupConnectionPoints: [...(p.subGroupConnectionPoints ?? [])],
              })),
            },
            type: "REPLACE_STATE",
          });
        }
      }

      if (asset.styles.type === "cable") {
        dispatch({
          form: "cableProperties",
          obj: getCableProperties(
            asset.geometry,
            asset.startAssetId,
            asset.endAssetId,
            Number(asset.length),
            asset.cableType,
            asset.cableTypeIsDefault,
            asset.cableTypeOriginal,
            asset.cableGroup,
            asset.cableGroupIsDefault,
            asset.numberOfPhases,
            asset.numberOfPhasesIsDefault,
            asset.numberOfPhasesOriginal,
            Number(asset.overrideLength),
            asset.autoSelect,
            asset.groupedConnectionPoints,
            asset.ducting,
            asset.fuseSelection,
            asset.annotation,
            asset.status,
            asset.groundTypeOverrides,
            asset.fusewayStatus,
            asset.groundTypeOverridesIsDefault,
          ),
          type: "REPLACE_STATE",
        });
      }

      if (asset.styles.type === "transformer") {
        dispatch({
          form: "transformerProperties",
          obj: getTransformerProperties(
            Number(asset.nameplateRating),
            asset.nameplateRatingIsDefault,
            Number(asset.cyclicRating),
            asset.mounting,
            asset.geometry,
            Number(asset.systemVoltage),
            asset.numberOfPhases,
            asset.numberOfPhasesIsDefault,
            asset.fuseSelection,
            asset.fuseSelectionIsDefault,
            Number(asset.fuseRating),
            asset.autoSelect,
            asset.groupedConnectionPoints,
            Number(asset.hvFaultLevel),
            asset.hvFaultLevelIsDefault,
            Number(asset.xrRatio),
            asset.annotation,
            asset.xrRatioIsDefault,
            asset.ohEnvironment,
            asset.poleTermLength,
            asset.isDefaultPoleTermLength,
            asset.designVoltage,
          ),
          type: "REPLACE_STATE",
        });
      }

      if (asset.styles.type === "groupedConnection") {
        dispatch({
          form: "groupedConnectionProperties",
          obj: {
            ...asset,
            groupedConnectionPoints: (asset.groupedConnectionPoints ?? []).map((p) => ({
              ...p,
              subGroupConnectionPoints: [...(p.subGroupConnectionPoints ?? [])],
            })),
          },
          type: "REPLACE_STATE",
        });
      }

      flushSync(() => {
        const _toolState = { ...toolState };
        _toolState.activeTool = asset.styles;
        _toolState.sidebar = true;
        _toolState.clickedAsset = asset;
        _toolState.activeTab = tab.PROPERTIES;
        _toolState.selectedSitePlanId = undefined;
        if (asset.styles.type === "transformer") {
          _toolState.showFeederList = true;
          _toolState.searchValue = asset.substationId;
        }
        setToolState(_toolState);
      });

      if (mode === "split-cable") {
        setSplitCable(asset);
      }
      if (mode === "re-shape" && e) {
        if (asset && asset.styles.type === "cable" && e.latlng) {
          const newPoint = snapToLine(asset, e.latlng);
          if (newPoint) {
            setReshapeCablePoint([
              ...reshapeCablePoint,
              {
                assetId: asset.id,
                position: newPoint,
              },
            ]);
          }
        }
      }
    }
  };

  const handleMove = (event, asset, type) => {
    const newGeometry = {
      lat: event.target._latlng.lat,
      lng: event.target._latlng.lng,
    };
    store.dispatch(startBatchAction());

    function handleUpdateCable(cable, originalGeometry, targetGeometry, anchors = []) {
      const start = cable.geometry[0];
      const end = cable.geometry[cable.geometry.length - 1];

      let pointToReplace;
      if (calculateDistance(start, originalGeometry) < calculateDistance(end, originalGeometry)) {
        pointToReplace = start;
      } else {
        pointToReplace = end;
      }

      const updatedGeometry = insertPointOnPath(
        cable.geometry,
        anchors,
        pointToReplace,
        targetGeometry,
      );

      dispatchRedux(updateCable({ id: cable.id, name: "geometry", value: updatedGeometry }));

      const newLength = calculateLength(updatedGeometry);

      dispatchRedux(updateCable({ id: cable.id, name: "length", value: newLength }));

      if (!(cable.overrideLength > 0) && cable.groundTypeOverrides?.length === 1) {
        dispatchRedux(
          updateCableArray({
            id: cable.id,
            arrayProperty: "groundTypeOverrides",
            index: 0,
            name: "length",
            value: newLength,
          }),
        );
      }
    }

    function updateState(singleAsset, updateType, targetGeometry) {
      dispatchRedux(
        updateType({
          id: singleAsset.id,
          name: "geometry",
          value: targetGeometry,
        }),
      );

      if (
        updateType === updateGroupedConnection &&
        singleAsset.groupedConnectionPoints?.length > 0
      ) {
        singleAsset.groupedConnectionPoints.forEach((q) => {
          dispatchRedux(
            updateGroupedConnectionChild({
              id: singleAsset.id,
              childUpdate: {
                id: q.id,
                name: "geometry",
                value: targetGeometry,
              },
              isGroupUpdate: false,
            }),
          );
        });
      }
    }

    if (mode === "select-group") {
      const latestAsset = allGeometryAssets.find((p) => p.asset.id === asset.id);
      const offsetLat = newGeometry.lat - latestAsset.geometry.lat;
      const offsetLng = newGeometry.lng - latestAsset.geometry.lng;
      const updatedElements = [];

      ringfenced.forEach((singleAsset) => {
        const currentAsset = allGeometryAssets.find((q) => q.asset.id === singleAsset.id);
        if (
          currentAsset.geometry.lat &&
          currentAsset.geometry.lng &&
          !currentAsset.geometry.length
        ) {
          const targetGeometry = new LatLng(
            currentAsset.geometry.lat + offsetLat,
            currentAsset.geometry.lng + offsetLng,
          );
          const originalGeometry = currentAsset.geometry;
          const updateType = (() => {
            switch (singleAsset.styles.type) {
              case "welder":
                return updateWelder;
              case "motor":
                return updateMotor;
              case "pointOfConnection":
                return updatePointOfConnection;
              case "transformer":
                return updateTransformer;
              case "groupedConnection":
              case "node":
              case "connectionPoint":
                return updateGroupedConnection;
              default:
                console.error(
                  `Unexpected type found.  Current type: ${singleAsset.styles.type}.  Expected: welder, motor, pointOfConnection, transformer, groupedConnection, node, connectionPoint`,
                );
                return undefined;
            }
          })();
          if (updateType) {
            updateState(singleAsset, updateType, targetGeometry);
          }
          updatedElements.push({
            assetId: currentAsset.asset.id,
            originalGeometry,
            targetGeometry,
          });
        } else if (currentAsset.geometry.length > 1) {
          const targetGeometry = currentAsset.geometry.map(
            (p) => new LatLng(p.lat + offsetLat, p.lng + offsetLng),
          );
          dispatchRedux(
            updateCable({
              id: singleAsset.id,
              name: "geometry",
              value: targetGeometry,
            }),
          );
        }
      });

      const affectedCables = cables.filter(
        (p) =>
          (updatedElements.some((q) => q.assetId === p.startAssetId) &&
            !updatedElements.some((q) => q.assetId === p.endAssetId)) ||
          (updatedElements.some((q) => q.assetId === p.endAssetId) &&
            !updatedElements.some((q) => q.assetId === p.startAssetId)),
      );

      affectedCables.forEach((cable) => {
        const { originalGeometry, targetGeometry } = updatedElements.find((p) =>
          [cable.startAssetId, cable.endAssetId].includes(p.assetId),
        );
        handleUpdateCable(cable, originalGeometry, targetGeometry);
      });
    } else {
      const originalGeometry = asset.geometry;

      updateState(asset, type, newGeometry);

      cables
        .filter((p) => [p.startAssetId, p.endAssetId].includes(asset.id))
        .forEach((cable) => {
          const anchors = reshapeCablePoint
            .filter((p) => p.assetId === cable.id)
            .map((p) => p.position);
          handleUpdateCable(cable, originalGeometry, newGeometry, anchors);
        });
    }

    hideResults();

    store.dispatch(endBatchAction());
  };

  const createSplitNode = (geometry) => {
    const _formState = { ...formState };

    let groupedConnection = _formState.groupedConnectionProperties;
    groupedConnection.id = uuid();
    groupedConnection.annotation = "";
    groupedConnection.geometry = geometry;
    groupedConnection.groupedConnectionPoints = [];
    groupedConnection.styles = getAssetStyleInfo("node");
    groupedConnection.pole = undefined;
    groupedConnection.linkBox = undefined;

    dispatchRedux(addGroupedConnection(groupedConnection));

    dispatch({
      form: "groupedConnectionProperties",
      obj: new GroupedConnection(
        groupedConnection.id,
        geometry,
        [],
        getAssetStyleInfo("node"),
        defaultGroundTypeOverride,
      ),
      type: "REPLACE_STATE",
    });

    return groupedConnection;
  };

  const splitters = (lat, lng, length = 100) => {
    // create vertical line at clicked point
    const latSplitter = lineString([
      [lat - length, lng],
      [lat + length, lng],
    ]);

    // create horizontal line at clicked point
    const lngSplitter = lineString([
      [lat, lng - length],
      [lat, lng + length],
    ]);

    return { latSplitter, lngSplitter };
  };

  const handleSplitCable = (originalCable) => {
    if (!originalCable || !originalCable.styles.type === "cable" || !toolState?.currentLatLng) {
      setSplitCable(undefined);
      return;
    }

    store.dispatch(startBatchAction());
    try {
      const cable = { ...originalCable };

      // Make sure cable.geo is always an array
      if (!Array.isArray(cable.geometry)) {
        cable.geometry = [cable.geometry];
      }

      // If any of the cable.geometry is undefined, return
      if (cable.geometry.some((a) => !a)) return;

      const points = cable.geometry.map((a) => [a.lat, a.lng]);

      // If the length of points is less than 2, return
      if (points.length < 2) return;

      const line = lineString(points);

      const { lat, lng } = toolState.currentLatLng;
      const { latSplitter, lngSplitter } = splitters(lat, lng, 0.00001);

      // split cable by vertical line
      let split = lineSplit(line, latSplitter);
      // if not two features, split cable by horizontal line instead
      if (split.features?.length !== 2) {
        split = lineSplit(line, lngSplitter);
      }

      const coords = split?.features?.[0]?.geometry?.coordinates;
      const splitCoord = coords?.[coords?.length - 1];

      if (!splitCoord) return;
      if (coords.length < 1) return;

      const nodeGeometry = {
        lat: splitCoord[0],
        lng: splitCoord[1],
      };

      const node = createSplitNode(nodeGeometry);
      const nodeId = node.id;

      const endAssetId = cable.endAssetId;
      const startAssetId = cable.startAssetId;
      const originalCableId = cable.id;

      const overrideFactor = cable.length === 0 ? 1 : cable.overrideLength / cable?.length;

      removeFromSplit(cable);

      if (line.geometry.coordinates?.length > 2) {
        cable.geometry = split.features[0].geometry.coordinates.map((a) => ({
          lat: a[0],
          lng: a[1],
        }));
      } else {
        cable.geometry = [
          [line.geometry.coordinates[0][0], line.geometry.coordinates[0][1]],
          splitCoord,
        ];
      }

      cable.startAssetId = startAssetId;
      cable.endAssetId = nodeId;
      const newCable1 = cableCreated(cable, nodeId, overrideFactor, true, [
        getAssetById(allAssets, startAssetId),
        node,
      ]);

      let lastCable = { ...cable };

      if (line.geometry.coordinates?.length > 2) {
        lastCable.geometry = split.features[1].geometry.coordinates.map((a) => ({
          lat: a[0],
          lng: a[1],
        }));
      } else {
        lastCable.geometry = [
          splitCoord,
          [line.geometry.coordinates[1][0], line.geometry.coordinates[1][1]],
        ];
      }

      lastCable.startAssetId = nodeId;
      lastCable.endAssetId = endAssetId;
      const newCable2 = cableCreated(lastCable, nodeId, overrideFactor, true, [
        node,
        getAssetById(allAssets, endAssetId),
      ]);

      attachSplitCableToLinkBox(
        clientSettings,
        groupedConnections,
        originalCableId,
        newCable1.id,
        startAssetId,
        dispatchRedux,
      );
      attachSplitCableToLinkBox(
        clientSettings,
        groupedConnections,
        originalCableId,
        newCable2.id,
        endAssetId,
        dispatchRedux,
      );

      handleSelectedItem(0, getAssetStyleInfo("select"), 0);
      handleSelectedItem(3, getAssetStyleInfo("editCable"), 3);
    } finally {
      setSplitCable(undefined);
      store.dispatch(endBatchAction());
    }
  };

  const handleSitePlanClick = (id) => {
    if (toolState.drawCable) return;
    if (toolState.mode === "select-results") return;
    const _toolState = { ...toolState };
    _toolState.selectedSitePlanId = id;
    _toolState.activeTool = "";
    _toolState.activeCategory = 0;
    _toolState.clickedAsset = null;
    _toolState.drawMarker = false;
    _toolState.drawCable = false;
    _toolState.drawBoundary = false;
    _toolState.ringfenced = [];
    _toolState.ringfencedFiltered = [];
    _toolState.ringfencedTypes = [];
    _toolState.disableBoundary = false;
    _toolState.activeTab = tab.PROPERTIES;
    _toolState.searchValue = null;
    _toolState.matchedSearchIds = [];
    setToolState(_toolState);
  };

  const undo = () => {
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    setToolState(_toolState);
    store.dispatch(undoAction());
  };
  const redo = () => {
    const _toolState = toolState;
    resetNetworkToolState(_toolState);
    setToolState(_toolState);
    store.dispatch(redoAction());
  };

  useMapEvents({
    mousemove(e) {
      const r = document.querySelector(":root");
      const translate3d = e.target._panes.mapPane.style.transform;
      const [, x, y] = translate3d.match(/translate3d\((-?[\d.]+)px, (-?[\d.]+)px, (-?[\d.]+)px\)/);
      r.style.setProperty("--x", `${x}px`);
      r.style.setProperty("--y", `${y}px`);
    },
    click(e) {
      const r = document.querySelector(":root");
      const translate3d = e.target._panes.mapPane.style.transform;
      const [, x, y] = translate3d.match(/translate3d\((-?[\d.]+)px, (-?[\d.]+)px, (-?[\d.]+)px\)/);
      r.style.setProperty("--x", `${x}px`);
      r.style.setProperty("--y", `${y}px`);

      if (toolState.mode === "split-cable") {
        const _toolState = toolState;
        _toolState.currentLatLng = e.latlng;
        if (e.type === "click") {
          _toolState.mouseEvent = e.type;
          setToolState(_toolState);
        }
      }
    },
  });

  return (
    <>
      {toolState.activeTool.type !== "cable" && mode === "draw" && <MarkerTool create={create} />}
      {toolState.activeTool.type === "cable" && mode === "draw" && (
        <CableTool overrideLength={overrideLength} create={create} />
      )}
      {!keyDown.shiftKey && (mode === "select-group" || mode === "remove-group") && (
        <RingfenceTool />
      )}
      {mode === "re-shape" && reshapeCablePoint.length > 0 && (
        <ReshapeTool points={reshapeCablePoint} replacePoint={replacePoint} />
      )}
      <ExitMessage />
      {clientSettings.features.DrawingToolsEnabled && (
        <Control positionY="top" positionX="left" size="sm" className="d-none d-sm-block">
          <IconDropDrownMenu
            menuItems={toolbar}
            selectedItem={selectedTool}
            activeCategory={activeCategory}
            selectItem={handleSelectedItem}
            undo={undo}
            redo={redo}
          />
        </Control>
      )}
      <Control positionY="bottom" positionX="left" size="sm">
        <ButtonGroup vertical>
          <CenterMapControl network={network} allGeometries={allGeometries} />
          <ZoomControl />
        </ButtonGroup>
      </Control>
      <ScaleControl position="bottomright" />
      {clientSettings.features.NetworkSearchEnabled && (
        <>
          <NearbyAssets assetType="substation" />
        </>
      )}
      <MapNavigate />
      <Network
        networkId={renderId}
        handleSelect={handleSelect}
        clickedAsset={clickedAsset}
        handleMove={handleMove}
        reshapeCablePoints={reshapeCablePoint}
        renderer={renderer}
        fallbackRenderer={fallbackRenderer}
      />
      {clientSettings.features.RAGCablesEnabled && (
        <RagNetwork ragNetworks={ragNetworks} handleSelect={handleRagNetworkTransformerSelected} />
      )}
      {clientSettings.features.ImpedanceFaultDetectionEnabled && (
        <DetectNetwork
          detectNetworks={detectNetworks}
          handleSelect={handleSelect}
          clickedAsset={clickedAsset}
        />
      )}
      <NavMenu
        mode={mode}
        menuItems={toolbar}
        selectedItem={selectedTool}
        activeCategory={activeCategory}
        selectItem={handleSelectedItem}
      />
      <Pane name="popup-results" style={{ zIndex: 600 }}>
        {useSelector((state) => getAllGeometryAssets(state)).map((asset) => (
          <>
            {clientSettings.features.AssessmentResultsPopupEnabled && popupResults && (
              <PopupResults asset={asset.asset} iconSize={iconSize} />
            )}
          </>
        ))}
      </Pane>
      <Pane name="site-plan" style={{ zIndex: 510 }}>
        {sitePlans
          .filter((f) => f.id !== selectedSitePlanId)
          .map((sitePlan) => (
            <SitePlanImport
              key={sitePlan.id}
              selectedObject={false}
              sitePlan={sitePlan}
              onClick={() => handleSitePlanClick(sitePlan.id)}
            />
          ))}
        {sitePlans
          .filter((f) => !refreshSitePlan && f.id === selectedSitePlanId)
          .map((sitePlan) => (
            <SitePlanImport
              key={sitePlan.id}
              selectedObject={true}
              sitePlan={sitePlan}
              onClick={() => {}}
            />
          ))}
      </Pane>
    </>
  );
};

export const resetDistributedConsumerId = (cableProperties) => {
  const groupedConnectionPoints = [...cableProperties.groupedConnectionPoints];
  cableProperties.groupedConnectionPoints = [];
  groupedConnectionPoints.forEach((gcp) => {
    const subGroupConnectionPoints = [...gcp.subGroupConnectionPoints];

    let gcpCopy = { ...gcp, id: uuid() };

    gcpCopy.subGroupConnectionPoints = [];

    subGroupConnectionPoints.forEach((cp) => {
      gcpCopy.subGroupConnectionPoints.push({
        ...cp,
        id: uuid(),
        parentId: gcpCopy.id,
      });
    });

    cableProperties.groupedConnectionPoints.push(gcpCopy);
  });
};

export default MapControls;
