import { lineString } from "@turf/turf";
import length from "@turf/length";
import along from "@turf/along";
import { v4 as uuid } from "uuid";
import getAssetStyleInfo from "../context/AssetStyleInfo";

export const mapCableRouteBackToTransformer = (networkCables, faultyCable) => {
  let cables = [];
  let nodeNumber = faultyCable.farNodeNumber;
  cables.push(faultyCable);
  while (nodeNumber > 1) {
    const getNextCable = (networkCables, nodeNumber) => {
      return networkCables.find((n) => n.farNodeNumber === nodeNumber);
    };
    let cable = getNextCable(networkCables, nodeNumber);
    nodeNumber = cable.nearNodeNumber;
    cables.push(cable);
  }
  return cables;
};

export const getFaultMarker = (cable, cables, transformers, groupedConnections, color) => {
  const line = lineString(cable.geometry.map((a) => [a.lng, a.lat]));
  const fullCableLength = length(line, { units: "meters" });

  const impedancePercentOfCable = calcImpedancePositionPercentage(cable);
  const cableLength = (fullCableLength / 100) * impedancePercentOfCable;

  const markerLocation = along(line, cableLength, {
    units: "meters",
  });

  return createMarker(
    markerLocation,
    cableLength,
    fullCableLength,
    cables,
    cable,
    transformers,
    groupedConnections,
    color,
  );
};

const getDistanceFromCableToTransformer = (cables, lengthToMarkerPosition, faultyCableId) => {
  var distance = 0;

  cables
    .filter((c) => {
      return c.id !== faultyCableId;
    })
    .forEach((cable) => (distance += parseFloat(cable.length)));

  return distance + lengthToMarkerPosition;
};

const getImpedance = (cable) => {
  return cable.impedanceType === "Loop"
    ? [
        cable.nearNode ? cable.nearNode.loopImpedance : 0,
        cable.farNode ? cable.farNode.loopImpedance : 0,
      ]
    : [
        cable.nearNode ? cable.nearNode.phaseImpedance : 0,
        cable.farNode ? cable.farNode.phaseImpedance : 0,
      ];
};

const calcImpedancePositionPercentage = (cable) => {
  const impedances = getImpedance(cable);
  let difference = parseFloat(impedances[1]) - parseFloat(impedances[0]);

  const startFromNearNode = difference > 0;
  if (!startFromNearNode) difference = difference * 100;

  const numberToSubtractToMakeZero = startFromNearNode
    ? parseFloat(impedances[0])
    : parseFloat(impedances[1]);
  const impedance = parseFloat(cable.impedance.impedance) - numberToSubtractToMakeZero;
  const percentage = (100 / difference) * impedance;

  return startFromNearNode ? percentage : 100 - percentage;
};

const createMarker = (
  markerLocation,
  cableLength,
  fullCableLength,
  cables,
  cable,
  transformers,
  groupedConnections,
  color,
) => {
  var useNearNode = cableLength * 2 < fullCableLength;

  var marker = {
    id: uuid(),
    styles: getAssetStyleInfo("map-marker"),
    geometry: {
      lat: markerLocation.geometry.coordinates[1],
      lng: markerLocation.geometry.coordinates[0],
    },
    distanceToTransformer: getDistanceFromCableToTransformer(cables, cableLength, cable.id),
    distanceToClosestNode:
      cableLength * 2 < fullCableLength ? cableLength : fullCableLength - cableLength,
    node: getNodeOrTransformer(transformers, groupedConnections, cable, useNearNode),
    color: color,
    impedance: cable.impedance.impedance,
  };

  return marker;
};

const getNodeOrTransformer = (transformers, groupedConnections, cable, useNearNode) => {
  if (useNearNode && cable.nearNodeNumber === "1") {
    return transformers.find((t) => t.nodeNumber === cable.nearNodeNumber);
  }

  if (!useNearNode && cable.farNodeNumber === "1") {
    return transformers.find((t) => t.nodeNumber === cable.farNodeNumber);
  }

  return groupedConnections.find((gc) =>
    gc.nodeNumber === useNearNode ? cable.nearNodeNumber : cable.farNodeNumber,
  );
};
