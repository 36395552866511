// @flow
import { LatLng } from "./simpleTypes";
import { GroupedConnectionPoint } from "../viewModel/groupedConnectionPoint";

export type TransformerMounting = "PMT" | "GMT";

export interface ITransformerProperties {
  nameplateRating: number;
  nameplateRatingIsDefault: Boolean;
  nameplateRatingAssigned: number;
  cyclicRating: number;
  mounting: TransformerMounting;
  geometry: LatLng;
  systemVoltage: number;
  maximumDemandKVA: number;
  maximumDemandKVAPeriod: number;
  maximumUtilisationPercent: number;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  fuseSelection: string;
  fuseSelectionIsDefault: Boolean;
  fuseRating: number;
  maxVoltDropPercent: number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: number;
  maximumExportKVA: number;
  maxVoltRiseGeneration: number;
  maxVoltRiseUnbalance: number;
  autoSelect: boolean;
  groupedConnectionPoints: GroupedConnectionPoint[];
  ratingType: string;
  AllmaximumDemandKVA: number;
  AllmaximumDemandKVAPeriod: number;
  AllmaximumUtilisationPercent: number;
  AllmaxVoltDropPercent: number;
  AllmaxVoltDropPercentPeriod: number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltRiseTotal: number;
  AllmaximumExportKVA: number;
  AllmaxVoltRiseGeneration: number;
  AllmaxVoltRiseUnbalance: number;
  AllmaxVoltRisePercentPeriod: number;
  wintermaximumDemandKVA: number;
  wintermaximumDemandKVAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaxVoltDropPercent: number;
  wintermaxVoltDropPercentPeriod: number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltRisePercent: number;
  wintermaxVoltRiseGeneration: number;
  wintermaxVoltRiseUnbalance: number;
  wintermaxVoltRisePercentPeriod: number;
  wintermaxVoltRisePercentPeriod: number;
  summermaximumDemandKVA: number;
  summermaximumDemandKVAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaxVoltDropPercent: number;
  summermaxVoltDropPercentPeriod: number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltRisePercent: number;
  summermaxVoltRiseGeneration: number;
  summermaxVoltRiseUnbalance: number;
  summermaxVoltRisePercentPeriod: number;
  summermaxVoltRisePercentPeriod: number;
  nodeNumber: number;
  hvFaultLevel: number;
  hvFaultLevelIsDefault: Boolean;
  xrRatio: number;
  annotation: string;
  xrRatioIsDefault: Boolean;
  flickerP28Primary: number;
  flickerP28PrimaryPassResult: Boolean;
  flickerP28Secondary: number;
  flickerP28SecondaryPassResult: Boolean;
  cascadingRAG: string;
  nonCascadingRAG: string;
  ohEnvironment: string;
  poleTermLength: number;
  isDefaultPoleTermLength: Boolean;
  designVoltage: number;
}

export class TransformerProperties implements ITransformerProperties {
  nameplateRating: number;
  nameplateRatingIsDefault: Boolean;
  nameplateRatingAssigned: number;
  cyclicRating: number;
  mounting: TransformerMounting;
  geometry: LatLng;
  systemVoltage: number;
  maximumDemandKVA: number;
  maximumDemandKVAPeriod: number;
  maximumUtilisationPercent: number;
  styles: any;
  numberOfPhases: string;
  numberOfPhasesIsDefault: Boolean;
  fuseSelection: string;
  fuseSelectionIsDefault: Boolean;
  fuseRating: number;
  maxVoltDropPercent: number;
  maxVoltDropPercentPassResult: Boolean;
  maxVoltRiseTotal: number;
  maximumExportKVA: number;
  maxVoltRiseGeneration: number;
  maxVoltRiseUnbalance: number;
  autoSelect: boolean;
  groupedConnectionPoints: GroupedConnectionPoint[];
  ratingType: string;
  AllmaximumDemandKVA: number;
  AllmaximumDemandKVAPeriod: number;
  AllmaximumUtilisationPercent: number;
  AllmaxVoltDropPercent: number;
  AllmaxVoltDropPercentPeriod: number;
  AllmaxVoltDropPercentPassResult: Boolean;
  AllmaxVoltRiseTotal: number;
  AllmaximumExportKVA: number;
  AllmaxVoltRiseGeneration: number;
  AllmaxVoltRiseUnbalance: number;
  AllmaxVoltRisePercentPeriod: number;
  wintermaximumDemandKVA: number;
  wintermaximumDemandKVAPeriod: number;
  wintermaximumUtilisationPercent: number;
  wintermaxVoltDropPercent: number;
  wintermaxVoltDropPercentPeriod: number;
  wintermaxVoltDropPercentPassResult: Boolean;
  wintermaxVoltRisePercent: number;
  wintermaxVoltRiseTotal: number;
  wintermaximumExportKVA: number;
  wintermaxVoltRiseGeneration: number;
  wintermaxVoltRiseUnbalance: number;
  wintermaxVoltRisePercentPeriod: number;
  summermaximumDemandKVA: number;
  summermaximumDemandKVAPeriod: number;
  summermaximumUtilisationPercent: number;
  summermaxVoltDropPercent: number;
  summermaxVoltDropPercentPeriod: number;
  summermaxVoltDropPercentPassResult: Boolean;
  summermaxVoltRisePercent: number;
  summermaxVoltRiseTotal: number;
  summermaximumExportKVA: number;
  summermaxVoltRiseGeneration: number;
  summermaxVoltRiseUnbalance: number;
  summermaxVoltRisePercentPeriod: number;
  nodeNumber: number;
  objectId: number;
  hvFaultLevel: number;
  hvFaultLevelIsDefault: Boolean;
  xrRatio: number;
  annotation: string;
  xrRatioIsDefault: Boolean;
  flickerP28Primary: number;
  flickerP28PrimaryPassResult: Boolean;
  flickerP28Secondary: number;
  flickerP28SecondaryPassResult: Boolean;
  cascadingRAG: string;
  nonCascadingRAG: string;
  ohEnvironment: string;
  substationId: string;
  phaseAngle: number;
  phasesAvailable: number;
  poleTermLength: number;
  isDefaultPoleTermLength: Boolean;
  designVoltage: number;
}
