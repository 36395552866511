import React from "react";
import { MsalProvider } from "@azure/msal-react";
import "./App.css";
import MsalSwitcher from "./components/MsalSwitcher";
import ToolProvider from "./context/ToolContext";
import FormProvider from "./context/FormContext";
import { store } from "./app/store";
import { Provider } from "react-redux";

const B2XApp = ({ pca }) => {
  return (
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <ToolProvider>
          <FormProvider>
            <MsalSwitcher />
          </FormProvider>
        </ToolProvider>
      </MsalProvider>
    </Provider>
  );
};

export default B2XApp;
