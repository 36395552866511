import React from "react";
import { InputGroup } from "reactstrap";

const NetworkLoad = ({
  importFileName,
  label,
  fileInputId,
  textInputId,
  disabled = false,
  multiple = false,
  max = undefined,
  allowedFileTypes = undefined,
  placeholder = "",
}) => {
  return (
    <div className="m-3">
      <InputGroup className="mr-2" style={{ marginBottom: -8 }}>
        <label className="input-group-btn">
          <span
            className={`btn btn-primary btn-sm ${disabled ? "disabled" : ""}`}
            onChange={importFileName}
          >
            {label}
            <input
              type="file"
              id={fileInputId}
              style={{ display: "none" }}
              disabled={disabled}
              multiple={multiple}
              max={max}
              accept={allowedFileTypes}
            />
          </span>
        </label>
        <input
          type="text"
          id={textInputId}
          className="form-control form-control-sm"
          placeholder={placeholder}
          readOnly
        />
      </InputGroup>
    </div>
  );
};

export default NetworkLoad;
