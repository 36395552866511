import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import settingsReducer from "./settingsSlice";
import networkReducer from "./networkSlice";
import { undoable } from "./undoable";

const reducer = {
  settings: settingsReducer,
  network: undoable(networkReducer, {
    maxHistory: 5,
    ignoreActions: [
      "network/setTitle",
      "network/setAsSaved",
      "network/resolveAllDefaults",
      "network/clearResults",
    ],
  }),
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
