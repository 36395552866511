import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const {
  REACT_APP_B2B,
  REACT_APP_B2C,
  REACT_APP_CLIENT_ID,
  REACT_APP_B2B_CLIENT_ID,
  REACT_APP_AUTHORITY,
  REACT_APP_B2B_AUTHORITY,
  REACT_APP_SIGN_IN_POLICY_ID,
  REACT_APP_KNOWN_AUTHORITIES,
  REACT_APP_CALLBACK_URL,
  REACT_APP_B2B_CALLBACK_URL,
  REACT_APP_DOMAIN,
  REACT_APP_SCOPE,
  REACT_APP_SCOPE_PREFIX,
  REACT_APP_PORTAL_BASE_URL,
} = process.env;

const B2Bauth = {
  clientId: REACT_APP_B2B_CLIENT_ID,
  authority: REACT_APP_B2B_AUTHORITY,
  redirectUri: REACT_APP_B2B_CALLBACK_URL,
  postLogoutRedirectUri: REACT_APP_B2B_CALLBACK_URL,
};

const B2Cauth = {
  clientId: REACT_APP_CLIENT_ID,
  authority: `${REACT_APP_AUTHORITY}/${REACT_APP_SIGN_IN_POLICY_ID}`,
  knownAuthorities: [REACT_APP_KNOWN_AUTHORITIES],
  redirectUri: REACT_APP_CALLBACK_URL,
  postLogoutRedirectUri: REACT_APP_CALLBACK_URL,
  portalBaseUrl: REACT_APP_PORTAL_BASE_URL,
};

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: REACT_APP_B2B === "true" ? B2Bauth : REACT_APP_B2C === "true" ? B2Cauth : "",
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const B2Bscopes = ["User.Read"];
const B2Cscopes = [`https://${REACT_APP_DOMAIN}/${REACT_APP_SCOPE_PREFIX}/${REACT_APP_SCOPE}`];

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: REACT_APP_B2B === "true" ? B2Bscopes : REACT_APP_B2C === "true" ? B2Cscopes : "",
};
