// @flow

import getAssetStyleInfo from "../../context/AssetStyleInfo";
import ConductingEquipment from "../cim/conductingEquipment";
import { Transformer } from "./transformer";
import { TransformerProperties } from "./transformerProperties";
import { getLatLngFromWktPoint } from "./geometryWktConverter";

export function createTransformerFromConductingEquipment(
  conductingEquipment: ConductingEquipment,
  groupedConnectionPoints: GroupedConnectionPoint[] = [],
  substationId: any,
): Transformer {
  const transformerProperties = new TransformerProperties();
  const geometryString = conductingEquipment.getPropertyValue("geometry");
  transformerProperties.geometry = getLatLngFromWktPoint(geometryString);
  transformerProperties.nameplateRating = conductingEquipment.getPropertyValue("nameplateRating");
  transformerProperties.nameplateRatingIsDefault =
    conductingEquipment.getPropertyValue("nameplateRatingIsDefault") === "true";
  transformerProperties.nameplateRatingAssigned =
    conductingEquipment.getPropertyValue("nameplateRatingAssigned") ??
    conductingEquipment.getPropertyValue("nameplateRating");
  transformerProperties.autoSelect = conductingEquipment.getPropertyValue("autoSelect") === "true";
  transformerProperties.cyclicRating = conductingEquipment.getPropertyValue("cyclicRating");
  transformerProperties.mounting = conductingEquipment.getPropertyValue("mounting");
  transformerProperties.systemVoltage = conductingEquipment.getPropertyValue("systemVoltage");
  transformerProperties.maximumDemandKVA = conductingEquipment.getPropertyValue("maximumDemandKVA");
  transformerProperties.maximumExportKVA = conductingEquipment.getPropertyValue("maximumExportKVA");
  transformerProperties.maximumDemandKVAPeriod =
    conductingEquipment.getPropertyValue("maximumDemandKVAPeriod");
  transformerProperties.maximumUtilisationPercent = conductingEquipment.getPropertyValue(
    "maximumUtilisationPercent",
  );
  transformerProperties.maxVoltDropPercent =
    conductingEquipment.getPropertyValue("maxVoltDropPercent");
  transformerProperties.maxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("maxVoltDropPercentPassResult") === "true";
  transformerProperties.maxVoltRiseTotal = conductingEquipment.getPropertyValue("maxVoltRiseTotal");
  transformerProperties.maxVoltRiseGeneration =
    conductingEquipment.getPropertyValue("maxVoltRiseGeneration");
  transformerProperties.maxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("maxVoltRiseUnbalance");
  transformerProperties.numberOfPhases = conductingEquipment.getPropertyValue("numberOfPhases");
  transformerProperties.numberOfPhasesIsDefault =
    conductingEquipment.getPropertyValue("numberOfPhasesIsDefault") === "true";
  transformerProperties.styles = getAssetStyleInfo(conductingEquipment.getPropertyValue("class"));
  transformerProperties.fuseSelection = conductingEquipment.getPropertyValue("fuseSelection");
  transformerProperties.fuseSelectionIsDefault =
    conductingEquipment.getPropertyValue("fuseSelectionIsDefault") === "true";
  transformerProperties.fuseRating = conductingEquipment.getPropertyValue("fuseRating");
  transformerProperties.groupedConnectionPoints = groupedConnectionPoints;
  transformerProperties.ratingType = conductingEquipment.getPropertyValue("ratingType");
  transformerProperties.AllmaximumDemandKVA =
    conductingEquipment.getPropertyValue("AllmaximumDemandKVA");
  transformerProperties.AllmaximumExportKVA =
    conductingEquipment.getPropertyValue("AllmaximumExportKVA");
  transformerProperties.AllmaximumDemandKVAPeriod = conductingEquipment.getPropertyValue(
    "AllmaximumDemandKVAPeriod",
  );
  transformerProperties.AllmaximumUtilisationPercent = conductingEquipment.getPropertyValue(
    "AllmaximumUtilisationPercent",
  );
  transformerProperties.AllmaxVoltDropPercent =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercent");
  transformerProperties.AllmaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltDropPercentPeriod",
  );
  transformerProperties.AllmaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("AllmaxVoltDropPercentPassResult") === "true";
  transformerProperties.AllmaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseTotal");
  transformerProperties.AllmaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "AllmaxVoltRiseGeneration",
  );
  transformerProperties.AllmaxVoltRiseUnbalance =
    conductingEquipment.getPropertyValue("AllmaxVoltRiseUnbalance");
  transformerProperties.AllmaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "AllmaxVoltRisePercentPeriod",
  );
  transformerProperties.wintermaximumDemandKVA =
    conductingEquipment.getPropertyValue("wintermaximumDemandKVA");
  transformerProperties.wintermaximumExportKVA =
    conductingEquipment.getPropertyValue("wintermaximumExportKVA");
  transformerProperties.wintermaximumDemandKVAPeriod = conductingEquipment.getPropertyValue(
    "wintermaximumDemandKVAPeriod",
  );
  transformerProperties.wintermaximumUtilisationPercent = conductingEquipment.getPropertyValue(
    "wintermaximumUtilisationPercent",
  );
  transformerProperties.wintermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercent",
  );
  transformerProperties.wintermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltDropPercentPeriod",
  );
  transformerProperties.wintermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("wintermaxVoltDropPercentPassResult") === "true";
  transformerProperties.wintermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercent",
  );
  transformerProperties.wintermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("wintermaxVoltRiseTotal");
  transformerProperties.wintermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseGeneration",
  );
  transformerProperties.wintermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "wintermaxVoltRiseUnbalance",
  );
  transformerProperties.wintermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "wintermaxVoltRisePercentPeriod",
  );
  transformerProperties.summermaximumDemandKVA =
    conductingEquipment.getPropertyValue("summermaximumDemandKVA");
  transformerProperties.summermaximumExportKVA =
    conductingEquipment.getPropertyValue("summermaximumExportKVA");
  transformerProperties.summermaximumDemandKVAPeriod = conductingEquipment.getPropertyValue(
    "summermaximumDemandKVAPeriod",
  );
  transformerProperties.summermaximumUtilisationPercent = conductingEquipment.getPropertyValue(
    "summermaximumUtilisationPercent",
  );
  transformerProperties.summermaxVoltDropPercent = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercent",
  );
  transformerProperties.summermaxVoltDropPercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltDropPercentPeriod",
  );
  transformerProperties.summermaxVoltDropPercentPassResult =
    conductingEquipment.getPropertyValue("summermaxVoltDropPercentPassResult") === "true";
  transformerProperties.summermaxVoltRisePercent = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercent",
  );
  transformerProperties.summermaxVoltRiseTotal =
    conductingEquipment.getPropertyValue("summermaxVoltRiseTotal");
  transformerProperties.summermaxVoltRiseGeneration = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseGeneration",
  );
  transformerProperties.summermaxVoltRiseUnbalance = conductingEquipment.getPropertyValue(
    "summermaxVoltRiseUnbalance",
  );
  transformerProperties.summermaxVoltRisePercentPeriod = conductingEquipment.getPropertyValue(
    "summermaxVoltRisePercentPeriod",
  );
  transformerProperties.nodeNumber = conductingEquipment.getPropertyValue("nodeNumber");
  transformerProperties.hvFaultLevel = Number(conductingEquipment.getPropertyValue("hvFaultLevel"));
  transformerProperties.hvFaultLevelIsDefault =
    conductingEquipment.getPropertyValue("hvFaultLevelIsDefault") === "true";
  transformerProperties.xrRatio = Number(conductingEquipment.getPropertyValue("xrRatio"));
  transformerProperties.annotation = conductingEquipment.getPropertyValue("annotation");
  transformerProperties.xrRatioIsDefault =
    conductingEquipment.getPropertyValue("xrRatioIsDefault") === "true";
  transformerProperties.flickerP28Primary =
    conductingEquipment.getPropertyValue("flickerP28Primary");
  transformerProperties.flickerP28PrimaryPassResult =
    conductingEquipment.getPropertyValue("flickerP28PrimaryPassResult") === "true";
  transformerProperties.flickerP28Secondary =
    conductingEquipment.getPropertyValue("flickerP28Secondary");
  transformerProperties.flickerP28SecondaryPassResult =
    conductingEquipment.getPropertyValue("flickerP28SecondaryPassResult") === "true";
  transformerProperties.cascadingRAG = conductingEquipment.getPropertyValue("cascadingRAG");
  transformerProperties.nonCascadingRAG = conductingEquipment.getPropertyValue("nonCascadingRAG");
  transformerProperties.ohEnvironment = conductingEquipment.getPropertyValue("ohEnvironment");

  transformerProperties.substationId =
    conductingEquipment.getPropertyValue("substationId") ?? substationId;

  transformerProperties.phaseAngle = conductingEquipment.getPropertyValue("phaseAngle");
  transformerProperties.phasesAvailable = conductingEquipment.getPropertyValue("phasesAvailable");
  transformerProperties.poleTermLength = conductingEquipment.getPropertyValue("poleTermLength");
  transformerProperties.isDefaultPoleTermLength =
    conductingEquipment.getPropertyValue("isDefaultPoleTermLength") === "true";

  transformerProperties.designVoltage = conductingEquipment.getPropertyValue("designVoltage");

  return new Transformer(conductingEquipment.id, transformerProperties);
}
