import { ButtonGroup, Button } from "reactstrap";
import { Popup, useMap } from "react-leaflet";
import "./LeafletPopup.css";

interface Props {
  menuItems: string[];
  onClick: (menuItem: string) => void;
}

const PopupMenu = ({ menuItems, onClick }: Props) => {
  const map = useMap();

  return (
    <Popup>
      <ButtonGroup vertical className="menu-popup">
        {menuItems.map((menuItem) => (
          <Button
            key={menuItem}
            color="primary"
            outline
            className="text-light"
            onClick={() => {
              map.closePopup();
              onClick(menuItem);
            }}
          >
            {menuItem}
          </Button>
        ))}
      </ButtonGroup>
    </Popup>
  );
};

export default PopupMenu;
