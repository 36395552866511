import React from "react";

const SpinnerIcon = ({ flag, showSpinner, color }) => {
  return (
    <span>
      <span
        style={{ color: flag ? color : null }}
        className={
          showSpinner
            ? "spinner icon-spinner-solid"
            : flag
              ? "icon-check-solid"
              : "icon-cross-solid"
        }
      ></span>
    </span>
  );
};

export default SpinnerIcon;
